import { Box, Button, Typography } from "@mui/material";
import { boxCardNP, boxPagesContent } from "../../theme/ui/Boxs";
import TableMonitoring from "../../components/detailTracingMedical/tableMonitoringSummary";
import { DetailTracingMedicalHeader } from "../../components/detailTracingMedical/detailTracingMedicalHeader";
import ContextRenditionTracking, { ContextValueTracking } from "../../context/contextRenditionTracking";
import { useEffect, useMemo, useState } from "react";
import { contextMedicalRenditionTracking, defaultRowsPerPage } from "../../utils/constDefault";
import { clientCCP } from "../../config/apolloClientsCCP";
import { medicalRenditionTracking } from "../../services/medicalRenditionTracking";
import { PointLoad } from "../../components/UI/Modal/PointLoad";
import DetailTracingMedical from "../../components/detailTracingMedical/detailTracingMedical";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useLocation, useParams } from "react-router-dom";

const TracingMedicalRendition = () => {  
  const [dataContext, setDataContext] = useState<ContextValueTracking["dataContext"]>(contextMedicalRenditionTracking);
  const contextValue = useMemo(() => ({ dataContext, setDataContext }), [dataContext, setDataContext]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [errorSummary, setErrorSummary] = useState(false);
  const [openLoad, setOpenLoader] = useState(false);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const randomValue = searchParams.get("randomValue");
  
  const { holding, year, month } = dataContext;

  const handleVisible = () => {
    setDetailVisible(false)
  }

  const getRenditions = async (pageInit: number, perPage: number) => {
    try {      
      setOpenLoader(true);
      const result = await clientCCP.query({
        query: medicalRenditionTracking,
        variables: {
          month: Number(month),
          year: Number(year),
          rutProvider: holding,
          page: pageInit + 1,
          perPage: perPage
        },
      });

      const { estado, mensaje, data, totalRecords }: { estado: number; mensaje: string; data: any; totalRecords: number; } = result.data.medicalRenditionTracking;
      if (estado === 200) {
        if (data !== null) {
          if (data.length > 0) {
            setErrorSummary(false)
            setDataContext({
              ...dataContext,
              medicalRenditions: data,
              totalRecords: totalRecords
            })
          }else {
            setErrorSummary(true)
            setDataContext({
              ...dataContext,
              medicalRenditions: [],
              totalRecords: 0
            })
          }          
        }else {
          setErrorSummary(true)
          setDataContext({
            ...dataContext,
            medicalRenditions: [],
            totalRecords: 0
          })
        }       
      }else {
        setErrorSummary(true)
        setDataContext({
          ...dataContext,
          medicalRenditions: [],
          totalRecords: 0
        })
      }
      setOpenLoader(false);
    } catch (error) {
      console.log(error)
      setErrorSummary(true)
      setDataContext({
        ...dataContext,
        medicalRenditions: [],
        totalRecords: 0
      })
      setOpenLoader(false);
    }    
  }

  // useEffect(() => {
  //   setDetailVisible(false)
  //   setDataContext(contextMedicalRenditionTracking);
  // }, [randomValue])

  return (
    <ContextRenditionTracking.Provider value={contextValue}>
      <Box pt="30px" sx={boxPagesContent} mb="5% !important" role="body">
        <PointLoad onOpen={openLoad} subText={"Buscando rendiciones..."} />
          {
            detailVisible ? 
            <>
              <Box width="90%" m="auto" >
                  <Button color="inherit" onClick={handleVisible}>
                      <ArrowBackRoundedIcon sx={{ color: '#01736C' }} />
                      <Typography variant='subtitle2' pl={1}>Volver a Escritorio de trabajo</Typography>
                  </Button>
              </Box>
              <Typography width="86%" m="auto" variant="h4" pb="14px">
                  Detalle de rendiciones médicas
              </Typography>
            </>            
            :
            <Typography width="86%" m="auto" variant="h4" pb="14px">
                Seguimiento de rendiciones médicas
            </Typography>            
          }
          
        <Box sx={boxCardNP} mt="18px !important">
          {
            detailVisible ? 
            <DetailTracingMedical /> :
            <>
              <DetailTracingMedicalHeader getRenditions={getRenditions} setPage={setPage} setRowsPerPage={setRowsPerPage} />
              <TableMonitoring setDetailVisible={setDetailVisible} getRenditions={getRenditions} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} errorSummary={errorSummary} dataSearch={dataContext}/>
            </>
          }
        </Box>
      </Box>
    </ContextRenditionTracking.Provider>
  )
}

export default TracingMedicalRendition;