function b64toBlob(base64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  
  export function downloadBase64File(base64Data: string, fileName: string, contentType: string | undefined) {
    const blob = b64toBlob(base64Data, contentType);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }
  
  /** opcional, para dos campos en resp */
  export const downloadFromBase64 = (base64Str: string, fileName: string, contentType?: string) => {
    const mimetype = (fileName?.includes('.jpg'))? 'image/jpeg' :  ((fileName?.includes('.xlsx'))?'application/octet-stream':'application/pdf');
    //downloadLink.href = `data:${mimetype};base64,${base64Str}`;
    const linkSource = (contentType)? `data:${contentType};base64,${encodeURI(base64Str)}`
    : `data:${mimetype};base64,${base64Str}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }