import { Box, Button, Grid, Typography } from "@mui/material";
import { boxCardNP, boxPagesContent } from "../../theme/ui/Boxs";
import {
  blueText,
  boldInputText,
  inputText,
  textBox,
  titleBox,
} from "../ui-components/Typography/typographyStyles";
import { useContext, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import es from "date-fns/locale/es";
import { CustomTextField } from "../../theme/ui/textField";
import MedicalRecord from "./medicalRecord";
import { CustomSelect } from "../ui-components/customSelect";
import ContextRenditionTracking, { ContextValueTracking } from "../../context/contextRenditionTracking";
import { defaultRowsPerPage, headCells, stateTrackingRendition } from "../../utils/constDefault";
import {useEffect} from 'react';
import { medicalRenditionTrackingDetails } from "../../services/medicalRenditionTrackingDetails";
import { clientCCP } from "../../config/apolloClientsCCP";
import { PointLoad } from "../UI/Modal/PointLoad";
import { getFechaProbablePago } from "../../services/getFechaProbablePago";
import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { dataPrestador } from "../../utils/getInfoProvider";
import { isVisible } from "@testing-library/user-event/dist/utils";

const DetailTracingMedical = () => {

  const { dataContext, setDataContext } = useContext<ContextValueTracking>(ContextRenditionTracking);
  const { type, dateRequest, holding, nameHolding, update } = dataContext;
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [orderBy, setOrderBy] = useState('1'); 
  const [order, setOrder] = useState('asc');
  const [searchTextbox, setSearchTextbox] = useState('');
  const [fechaProbablePago] = useLazyQuery(getFechaProbablePago);
  const [resultFechaProbablePago, setResultFechaProbablePago] = useState("");

  const [error, setError] = useState(false);
  const [openLoad, setOpenLoader] = useState(false);
  const [textLoad, setTextLoad] = useState('');
  const [visibilidadFechaCompromiso, setVisibilidadFechaCompromiso] = useState(false);
  const [detailsRenditition, setDetailsRendition] = useState([]);

  const handleFormChange = async ({ value, name }: any) => {
    if (name == "dateRequest" && visibilidadFechaCompromiso) {
      setOpenLoader(true);
      setTextLoad("Actualizando fecha probable de pago...");
      await fetchFechaProbablePago(value);
      setOpenLoader(false);
    }
    
    setDetailsRendition([]);
    setDataContext({
      ...dataContext,
      [name]: value,
    });
  };

  const handleRenditions = () =>{ 
    setSearchTextbox("")
    getRenditionsDetails(0, defaultRowsPerPage, orderBy, order, "" );
  }

  const getRenditionsDetails = async (pageInit: number, perPage: number, orderBy: string, typeOrder: string, searchT: string) => {
    try {
      console.log(searchT)
      let column: any = headCells.find(x => x.id == orderBy)?.columnName;
      setOpenLoader(true);
      setTextLoad("Buscando rendiciones...");
      const result = await clientCCP.query({
        query: medicalRenditionTrackingDetails,
        variables: {
          renditionDate: dateRequest,
          status: String(type),
          rutProvider: holding,
          page: pageInit + 1,
          perPage: perPage,
          orderBy: `${orderBy} ${typeOrder}`,
          columnId: column,
          searchText: searchT
        },
      });
      
      const { estado, mensaje, data, totalRecords }: { estado: number; mensaje: string; data: any; totalRecords: number; } = result.data.medicalRenditionTrackingDetails;
      
      if (estado === 200) {
        if (data !== null) {
          if (data.length > 0) {
            setError(false)
            setDetailsRendition(data)
            setTotalRecords(totalRecords)
          }else {
            setDetailsRendition([]);
            setTotalRecords(0)
            setError(true)
          }          
        } else{
          setDetailsRendition([]);
          setTotalRecords(0)
          setError(true)
        }  
      }else {
        setDetailsRendition([]);
        setTotalRecords(0)
        setError(true)
      }
      setOpenLoader(false);
    } catch (error) {
      console.log(error)
      setOpenLoader(false);
    }    
  }

  const fetchFechaProbablePago = (date: any) => {
    return new Promise((resolve, reject) => {
      fechaProbablePago({
        variables: {
          fechaDesde: date,
          sla: dataPrestador(1, holding)
        },
        onCompleted: (data) => {

          setResultFechaProbablePago(data.obtenerFechaProbablePago.fechaProbablePago)
          resolve(data);
        },
        onError: (error) => {

          reject(error);
        }
      });
    });
  };

  useEffect(() => {
    setSearchTextbox("")
    let search = ""
    getRenditionsDetails(0, defaultRowsPerPage, orderBy, order, search );
  }, [update])

  useEffect(() => {
    const visible = dataPrestador(2, holding)
    setVisibilidadFechaCompromiso(visible)
    if (visible) {
      fetchFechaProbablePago(new Date(dateRequest))
    }
  }, [])
  
  return (
    <Grid>
      <PointLoad onOpen={openLoad} subText={textLoad} />
        <Grid container p="24px">
          <Grid item xs={12} pr="5%" pb="24px">
            <Typography sx={titleBox}>Rendiciones médicas</Typography>
            <Typography sx={textBox}>
              Selecciona y carga información para visualizar el detalle de las
              rendiciones.
            </Typography>
          </Grid>
          <Grid item xs={2} pr="24px">
            <Typography sx={inputText} pb="6px" whiteSpace={"pre"}>
              Estado de rendición
            </Typography>
            <CustomSelect
                disabled={false}
                value={type}
                data={stateTrackingRendition}
                titulo={"Seleccione"}
                name="type"
                onChange={(event: { target: any }) => handleFormChange(event.target)}
            />
          </Grid>
          <Grid item xs={2} pr="24px">
            <Typography sx={inputText} pb="6px">
              Fecha solicitud
            </Typography>
            <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
              <DatePicker
                value={dateRequest}
                onChange={(value: any) =>
                  handleFormChange({ value, name: "dateRequest" })
                }
                PaperProps={{
                  className: "paper-calendar",
                }}
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    className="full-width"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD/MM/AAAA",
                      readOnly: true,
                    }}
                    value={dateRequest}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          {
            visibilidadFechaCompromiso == true &&
            <Grid item xs={2}>
              <Typography sx={boldInputText} pb="6px">
                Fecha Probable de pago
              </Typography>
              <Typography sx={blueText} pb="6px" pt="6px">
                { resultFechaProbablePago }
              </Typography>
            </Grid>
          }          
          <Grid item xs={4}>
            <Typography sx={boldInputText} pb="6px">
              Prestador solicitante
            </Typography>
            <Typography sx={blueText} pb="6px" pt="6px">
              { nameHolding }
            </Typography>
          </Grid>
          <Grid item xs={2} pt="26px">
            <Button color={"primary"} sx={{ p: "16px !important" }} onClick={handleRenditions}>
              Buscar Rendiciones
            </Button>
          </Grid>
        </Grid>
        <MedicalRecord datos={detailsRenditition} totalRecords={totalRecords} getRenditionsDetails={getRenditionsDetails} orderBy={orderBy} setOrderBy={setOrderBy} order={order} setOrder={setOrder} setSearchTextbox={setSearchTextbox} searchTextbox={searchTextbox} error={error} setError={setError} />
    </Grid>
  );
};

export default DetailTracingMedical;
