import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { CustomSelect } from "../ui-components/customSelect";
import { CustomSelectInput } from "../../theme/ui/Select";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { CustomTextField } from "../../theme/ui/textField";
import UploadGray from "../../assets/uploadGray.svg";
import {
  defaultContent,
  defaultFormRegister,
  defaultTypeDocument,
} from "../../utils/constDefault";
import DateAdapter from "@mui/lab/AdapterDateFns";
import es from "date-fns/locale/es";
import {
  graySubtext,
  inputText,
  titleBox,
} from "../ui-components/Typography/typographyStyles";
import UploadGreen from "../../assets/uploadGreen.svg";
import { useContext, useEffect, useState } from "react";
import { formatNumber, removeFormatNumber } from "../../utils/formatNumber";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import { getFormattedDate } from "../../utils/getFormatDate";
import { PostDocumentTributary } from "../../services/postDocumentTributary";
import { clientCCP } from "../../config/apolloClientsCCP";
import ModalLoader from "../ui-components/ModalLoader/ModalLoader";
import Toast from "../ui-components/Toast/Toast";
import { InputAmounts } from './inputAmounts';
import { obtieneIvaDeNeto } from "../../utils/obtieneIvaDeNeto";
import { getTypeDocument } from "../../utils/getTypeDocument";

export const FormRegister = () => {
  const { dataContext, setDataContext } =
    useContext<ContextValue>(ContextRendition);
  const { invoices, insertInvoices, idRendition, amountToPay } = dataContext;
  const [openLoad, setOpenLoader] = useState(false);
  const [contentAlert, setContentAlert] = useState<any>(defaultContent);
  const [alert, setAlert] = useState<boolean>(false);

  const getMaxDate = () => new Date();

  const [disabledInsert, setDisabledInsert] = useState(true);

  const [{ date, typeDocument, amount, tax, net, exempt, folioDocument }, setFormData] =
    useState(defaultFormRegister);

  const handleFormChange = ({ value, name }: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  
  const openToast = (
    isSuccess: boolean,
    content: string,
    typeAlert: string,
    styleAlert: object
  ) => {
    setContentAlert({ isSuccess, content, typeAlert, styleAlert });
    setAlert(true);
  };

  const handleSubmit = async () => {
    const sessionData = sessionStorage.getItem("info-session");
    const infoData = sessionData ? JSON.parse(sessionData) : {};
    try {
      setOpenLoader(true);
      let typeDoc = getTypeDocument(typeDocument);
      const newInvoice = {
        id: dataContext.idRendition,
        date: getFormattedDate(date),
        typeDocument: typeDoc,
        radioIVA: typeDocument === "33" ? "Si" : "No",
        net: removeFormatNumber(net),
        exempt: removeFormatNumber(exempt),
        tax: removeFormatNumber(tax),
        amount: removeFormatNumber(amount),
        folioDocument,
      };

      const tributaryDocument = {
        id: String(folioDocument),
        medicalRenditionId: dataContext.idRendition,
        documentType: Number(typeDocument),
        documentFolio: folioDocument,
        documentNet: removeFormatNumber(net),
        documentExempt: removeFormatNumber(exempt),
        documentTax: removeFormatNumber(tax),
        documentAmount: removeFormatNumber(amount),
        documentDate: getFormattedDate(date),
        logUsuario: infoData.email,
      };

      const result = await clientCCP.mutate({
        mutation: PostDocumentTributary,
        variables: {
          tributaryDocument,
        },
      });
      setOpenLoader(false);
      const data = result?.data?.updateTributaryDocument;
      if(data) {
        const { estado, mensaje } = data;
        if (estado === 200) {
          const updatedInsertInvoices = [...insertInvoices, newInvoice];
          const updatedInvoices = invoices.filter(
            (invoice) => invoice.id !== String(folioDocument)
          );
          setDataContext((prevContext) => ({
            ...prevContext,
            invoices: updatedInvoices,
            insertInvoices: updatedInsertInvoices,
          }));
          
          setFormData(defaultFormRegister);
        } else {
          openToast(false, mensaje, "top", { marginTop: "300px" });
        }
      }else {
        openToast(
          false,
          "Error al insertar documento de cobro, intente nuevamente",
          "top",
          { marginTop: "300px" }
        );
        setOpenLoader(false);
      }
    } catch (error) {
      openToast(
        false,
        "Error al insertar documento de cobro, intente nuevamente",
        "top",
        { marginTop: "300px" }
      );
      setOpenLoader(false);
    }
  };

  useEffect(() => {
    if(invoices.length > 0) {
      if (typeDocument === "33") {
        validateDisabledInsert(net);
      }
      else if (typeDocument === "34"){
        validateDisabledInsert(exempt);
      }
    }
  }, [net, exempt, date, typeDocument, folioDocument, invoices])

  const validateDisabledInsert = (numValidate: string) => {
    const unformattedNum = removeFormatNumber(numValidate)
    if (!isNaN(unformattedNum)) {
      if (unformattedNum > 0 && folioDocument > 0) {
        setDisabledInsert(false);
      } else {
        setDisabledInsert(true);
      }
    } else {
      setDisabledInsert(true);
    }
  }

  useEffect(() => {
    if (typeDocument === "33") {
      if ( net !== "0" && net !== "" ) {
        let exemptZero = 0;
        if (exempt !== "0" && exempt !== "") {
          exemptZero = removeFormatNumber(exempt);
        }
        const taxIva = obtieneIvaDeNeto(removeFormatNumber(net));
        handleFormChange({ value: formatNumber(taxIva), name: "tax" });
        handleFormChange({ value: formatNumber(removeFormatNumber(net) + taxIva + exemptZero), name: "amount" });
      }else {
        handleFormChange({ value: "", name: "tax" });
        handleFormChange({ value: "", name: "amount" });
      }
    }else if (typeDocument === "34") {
      if ( exempt !== "0" && exempt !== "" ) {
        handleFormChange({ value: exempt, name: "amount" });
      }else {
        handleFormChange({ value: "", name: "amount" });
      }
    }
  }, [net, exempt])

  useEffect(() => {
    handleFormChange({value: "", name: "tax"})
    handleFormChange({value: "", name: "amount"})
    handleFormChange({value: "", name: "net"})
    handleFormChange({value: "", name: "exempt"})
  }, [typeDocument])

  useEffect(() => {
    if(idRendition === 0) {
      setFormData(defaultFormRegister);
    }
  }, [idRendition]);

  return (
    <Grid container p="24px">
      <Toast
        openAlert={alert}
        setOpenAlert={setAlert}
        contentAlert={contentAlert}
      />
      <ModalLoader open={openLoad} title={"Cargando documento tributario"} />
      <Grid item xs={10}>
        <Typography sx={titleBox}>Registro de documentos de cobro</Typography>
        <Typography sx={graySubtext}>
          Ingrese documentos tributarios relacionados a la rendición de bonos
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Button
          color={!disabledInsert ? "secondary" : "primary"}
          fullWidth
          disabled={disabledInsert}
          startIcon={<img src={!disabledInsert ? UploadGreen : UploadGray} alt="" />} //UploadGray
          onClick={handleSubmit}
        >
          Insertar{" "}
        </Button>
      </Grid>
      <Grid item style={{maxWidth: '19%'}} pr="20px" pt="24px">
        <Typography sx={inputText} pt="12px" pb="6px" whiteSpace={"pre"}>
          Tipo de documento
        </Typography>
        <CustomSelect
          onChange={(event: { target: any }) => handleFormChange(event.target)}
          disabled={false}
          value={typeDocument}
          data={defaultTypeDocument}
          name="typeDocument"
        />
      </Grid>
      <Grid item style={{maxWidth: '14.1%'}} pr="20px" pt="24px">
        <Typography sx={inputText} pt="12px" pb="6px" whiteSpace={"pre"}>
          Folio del documento
        </Typography>
        <CustomSelect
          onChange={(event: { target: any }) => handleFormChange(event.target)}
          disabled={false}
          value={folioDocument}
          data={invoices}
          name="folioDocument"
        />
      </Grid>
      <Grid item style={{maxWidth: '14%'}} pr="20px" pt="24px">
        <Typography sx={inputText} pt="12px" pb="6px" whiteSpace={"pre"}>
          Fecha del documento
        </Typography>
        <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
          <DatePicker
            value={date}
            maxDate={getMaxDate()}
            onChange={(value: any) => handleFormChange({ value, name: "date" })}
            PaperProps={{
              className: "paper-calendar",
            }}
            renderInput={(params: any) => (
              <CustomTextField
                {...params}
                className="full-width"
                inputProps={{
                  ...params.inputProps,
                  placeholder: "DD/MM/AAAA",
                  readOnly: true,
                }}
                value={date}
              />
            )}
          />
        </LocalizationProvider>
      </Grid> 
      <InputAmounts setFormData={setFormData} valueInput={net} nameInput={"net"} disabled={typeDocument == "34" ?? true} title={"Monto Neto"} />         
      <InputAmounts setFormData={setFormData} valueInput={exempt} nameInput={"exempt"} title={"Monto Exento"} />         
      <InputAmounts setFormData={setFormData} valueInput={tax} nameInput={"tax"} disabled={true} title={"Monto Impuesto"} />
      <InputAmounts setFormData={setFormData} valueInput={amount} nameInput={"amount"} disabled={true} title={"Monto Total"} />      
    </Grid>
  );
};