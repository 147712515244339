import { NavLink} from "react-router-dom";
import { validateRole } from "../../utils/validateRole";
import { List, ListItemButton } from "@mui/material";
import { useState } from "react";
export const NavDrawerExtranet = ({ isAuthenticated, items }: any) => {
  const [randomValue, setRandomValue] = useState(Math.random());
  
  const handleClick = () => {
    setRandomValue(Math.random());
  };

  return (
    
    <nav>
      <List key={"list_1"} component={"div"} className="item-menu-container">
        {items.map(({ id, icon, iconSelected, title, route, role }: any) => (
          <ListItemButton
            key={id}
            className="item-menu"
            disabled={!isAuthenticated || !validateRole(role)}
          >            
            <NavLink to={route === "/tracing-medical-rendition" ? `${route}?randomValue=${randomValue}` : route} onClick={handleClick}>
              {({ isActive }) => (
                <>
                  <img src={isActive ? iconSelected : icon} alt={title} />
                  <p>{title}</p>
                </>
              )}
            </NavLink>
          </ListItemButton>
        ))}
      </List>
    </nav>
  );
};
