import { Divider, Grid, Typography } from "@mui/material";
import {
  backGreen,
  blackBold,
} from "../ui-components/Typography/typographyStyles";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import { useContext, useEffect, useState } from "react";
import { formatNumber } from "../../utils/formatNumber";

export const TotalDocument = () => {

  const { dataContext } = useContext<ContextValue>(ContextRendition);
  const { insertInvoices, amountToPay } = dataContext;

  const [{totalDoc, montoCobro, diferenciaFacturacion}, setTotalDoc] = useState({totalDoc: 0, montoCobro: "0", diferenciaFacturacion: "0"});

  useEffect(() => {       
    let montoCobroFact = insertInvoices.reduce((sum, invoice) => sum +  invoice.amount , 0);
    setTotalDoc({
      totalDoc: insertInvoices.length,
      montoCobro: formatNumber(montoCobroFact),
      diferenciaFacturacion: formatNumber(montoCobroFact - amountToPay)
    });
  }, [insertInvoices])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={8}></Grid>
      <Grid item xs={4} pt="19px" pr="24px" pb="24px">
        <Typography
          sx={blackBold}
          fontWeight={"700 !important"}
          pt="12px"
          pb="16px"
        >
          Total doc. cobro: {totalDoc}
          
        </Typography>
        <Typography sx={backGreen} pt="12px" pb="6px">
          Diferencia Cuadre Facturación: {diferenciaFacturacion}
        </Typography>
        <Typography sx={backGreen} pt="12px" pb="6px">
          Monto total de cobro: {montoCobro}
        </Typography>
      </Grid>
    </Grid>
  );
};
