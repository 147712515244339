import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import CMProvider from "../context/context";
import {
  MedicalRendition,
  TracingMedicalRendition,
  Index,
} from "../pages/pages";
import ProtectedRoutes from "./protectedRoutes";
import { NewTracingCM } from "../pages/newTracingCM/newTracingCM";
import ConsultMedicalReport from "../pages/consultMedical/ConsultMedicalReport";

const RoutesConfig = () => {
  return (
    <CMProvider>
        <Suspense fallback={null}>
          <Routes>
            <Route element={<Index />} path="/" />
            <Route element={<Index />} path="/logout" />
            <Route element={<ProtectedRoutes />}>
              <Route path="/newTracingCM/" element={<NewTracingCM />} />
              <Route path="/medical-rendition" element={<MedicalRendition />} />
              <Route
                path="/tracing-medical-rendition"
                element={<TracingMedicalRendition key={Math.random()} />}
              />
              <Route path="/consultaMedica" element={<ConsultMedicalReport />} />
            </Route>
          </Routes>
        </Suspense>
    </CMProvider>
  );
};

export default RoutesConfig;
