const marginAuto = {
  width: "86%",
  margin: 'auto',
}

//Contenido de las paginas
const boxPagesContent = {
  flexGrow: 1,
  height: "100vh",
  width: `calc(100% - 320px)`,
}

// Seguimiento Cuenta Medica
const boxSelectsTracingStyle = {
  ...marginAuto,
  background: "#FFFFFF",
  height: "144px",
  mt: "32px",
  display: "flex",
  alignItems: "flex-end",
  borderRadius: "8px",
  padding: "34px 24px",
};

const boxCard = {
  ...marginAuto,
  background: "#FFFFFF",
  borderRadius: "8px",
  padding: "24px 24px",
  boxShadow: " 0px 2px 5px 0px #C9CFE3BF",
};


const grayBox = {
  border: " 0.5px solid #D2D6D9",
  background: "#F8F9F9",
  borderRadius: "8px",
  padding: "14px 16px",
  boxShadow: "none",
};

const boxCardNP = {
  ...marginAuto,
  background: "#FFFFFF",
  borderRadius: "8px",
  boxShadow: " 0px 2px 5px 0px #C9CFE3BF",
};

const labelSelectTracingP = {
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  letterSpacing: "0.0120588em",
  color: "#364855",
};

// Detalle Cuenta Medica
const boxSelectsDetailStyle = {
  ...marginAuto,
  background: "#FFFFFF",
  height: "252px",
  mt: "32px",
  borderRadius: "8px",
  padding: "26px 24px",
};

const boxTablesInfo = {
  ...marginAuto,
  background: "#FFFFFF",
  height: "50%",
  mt: "32px",
  borderRadius: "8px",
}

// Documentos Cargados
const boxLoadDocument = {
  ...marginAuto,
  background: "#FFFFFF",
  mt: "32px",
  borderRadius: "8px",
  padding: "26px 24px",
}

const boxAttachmentsFiles = {
  ...marginAuto,
  background: "#FFFFFF",
  height: "350px",
  mt: "32px",
  borderRadius: "8px",
}


//Historial Resolutiv CM
const boxDocuments = {
  ...marginAuto,
  maxHeight: "700px",
  background: "#FFFFFF",
  borderRadius: "8px",
  mt: "32px",
}

const verticalDivider = {
  border: "1px solid #D2D6D9",
  height: "28px"
}

//chips
const inProcessStatusStyle = {
  width: '85px',
  bgcolor: '#EEECFB',
  padding: '4px 8px',
  border: '1px solid #5143D5',
  borderRadius: '8px',
  textAlign: 'center'
}

const notSendStatusStyle = {
  width: '80px',
  bgcolor: '#EEECFB',
  padding: '4px 8px',
  border: '1px solid #5143D5',
  borderRadius: '8px',
  textAlign: 'center'
}

const paidStyle = {
  width: '85px',
  bgcolor: '#CCEDEB',
  padding: '4px 8px',
  border: '1px solid #01A49A',
  borderRadius: '8px',
  textAlign: 'center'
}

const pendingStatusStyle = {
  width: '75px',
  bgcolor: '#FFF8E1',
  padding: '4px 8px',
  border: '1px solid #FFB300',
  borderRadius: '8px',
  textAlign: 'center'
}

const sendingStatusStyle = {
  bgcolor: '#DCEDC8',
  padding: '4px 8px',
  border: '1px solid #33691E',
  borderRadius: '8px',
  textAlign: 'center'
}

const rejectedStatusStyle = {
  width: '82px',
  bgcolor: '#FFEBEE',
  padding: '4px 8px',
  border: '1px solid #E53935',
  borderRadius: '8px',
  textAlign: 'center'
}

const deletedStatusStyle = {
  width: '82px',
  bgcolor: '#F2F5F9',
  padding: '4px 8px',
  border: '1px solid #93999D',
  borderRadius: '8px',
  textAlign: 'center'
}

const processStyle = {
  width: '67px',
  bgcolor: '#FFF8E1',
  padding: '4px 8px',
  border: '1px solid #FFB300',
  borderRadius: '8px',
  textAlign: 'center'
}

const requestedStyle = {
  width: '90px',
  bgcolor: '#EEECFB',
  padding: '4px 8px',
  border: '1px solid #5143D5',
  borderRadius: '8px',
  textAlign: 'center'
}

const receivedStatusStyle = {
  width: '95px',
  bgcolor: '#CCEDEB',
  padding: '4px 8px',
  border: '1px solid #01A49A',
  borderRadius: '8px',
  textAlign: 'center'
}

const issuedStatusStyle = {
  width: '95px',
  bgcolor: '#F1F8E9',
  padding: '4px 8px',
  border: '1px solid #7CB342',
  borderRadius: '8px',
  textAlign: 'center'
}

const issuedManualStatusStyle = {
  width: '116px',
  bgcolor: '#F1F8E9',
  padding: '4px 8px',
  border: '1px solid #7CB342',
  borderRadius: '8px',
  textAlign: 'center'
}

export {
  verticalDivider,
  grayBox,
  boxPagesContent,
  boxTablesInfo,
  boxSelectsTracingStyle,
  labelSelectTracingP,
  boxSelectsDetailStyle,
  boxLoadDocument,
  boxAttachmentsFiles,
  boxDocuments,
  marginAuto,
  boxCard,
  boxCardNP,
  requestedStyle,
  receivedStatusStyle,
  inProcessStatusStyle,
  pendingStatusStyle,
  processStyle,
  rejectedStatusStyle,
  deletedStatusStyle,
  notSendStatusStyle,
  paidStyle,
  sendingStatusStyle,
  issuedStatusStyle,
  issuedManualStatusStyle,
}