import { Box, Typography } from "@mui/material";
import { boxPagesContent } from "../../theme/ui/Boxs";
import BonusHeader from "../../components/bonusHeader/bonusHeader";

import { useState, useMemo } from "react";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import CollectionDocuments from "../../components/collectionDocuments/collectionDocuments";
import SummaryValidations from "../../components/summaryValidations/summaryValidations";
import { contextMedicalRendition } from "../../utils/constDefault";

function MedicalRendition() {
  const [dataContext, setDataContext] = useState<ContextValue["dataContext"]>(contextMedicalRendition);
  const contextValue = useMemo(() => ({ dataContext, setDataContext }), [dataContext, setDataContext]);
  return (
    <ContextRendition.Provider value={contextValue}>
      <Box pt="30px" sx={boxPagesContent} mb="5% !important" role="body">
        <Typography width="86%" m="auto" variant="h4">
          Solicitud rendición médica
        </Typography>
        <BonusHeader />
        <CollectionDocuments />
        <SummaryValidations />
      </Box>
    </ContextRendition.Provider>
  );
}

export default MedicalRendition;
