import Snackbar from "@mui/material/Snackbar";
import Check from "../../../assets/check.svg";
import Cancel from "../../../assets/cancel.svg"
import { Alert } from "./Alert";


function Toast({ openAlert, setOpenAlert, contentAlert }: any) {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };
  return (
    <Snackbar
      style={contentAlert.styleAlert}
      open={openAlert}
      anchorOrigin={{
        vertical: contentAlert.typeAlert || 'bottom',
        horizontal: 'center'
      }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={contentAlert.isSuccess === true ? "success" : "error"}
        sx={{ width: "100%", textAlign:"center"}}
        icon={<img src={contentAlert.isSuccess === true ? Check : Cancel} />}
      >
        {contentAlert.content}
      </Alert>
    </Snackbar>
  );
}

export default Toast;