import { boxCardNP } from "../../theme/ui/Boxs";
import { Box } from "@mui/system";
import { FormRegister } from "./formRegister";
import { TotalDocument } from "./totalDocument";
import { useState } from "react";
import { Divider, Grid } from "@mui/material";
import TableDocument from "./tableDocument";

function CollectionDocuments() {
  return (
    <Box sx={boxCardNP} mt="25px !important">
      <FormRegister />
      <Grid container>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TableDocument />
        </Grid>
      </Grid>
      <TotalDocument />
    </Box>
  );
}

export default CollectionDocuments;
