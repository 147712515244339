import { useEffect } from 'react'
import { CustomSelect } from '../ui-components/customSelect'

export const SelectPrestador = ({holding, handleSelectChange, setPrestadores, prestadores, includeAll }: any) => {

    const loadData = () => {
      const sessionData = sessionStorage.getItem("info-prestadores");
      const prestadoresArray = sessionData
        ? JSON.parse(sessionData).prestadores
        : [];
    
      let prestadoresData;
    
      if (includeAll) {
        const todosLosPrestadoresOption = {
          id: "0",
          nombre: "TODOS",
          value: "todos",
          multiplesFacturas: false,
          visibilidadFechaCompromiso: false,
          diasCtrlCaducidad: 0,
        };
    
        prestadoresData = [
          todosLosPrestadoresOption,
          ...prestadoresArray.map((prestador: any) => ({
            id: prestador.rutPrestador,
            nombre: prestador.rutPrestador + " " + prestador.nombrePrestador,
            value: prestador.rutPrestador,
            multiplesFacturas: prestador.multiplesFacturas,
            visibilidadFechaCompromiso: prestador.visibilidadFechaCompromiso,
            diasCtrlCaducidad: prestador.diasCtrlCaducidad,
          })),
        ];
      } else {
        prestadoresData = prestadoresArray.map((prestador: any) => ({
          id: prestador.rutPrestador,
          nombre: prestador.rutPrestador + " " + prestador.nombrePrestador,
          value: prestador.rutPrestador,
          multiplesFacturas: prestador.multiplesFacturas,
          visibilidadFechaCompromiso: prestador.visibilidadFechaCompromiso,
          diasCtrlCaducidad: prestador.diasCtrlCaducidad,
        }));
      }
    
      setPrestadores(prestadoresData);
    };
  

    useEffect(() => {
        loadData();
      }, []);
    
  return (
    <CustomSelect
        onChange={handleSelectChange}
        disabled={false}
        value={holding}
        data={prestadores}
        titulo={"Seleccione un prestador"}
        name="holding"
    />
  )
}
