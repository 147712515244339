import { useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import {
  blackBold2,
  boldTitle,
  modalSubT,
  modalTitle,
  purpleLittle,
} from "../../../../components/ui-components/Typography/typographyStyles";
import {
  CustomModal,
  CustomModalTitle,
} from "../../../../components/UI/Modal/CustomModal";
import { Typography } from "@mui/material";
import { SkeletonModalFile } from "../tracingLoaders";
import { statusEnumGrouped } from "../../shared/status";
import TableFiles from "./tableFiles";
import { formatRut } from "../../../../utils";
import { UploadFile } from "./uploadFile";
import { useMsal } from "@azure/msal-react";
import SnackBar from "../../../../components/ui-components/Snackbar/Snackbar";
import useFile from "./hooks/useFile";
import { DeleteDocumentModal } from "./deleteDocumentModal";

type ModalFilesProps = {
  open: boolean;
  onClose: (shouldRefetch: boolean) => void;
  row: any;
};

export const ModalFiles = ({ open, onClose, row }: ModalFilesProps) => {
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] =
    useState<boolean>(false);
  const { accounts } = useMsal();
  const {
    deletingDocumentData,
    files,
    loadingDocuments,
    loadingSendDocument,
    selectTypeFile,
    shouldRefetch,
    toastData,
    typeFile,
    handleDeleteFile,
    setDeletingDocumentData,
    sendFile,
    setToastData,
    setTypeFile,
    fnLoad
  } = useFile(row.idCMD);
  const canUpload = ([statusEnumGrouped.ENTREGADA].includes(row.estado) && row.canalOrigen === "CMD");

  const onPreDelete = (documentData: { fileId: number, folio: string}) => {
    setDeletingDocumentData(documentData);
    setShowDeleteDocumentModal(!showDeleteDocumentModal);
  };
 
  const onConfirmDelete = () => {
    if (deletingDocumentData.fileId > 0) {
      handleDeleteFile(deletingDocumentData.fileId);
      setShowDeleteDocumentModal(!showDeleteDocumentModal);
    }
  }

  useEffect(() => {
    if (open) {
      fnLoad();
    }
  }, [open]);

  return (
    <>
      <SnackBar vertical="top" state={toastData} setState={setToastData} />
      <DeleteDocumentModal
        documentId={deletingDocumentData.folio}
        onConfirm={onConfirmDelete}
        onClose={() => setShowDeleteDocumentModal(!showDeleteDocumentModal)}
        open={showDeleteDocumentModal}
      />
      <CustomModal
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <CustomModalTitle id="warning" onClose={() => onClose(shouldRefetch)}>
          <Typography sx={modalTitle} paddingTop="16px">
            Carga de documentos
          </Typography>
          <Typography sx={modalSubT} pt="16px" pb="17px">
            Aquí podrás visualizar o cargar documentos.
          </Typography>
        </CustomModalTitle>

        <DialogContent dividers>
          {loadingDocuments ? (
            <SkeletonModalFile />
          ) : (
            <Grid container>
              <Grid item xs={3} pb="16px">
                <Stack direction="column">
                  <Typography sx={purpleLittle} component="div" pb="4px">
                    Nº C. Extranet
                  </Typography>
                  <Typography sx={blackBold2} component="div">
                    {row.idCMD ?? row.id}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} pb="16px">
                <Stack direction="column">
                  <Typography sx={purpleLittle} component="div" pb="4px">
                    Nº C. Prestador
                  </Typography>
                  <Typography sx={blackBold2} component="div">
                    {row.numeroCuenta}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} pb="16px">
                <Stack direction="column">
                  <Typography sx={purpleLittle} component="div" pb="4px">
                    Fecha de recepción
                  </Typography>
                  <Typography sx={blackBold2} component="div">
                    {row.fechaRecepcion}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} pb="16px">
                <Stack direction="column">
                  <Typography sx={purpleLittle} component="div" pb="4px">
                    Beneficiario
                  </Typography>
                  <Typography sx={blackBold2} component="div">
                    {row.nombrePaciente} {formatRut(row.rutPaciente ?? "")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {canUpload && (
                  <UploadFile
                    sendFile={sendFile}
                    selectTypeFile={selectTypeFile}
                    setToastData={setToastData}
                    setTypeFile={setTypeFile}
                    typeFile={typeFile}
                    usuario={accounts[0].name}
                    rutBeneficiario={row.rutPaciente}
                    idSolicitud={row.idCMD}
                    loading={loadingSendDocument}
                  />
                )}

              <Grid item xs={12} textAlign={"left"}>
                <Typography sx={boldTitle} pt="30px">
                  Documentos cargados
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign={"left"} pt="20px">
                <TableFiles
                  files={files}
                  canDelete={[
                    statusEnumGrouped.ENTREGADA,
                  ].includes(row.estado)}
                  onDelete={onPreDelete}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions sx={{ textAlign: "center" }}>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="flex-end"
          >
            <Button
              sx={{ padding: "15px 42px !important" }}
              color="secondary"
              onClick={() => onClose(shouldRefetch)}
            >
              Cerrar
            </Button>
          </Grid>
        </DialogActions>
      </CustomModal>
    </>
  );
};
