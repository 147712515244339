import { TableCell, TableHead, TableRow } from "@mui/material";
import { ReactChild, ReactFragment, ReactPortal, Key } from "react";

export const HeaderTable = ({tableHeaders}: any) => {
  return (
    <TableHead>
      <TableRow>
        {tableHeaders.map((header: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined, index: Key | null | undefined) => (
          <TableCell key={index}>{header}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
