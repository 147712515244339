import { useLazyQuery, useMutation, } from "@apollo/client";
import {
  deleteDocumentoQuery,
  loadDocumentsQuery,
  sendFileQuery,
} from "../../../../../services/loadFilesServices";
import { useState } from "react";

const useFile = (rowId: number) => {
  const [files, setFiles] = useState([]);
  const [data, setData] = useState<any[]>([]);
  const [typeFile, setTypeFile] = useState<number>(0);
  const [deletingDocumentData, setDeletingDocumentData] = useState<{ fileId: number, folio: string}>({fileId: 0, folio: ""});
  const [toastData, setToastData] = useState<any>({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  const selectTypeFile: { [key: number]: any } = {
    0: "Documentos de cobro",
    1: "Antecedentes Clinicos",
    2: "Antecedentes Adicionales Solicitados",
  };

  const [fnLoad, { loading: loadingDocuments }] = useLazyQuery(loadDocumentsQuery, {
    variables: { idSolicitud: rowId },
    onCompleted: (res) => {
      if (res.obtenerDocumentoIdSolicitud) {
        setFiles(res.obtenerDocumentoIdSolicitud);
      }
    },
  });

  const [sendFile, { loading: loadingSendDocument }] = useMutation(
    sendFileQuery,
    {
      onCompleted: (res) => {
        setToastData({
          abrir: true,
          mensaje: "Archivo subido correctamente, revise tabla documentos",
          severidad: "success",
        });
        setData([...data, res.uploadDocumento]);
        setTypeFile(0);
        setShouldRefetch(true);
        fnLoad();
      },
      onError: () => {
        setToastData({
          abrir: true,
          mensaje: "Error al subir archivo, intente nuevamente",
          severidad: "error",
        });
        setTypeFile(0);
      },
    }
  );

  const [deleteFile, { loading: loadingDelete }] =
    useLazyQuery(deleteDocumentoQuery);

  const handleDeleteFile = (id: number) => {
    deleteFile({
      variables: { id },
      onCompleted: () => {
        setToastData({
          abrir: true,
          mensaje: "El documento ha sido eliminado correctamente",
          severidad: "success",
        });
        fnLoad();
        setShouldRefetch(true);
      },
      onError: () => {
        setToastData({
          abrir: true,
          mensaje:
            "Ocurrió un error al eliminar el documento. Por favor, inténtalo de nuevo",
          severidad: "error",
        });
      },
    });
  };

  return {
    deletingDocumentData,
    files,
    loadingDelete,
    loadingDocuments,
    loadingSendDocument,
    selectTypeFile,
    shouldRefetch,
    toastData,
    typeFile,
    handleDeleteFile,
    setDeletingDocumentData,
    sendFile,
    setToastData,
    setTypeFile,
    fnLoad
  };
};

export default useFile;
