import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";

const secondaryAlemana = {
  color: "#00837A",
  fontStyle: "normal",
  fontWeight: "bold",
  boxSizing: "border-box",
  border: "1px solid #00837A",
  left: "0%",
  right: "0%",
  top: "0%",
  bottom: "0%",
  letterSpacing: "0.0021em",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  lineHeight: "20px",
  textTransform: "none",
  fontFamily: "interstate",
  fontSize: "16px",
  height: "48px",
  padding: "15px 22px",

  "&:hover": {
    border: "1px solid #00837A",
    boxSizing: "border-box",
    color: "#00837A",
    backgroundColor: "#E6F6F5",
  },
  "&:active": {
    color: "#FFFFFF",
    backgroundColor: "#00736B",
  },
  "&:disabled": {
    boxSizing: "border-box",
    border: "1px solid #909AA1",
    color: "#909AA1",
    boxShadow: "none",
    backgroundColor: "#FFFFFF",
  },
};
const primaryAlemana = {
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "bold",
  left: "0%",
  right: "0%",
  top: "0%",
  bottom: "0%",
  letterSpacing: "0.0021em",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "8px",
  boxShadow: " 0px 2px 2px rgba(121, 133, 142, 0.25)",
  backgroundColor: "#00837A",
  lineHeight: "20px",
  textTransform: "none",
  fontFamily: "interstate",
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",
  border: "none",

  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#4DC0B8",
    boxShadow: "0px 4px 10px rgba(1, 115, 108, 0.35)",
    border: "none",
  },
  "&:active": {
    color: "#FFFFFF",
    backgroundColor: "#00736B",
    boxShadow: "0px 2px 2px rgba(1, 164, 154, 0.25)",
  },
  "&:disabled": {
    color: "#E9EBEC",
    boxShadow: "none",
    backgroundColor: "#E9EBEC",
  },
};
const NoBorder = {
  color: "#00837A",
  fontStyle: "normal",
  fontWeight: "bold",
  boxSizing: "border-box",
  border: "none",
  left: "0%",
  right: "0%",
  top: "0%",
  bottom: "0%",
  letterSpacing: "0.0021em",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  lineHeight: "20px",
  textTransform: "none",
  fontFamily: "interstate",
  fontSize: "16px",
  height: "48px",
  minWidth: "150px",

  "&:hover": {
    border: "none",
    boxSizing: "border-box",
    color: "#00837A",
    backgroundColor: "#E6F6F5",
  },
  "&:active": {
    color: "#FFFFFF",
    backgroundColor: "#00736B",
  },
  "&:disabled": {
    boxSizing: "border-box",
    border: "none",
    color: "#909AA1",
    boxShadow: "none",
    backgroundColor: "unset",
  },
};

export const StyledloadingButton = (variant) => {
  const switchVariant = (value) => {
    switch (value) {
      case "secondary":
        return secondaryAlemana;
      case "noBorder":
        return NoBorder;
      default:
        return primaryAlemana;
    }
  };
  return styled(LoadingButton)(switchVariant(variant));
};
