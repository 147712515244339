import { MenuItem, Select, Typography } from '@mui/material';
import { CustomSelectInput, menuFirstStyle, menuItemStyle, menuSelectStyle } from '../../theme/ui/Select';

export const CustomSelect = (props: any) => {
    let defaultValue = props.defaultValue;
    let value = props.value;

    if (props.data.length === 0) {
        defaultValue = 0;
        value = 0;
    }
    return (
        <Select
            fullWidth
            onChange={props.onChange}
            disabled={props.disabled}
            value={value || 0}
            sx={{ width: '100%' }}
            input={<CustomSelectInput disabled={props.disabled} />}
            MenuProps={menuSelectStyle}
            name={props.name}
            role={props.name}
            defaultValue={defaultValue}
        >
            <MenuItem disabled key={0} value={0} className='FirstMenu' sx={menuFirstStyle}>
                <Typography sx={menuFirstStyle}>{props.titulo}</Typography>
            </MenuItem>
            {props.data.map((elem: any, idx: any) => (
                
                <MenuItem
                    key={elem.id || idx + 1}
                    value={elem.id || idx + 1 || 0}
                    sx={menuItemStyle}
                >
                    {elem.nombre ? elem.nombre : elem}
                </MenuItem>
            ))}
        </Select >
    )
}

