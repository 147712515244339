import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { migrarSolicitudes } from "../../../services/tracingCmServices";
import { AlertColor } from "@mui/material";

type useSendCMProps = {
  refetch: Function;
  showMessage: (message: string, severity: AlertColor) => void;
};

const useSend = ({ refetch, showMessage }: useSendCMProps) => {
  const [sendingQueue, setSendingQueue] = useState<string[]>([]);
  const [execMigration, { loading: loadingExecMigration }] = useMutation(migrarSolicitudes);

  const handleSend = (incomingId: string[], resetSelectedRecords?: () => void) => {
    const onlyIdCMD = incomingId.map(id => Number(id.split('-')[0]));
    execMigration({
      variables: {
        solicitudes: onlyIdCMD,
      },
      onCompleted: (res) => {
        if (!res) {
          showMessage(
            "Ocurrió un error al enviar la(s) cuenta(s). Por favor, inténtalo de nuevo.",
            "error"
          );
        } else {
          showMessage(
            "Ha comenzado el envío de las cuentas seleccionadas.",
            "info"
          );
        }
        setSendingQueue(sendingQueue.filter((val) => !incomingId.includes(val)));
        if (resetSelectedRecords) resetSelectedRecords();
        refetch();
      },
      onError: (err) => {
        console.log("error migration", err);
        showMessage(
          "Ocurrió un error al enviar la(s) cuenta(s). Por favor, inténtalo de nuevo.",
          "error"
        );
        setSendingQueue(sendingQueue.filter((val) => !incomingId.includes(val)));
        refetch();
      },
    });
  };

  useEffect(() => {
    if (sendingQueue.length > 0 && !loadingExecMigration) {
      setSendingQueue([]);
    };
  }, [sendingQueue, loadingExecMigration]);

  return {
    loadingExecMigration,
    sendingQueue,
    handleSend,
    setSendingQueue,
  };
};

export default useSend;
