import { Box, Typography } from "@mui/material";
import {
  issuedStatusStyle,
  issuedManualStatusStyle,
  paidStyle,
  pendingStatusStyle,
  rejectedStatusStyle,
  requestedStyle,
  deletedStatusStyle
} from "../../../theme/ui/Boxs";
import { getStatusDescriptionById } from "../shared/status";

// const stateColor: { [key: number]: object } = {
//   1: paidStyle,
//   2: requestedStyle
//   3: paidStyle,
//   4: pendingStatusStyle,
//   5: rejectedStatusStyle,
//   6: requestedStyle,
//   7: issuedStatusStyle,
//   8: rejectedStatusStyle,
//   39: issuedStatusStyle,
// };

const newStateColor: { [key: number]: object } = {
  40: paidStyle,
  41: requestedStyle,
  42: requestedStyle,
  43: requestedStyle,
  44: requestedStyle,
  46: issuedStatusStyle,
  47: rejectedStatusStyle,
  48: rejectedStatusStyle,
  49: pendingStatusStyle,
  50: issuedManualStatusStyle,
  51: deletedStatusStyle,
};

const stateColorGruped: { [key: number]: object } = {
  1: paidStyle,
  2: requestedStyle,
  3: pendingStatusStyle,
  4: rejectedStatusStyle,
  5: issuedStatusStyle,
  6: issuedManualStatusStyle,
  7: deletedStatusStyle,
  8: rejectedStatusStyle
};

const StatusTag = ({ statusId, isGrouped }: { statusId: number, isGrouped: boolean }) => (
  <Box sx={isGrouped ? stateColorGruped[statusId] : newStateColor[statusId]}>
    <Typography variant="h6" fontSize="12px">
      {getStatusDescriptionById(statusId, isGrouped)}
    </Typography>
  </Box>
);

export default StatusTag;
