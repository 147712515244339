import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Checkbox,
  AlertColor,
} from "@mui/material";
import { HeadTracingTable } from "./headTracingTable";
import { stickyBodyLeft } from "../../../components/ui-components/Table/tableStyles";
import { NoResult } from "../../../components/ui-components/Table/noResult";
import { formatNumber } from "../../../utils/formatNumber";
import { Paginador } from "../../../components/ui-components/Table/tablePaginador";
import { MenuTableTracing } from "./MenuTableTracing";
import { LongTextTooltip } from "../../../components/ui-components/Tooltip/LongTextTooltip";
import StatusTag from "./statusTag";
import { Box } from "@mui/system";
import { inProcessStatusStyle, pendingStatusStyle, rejectedStatusStyle, requestedStyle } from "../../../theme/ui/Boxs";
import { SkeletonTable } from "./tracingLoaders";

// TODO: TIPAR!
type NewTracingTableProps = {
  count: number;
  error: boolean;
  isEmptyState: boolean;
  orderBy: string;
  orderDirection: "asc" | "desc" | undefined;
  pageAccount: number;
  rows: any[];
  rowsAccount: number;
  selectedRecords: string[];
  isSelected: (selectedId: string) => boolean;
  handleItemChecked: (selectedId: string) => void;
  handleSelectAllClick: (rows: number[]) => void;
  onRequestSort: (id: string) => void;
  setPageAccount: (pageAccount: number) => void;
  setRowsAccount: (rowsAccount: number) => void;
  setCurrentMenuRow: Function;
  setShowHistoryModal: Function;
  loadingObtenerDocumento: boolean;
  loadingExecMigration: boolean;
  sendingQueue: string[];
  showMessage: (message: string, severity: AlertColor) => void;
  setSendingQueue: Function;
  handleSend: Function;
  resetSelectedRecords: () => void;
  refetch: Function;
};

export const NewTracingTable = ({
  count,
  error,
  isEmptyState,
  orderBy,
  orderDirection,
  pageAccount,
  rows,
  rowsAccount,
  selectedRecords,
  isSelected,
  handleItemChecked,
  handleSelectAllClick,
  onRequestSort,
  setPageAccount,
  setRowsAccount,
  setCurrentMenuRow,
  setShowHistoryModal,
  loadingObtenerDocumento,
  loadingExecMigration,
  sendingQueue,
  showMessage,
  setSendingQueue,
  handleSend,
  resetSelectedRecords,
  refetch,
}: NewTracingTableProps) => {

  const onSend = (incomingID: string) => {
    setSendingQueue([...sendingQueue, incomingID]);
    handleSend([incomingID]);
  }

  const NoDataContent = () => {
    if (error) {
      return (
        <NoResult
          titulo={"No se pudo cargar la tabla"}
          subtitulo={"Intenta recargar la página para solucionar el problema."}
          noDivider={true}
          error
        />
      );
    }

    if (isEmptyState) {
      return (
        <NoResult
          titulo={"No hay información de cuentas médicas para visualizar"}
          subtitulo={
            "Para visualizar o gestionar la información en la tabla recepcione una cuenta."
          }
          noDivider={true}
          docImg
        />
      );
    }

    if (rows.length === 0) {
      return (
        <NoResult
          titulo={"No se encontró información de cuentas"}
          subtitulo={"Inténtelo nuevamente usando otro criterio para su filtro."}
          noDivider={true}
          newFound={true}
        />
      ); 
    }

    return <SkeletonTable rowsAccount={rowsAccount} />;
  };

  const IsNoInformation = ({ data }: any) => {
    return data !== null && data !== "" ? (
      data
    ) : (
      <Typography sx={{
        color: '#A6AEB3'
      }}>Sin Información</Typography>
    );
  };
  const transformEM = (estado: number | null) => {
    switch (estado) {
      case 1:
        return (
          <Box sx={pendingStatusStyle}>
            <Typography variant="h6" fontSize="12px">
              En cola
            </Typography>
          </Box>);
      case 2:
        return (
          <Box sx={inProcessStatusStyle}>
            <Typography variant="h6" fontSize="12px">
              En proceso
            </Typography>
          </Box>);
      case 3:
        return (
          <Box sx={rejectedStatusStyle}>
            <Typography variant="h6" fontSize="12px">
              Error
            </Typography>
          </Box>);
      default:
        return "";
    }
  }

  const filtered = rows.filter((sol) => sol.estadoMigracion && sol.estadoMigracion !== 4);

  return (
    <>
      <Paper
        className="paper-tables"
        sx={{
          border: "none !important",
          borderRadius: "0 !important",
        }}
      >
        {error || isEmptyState || rows.length < 1 ? (
          <NoDataContent />
        ) : (
          <>
            <TableContainer sx={{ pl: "0 !important" }}>
              <Table aria-label="customized table">
                <HeadTracingTable
                  loading={loadingObtenerDocumento || loadingExecMigration}
                  showSending={sendingQueue.length > 0 || filtered.length > 0}
                  handleSelectAllClick={handleSelectAllClick}
                  numSelected={selectedRecords.length}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  rowCount={rows.length}
                  onRequestSort={onRequestSort}
                />
                <TableBody>
                  {rows.map((row) => {
                    const isItemSelected = isSelected(`${row.type === 2 ? row.id : row.idCMD ?? row.id}-${row.canalOrigen}`);
                    return (
                      <TableRow key={`${row.type === 2 ? row.id : row.idCMD ?? row.id}-${row.canalOrigen}`}>
                        <TableCell
                          padding="checkbox"
                          className="tCell-p"
                          sx={stickyBodyLeft("0")}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            disabled={loadingObtenerDocumento || loadingExecMigration}
                            onChange={() => {
                              handleItemChecked(`${row.type === 2 ? row.id : row.idCMD ?? row.id}-${row.canalOrigen}`);
                            }}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <IsNoInformation data={row.fechaRecepcion} />
                        </TableCell>
                        <TableCell>
                          <IsNoInformation data={row.fechaEmision ?? ''} />
                        </TableCell>
                        <TableCell>
                          <IsNoInformation data={row.canalOrigen === "CMD" ? (row.fechaIngreso ?? '') : ''} />
                        </TableCell>
                        <TableCell>
                          <StatusTag statusId={row.estado ?? 0} isGrouped />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <IsNoInformation data={row.numeroCuenta} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <IsNoInformation data={row.id} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <IsNoInformation data={row.idCMD} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>{row.numeroEnvio}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography>{row.numeroCobro}</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography className="rutTable">
                            {row.rutPrestador}
                          </Typography>
                          <Typography className="nameTable">
                            <LongTextTooltip
                              placement="right"
                              maxLength={10}
                              text={`${row.razonSocial ?? ''}`}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {row.canalOrigen === "CMD" ? "Extranet" : "Email"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography className="rutTable">
                            {row.rutPaciente}
                          </Typography>
                          <Typography className="nameTable">
                            <LongTextTooltip
                              placement="right"
                              maxLength={10}
                              text={`${row.nombrePaciente ?? ''}`}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          {formatNumber(row.montoTotal)}
                        </TableCell>
                        {(sendingQueue.length > 0  || filtered.length > 0) && (
                          <TableCell
                            className="tableBodyEspecial"
                            align="center"
                          >
                            {(sendingQueue.includes(
                              `${row.idCMD}-${row.canalOrigen}`
                            ) && (
                                <Box sx={requestedStyle}>
                                  <Typography variant="h6" fontSize="12px">
                                    Enviando...
                                  </Typography>
                                </Box>
                              )) || transformEM(row.estadoMigracion)}
                          </TableCell>
                        )}
                        <TableCell className="tableBodyAcciones" align="center">
                          <MenuTableTracing
                            refetch={refetch}
                            resetSelectedRecords={resetSelectedRecords}
                            row={row}
                            sendingQueue={sendingQueue}
                            setCurrentMenuRow={setCurrentMenuRow}
                            setShowHistoryModal={setShowHistoryModal}
                            handleSend={() =>
                              onSend(`${row.idCMD}-${row.canalOrigen}`)
                            }
                            showMessage={showMessage}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Paginador
              opciones={[25, 50, 100]}
              data={count}
              itemPorPagina={rowsAccount}
              pagina={pageAccount}
              setPagina={setPageAccount}
              setLineasPagina={setRowsAccount}
            />
          </>
        )}
      </Paper>
    </>
  );
};
