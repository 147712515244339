import {
    Button,
    DialogActions,
    DialogContentText,
    DialogContent,
    Grid,
    Box
} from "@mui/material";
import GreenFile from "../../assets/greenFile.svg";
import { Typography } from '@mui/material';
import { boldGrayModal, grayModal, modalText, modalTitle, modalTitleAlt } from "../ui-components/Typography/typographyStyles";
import { CustomModal, CustomModalTitle } from "../UI/Modal/CustomModal";
export const OperativeNote = ({
    onOpen,
    onClose,
    setOpen,
    titleNote
}: // subTitle
    any) => {
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <CustomModalTitle id="operativeNote" onClose={onClose} >
                <Typography sx={modalTitleAlt} pt="16px" pb="17px">
                    Nota resolutiva
                </Typography>
            </CustomModalTitle>


            <DialogContent dividers >
                <Box textAlign={'center'}>
                    <img src={GreenFile} />
                </Box>

                <Typography sx={boldGrayModal}>
                    NOTA:
                </Typography>
                <Typography sx={grayModal}>
                    {titleNote}
                </Typography>
            </DialogContent>

            <DialogActions sx={{ textAlign: "end" }}>
                <Button
                    sx={{ p: "15px 16px !important" }}
                    color="secondary"
                    onClick={handleClose}

                >
                    Cerrar
                </Button>
            </DialogActions>
        </CustomModal>
    );
};
