import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { issuedStatusStyle, paidStyle, processStyle, rejectedStatusStyle, requestedStyle } from '../../theme/ui/Boxs'
import { useState } from 'react';
import { display } from '@mui/system';

export const StatesRendition = ({state}: any) => {
    const [typeState, setTypeState] = useState<any>(null)
    const [textState, setTextState] = useState<string>("")
    const idState = parseInt(state, 10);
    useEffect(() => {
        switch (idState) {
            case 5:
                setTypeState(requestedStyle)
                setTextState("Solicitada")
                break;
            case 2:
                setTypeState(paidStyle)
                setTextState("Pagada")
                break;
            case 3:
                setTypeState(rejectedStatusStyle)
                setTextState("Rechazada")
                break;
            case 1:
                setTypeState(issuedStatusStyle)
                setTextState("En proceso")
                break;
            case 6:
                setTypeState(paidStyle)
                setTextState("Revisada")
                break;
            default:
                break;
        }
    }, [state])
  return (
    <div style={{display: 'inline-block'}}>
        <Box sx={typeState}>
            <Typography variant="h6" fontSize="12px">
                {textState}
            </Typography>
        </Box>
    </div>
    
  )
}
