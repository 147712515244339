import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '../../../assets/close.svg'
const CustomModal = styled(Dialog)(({ theme }) => ({
  boxShadow: " 0px 2px 10px 0px #364855",
  borderRadius: "8px !important",

  '& .MuiDialogContent-root': {
    padding: "24px",
  },
  '& .MuiDialogTitle-root': {
    paddingTop: "24px",
  },
  '& .MuiDialogActions-root': {
    padding: "24px",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  disabled?: boolean;
}

function CustomModalTitle(props: DialogTitleProps) {
  const { children, onClose, disabled, ...other } = props;

  return (
    <DialogTitle {...other} sx={{ textAlign: "center" }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          disabled={disabled}
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <img src={Close} width="18px" alt="close img" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export {
  CustomModal,
  CustomModalTitle
}