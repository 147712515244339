import {
    CircularProgress,
    Dialog,
    DialogContentText,
    Typography,
  } from "@mui/material";
import { loader, subLoader } from "../../ui-components/Typography/typographyStyles";
  
  export const ModalSpinnerLoad = ({ onOpen, onClose, text, subText }: any) => {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={onOpen}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogContentText
          textAlign="center"
          pl="24px"
          pr="24px"
          pt="90px"
          pb="80px"
        >
          <CircularProgress
            disableShrink
            thickness={5}
            size={"39px"}
            sx={{ color: "#00A499", borderRadius: "100px" }}
          />
          {text ? (
            <Typography sx={loader} data-testid="text-element">{text}</Typography>
          ) : (
            <Typography sx={loader} data-testid="text-element"> </Typography>
          )}
          {subText ? (
            <Typography sx={subLoader} data-testid="subtext-element">{subText}</Typography>
          ) : (
            <Typography sx={subLoader} data-testid="subtext-element">
              {" "}
               
            </Typography>
          )}
        </DialogContentText>
      </Dialog>
    );
  };
  