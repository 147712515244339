import { styled } from '@mui/material/styles';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  '&.MuiTableCell-head': {
    padding: '18px 14px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: "#67737C",
    letterSpacing: '0.0120588em',
    backgroundColor: '#F8F9F9'
  },
  '&.MuiTableCell-body': {
    padding: '18px 14px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: "#4C4C4C",
    letterSpacing: '0.0120588em'
  },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.MuiTableRow-hover': {
    '&:hover': {
      backgroundColor: 'papayawhip',
    },
  },
}));


const styledModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "530px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px #364855",
};

const typographyModal = {
  color: "#364855",
  lineHeight: "20px",
  fontSize: "16px",
  paddingBottom: "10px",
  paddingTop: "24px",
  letterSpacing: "0.0120588em",
};


export {
  StyledTableCell,
  StyledTableRow,
  styledModal,
  typographyModal,
}
