import { Box, CircularProgress, Typography } from "@mui/material"
import { labelSelectTracingP } from "../../theme/ui/Boxs"

function IsLoading({message}:any) {
  return <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="76%"
  >
    <CircularProgress size="24px" sx={{ color: '#01A49A' }} /> 
    <Typography sx={labelSelectTracingP} pl="10px">{message}</Typography>
  </Box>
}

export default IsLoading