import {
  Button,
  DialogActions,
  DialogContentText,
  Grid
} from "@mui/material";
import icon_warningOrange from "../../../assets/warningIcon.svg";
import { modalText, modalTitle } from "../../ui-components/Typography/typographyStyles";
import { CustomModal, CustomModalTitle } from "./CustomModal";
import { Typography } from '@mui/material';
export const WarningModal = ({
    onOpen,
    onClose,
    onClick,
    setOpen,
    title,
    subtitle,
    primaryButton,
    secondaryButton,
    buttonCancel = false
}: 
    any) => {
    const handleClose = () => {
        setOpen(false)
    }
    const handleClick = () => {
        setOpen(false)
        onClick()
    }
    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            <CustomModalTitle id="warning" onClose={onClose} >
                <img alt="img" src={icon_warningOrange} style={{ marginTop: "20px" }} />
                <Typography sx={modalTitle} paddingTop="16px">
                    {title}
                </Typography>
            </CustomModalTitle>


            <DialogContentText textAlign="center" pl="10%" pr="10%"  sx={modalText} >
                {subtitle}
            </DialogContentText>

            <DialogActions sx={{ textAlign: "center" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {
                        primaryButton ?
                            <Button
                                sx={{ padding: "15px 42px !important" }}
                                color="secondary"
                                onClick={handleClose}

                            >
                                {primaryButton}
                            </Button> :
                            setOpen && buttonCancel &&
                            <Button
                                sx={{ padding: "15px 42px !important" }}
                                color="secondary"
                                onClick={handleClose}

                            >
                                Cancelar
                            </Button>
                    }

                    {
                        secondaryButton ?
                            <Button color="primary" sx={{ marginLeft: "24px", }} onClick={handleClick}>
                                { secondaryButton }
                            </Button>
                            :
                            <Button color="primary" sx={{ marginLeft: "24px" }}  onClick={handleClose}>
                                Aceptar
                            </Button>
                    }
                </Grid>
            </DialogActions>
        </CustomModal>
    );
};
