const styledLoaderModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "540px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px #364855",
  outline: 'none',
  textAlign: 'center',
  padding: '100px'
};

export {
  styledLoaderModal
}