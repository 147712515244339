import { TablePagination } from "@mui/material";
import { useContext, useState } from "react";
import ContextRendition, { ContextValue } from "../../context/contextRendition";

export const TablePaginationFormat = ({page, rowsPerPage, setPage, setRowsPerPage}: any) => {
  
  const handleChangePage = (_event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { dataContext } = useContext<ContextValue>(ContextRendition);
  const { insertInvoices } = dataContext;

  return (
    <TablePagination
      rowsPerPageOptions={[4, 8, 10, 15]}
      component="div"
      count={insertInvoices.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{ mt: "14px", mb: "14px" }}
      labelRowsPerPage={"mostrar por página"}
      labelDisplayedRows={(e) => {
        return (
          "" +
          (page + 1) +
          " de " +
          Math.ceil(insertInvoices.length / rowsPerPage)
        );
      }}
    />
  );
};
