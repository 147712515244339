import { useMemo, useState } from "react";
import { createContext } from "react";
import { IUserConfigurationContext } from "../interfaces/userConfigurationContextInterface";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Prestador } from "../interfaces/tracingCmInterfaces";

const UserConfigurationContext = createContext<IUserConfigurationContext>(
  {} as IUserConfigurationContext
);

interface UserConfigurationProviderProps {
  children: ReactJSXElement | ReactJSXElement[];
}
const UserConfigurationProvider = ({
  children,
}: UserConfigurationProviderProps) => {
  const [userConfiguration, setUserConfiguration] = useState<Prestador[]>([]);

  const contextValue = useMemo(
    () => ({
      userConfiguration,
      setUserConfiguration,
    }),
    [userConfiguration]
  );

  return (
    <UserConfigurationContext.Provider value={contextValue}>
      {children}
    </UserConfigurationContext.Provider>
  );
};

export { UserConfigurationProvider, UserConfigurationContext };
