import { borderRadius } from "@mui/system";

const interstate = {
  fontFamily: "Interstate",
};

const titleBox = {
  ...interstate,
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "0.0121em",
  color: "#203442",
};

const tableText = {
  ...interstate,
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.0121em",
  color: "#203442",
};

const textBox = {
  ...interstate,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.0121em",
  color: "#364855",
};

const inputText = {
  ...interstate,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.0121em",
  color: "#364855",
};

const grayText = {
  ...interstate,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "16px",
  letterSpacing: "0.0121em",
  color: "#63717B",
};

const emptyResumeText = {
  ...interstate,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "16px",
  letterSpacing: "0.0121em",
  color: "#4D5D68",
};

const grayTextFile = {
  ...interstate,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.0121em",
  color: "#63717B",
};
const backGreen = {
  ...interstate,
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "16px",
  letterSpacing: "0.0121em",
  color: "#364855",
  backgroundColor: "#E6F6F5",
  paddingTop: "8px",
  paddingBottom: "8px",
  paddingLeft: "16px",
  borderRadius: "4px",
};

const blackBold = {
  ...interstate,
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "16px",
  letterSpacing: "0.0121em",
  color: "#364855",
};

const blackBold3 = {
  ...interstate,
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "20px",
  letterSpacing: "0.0121em",
  color: "#364855",
};

const graySubtext = {
  ...interstate,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "15.86px",
  letterSpacing: "0.0121em",
  color: "#63717B",
};

const altTitle = {
  ...interstate,
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "20px",
  letterSpacing: "0.0121em",
  color: "#364855",
};

const littleText = {
  ...interstate,
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "16px",
  letterSpacing: "0.0121em",
  color: "#203442",
};

const loader = {
  fontFamily: "Rubik",
  color: "#364855",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "32px",
  letterSpacing: "0.0120588em",
  pt: "24px",
};

const subLoader = {
  ...interstate,
  color: "#4D5D68",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  pt: "8px",
};

const modalTitle = {
  ...interstate,
  color: "#203442",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  textAlign: "center",
};

const modalTitleAlt = {
  ...interstate,
  color: "#203442",
  fontSize: "22px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "1.21%",
  textAlign: "center",
};

const modalSubtitle = {
  ...interstate,
  color: '#4D5D68',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: "0.0120588em",
  textAlign: "center"
}

const typoGraphySubtitle = {
  ...interstate,
  color: '#4D5D68',
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '24px',
  letterSpacing: "0.0120588em",
  textAlign: "center"
}

const modalText = {
  ...interstate,
  color: "#4D5D68",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  display: "inline",
};

const modalTextBold = {
  ...interstate,
  color: "#4D5D68",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  display: "inline",
};

const boldInputText = {
  ...interstate,
  color: "#364855",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "20px",
  letterSpacing: "1.21%",
};

const blueText = {
  ...interstate,
  color: "#5143D5",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "1.21%",
};

const boldGrayModal = {
  ...interstate,
  color: "#4D5D68",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "1.21%",
};

const grayModal = {
  ...interstate,
  color: "#4D5D68",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "1.21%",
};

const greenBigTitle = {
  ...interstate,
  fontWeight: 700,
  fontSize: "36px",
  lineHeight: "39.71px",
  color: "#4DBFB8",
};

const lineSubText = {
  ...interstate,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#364855",
};

const greenText = {
  ...interstate,
  fontSize: "16px",
  fontWight: 400,
  lineHeight: "16px",
  letterSpacing: " 0.0121em",
  color: "#01A49A",
};

const greenText700 = {
  ...interstate,
  fontSize: "16px",
  fontWight: 700,
  lineHeight: "16px",
  letterSpacing: " 0.0121em",
  color: "#01A49A",
};
const greenTextBold = {
  ...greenText,
  fontWight: 700,
};

const purpleText = {
  ...greenText,
  color: "#857BE2",
};

const redText = {
  ...greenText,
  color: "#E53935",
};

const yellowText = {
  ...greenText,
  color: "#FFB300",
};

const grayBold = {
  ...interstate,
  fontSize: "16px",
  fontWight: 400,
  lineHeight: "16px",
  letterSpacing: " 0.0121em",
  color: "#364855",
};

const modalSubT = {
  ...interstate,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "2px",
  color: "#4D5D68",
};
const boldTitle = {
  ...interstate,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "32px",
  color: "#203442",
};
const lineText = {
  ...interstate,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#364855",
  display: "inline",
};
const lineBold = {
  ...interstate,
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#364855",
  display: "inline",
};

const cardSubT = {
  ...interstate,
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#4D5D68",
};

const littleTextCard = {
  ...interstate,
  color: "#4D5D68",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "18px",
  letterSpacing: "0.0120588em",
};

const purpleLittle = {
  ...interstate,
  color: "#392F95",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "14px",
  letterSpacing: "0.0120588em",
};

const blackBold2 = {
  ...interstate,
  color: "#203442",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "18px",
  letterSpacing: "0.0120588em",
};

const line22 = {
  ...interstate,
  color: "#63717B",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "22px",
  letterSpacing: "0.0120588em",
};
const subCard = {
  ...interstate,
  color: "#4D5D68",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
};

const littleDetail = {
  ...interstate,
  color: "#364855",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  letterSpacing: "0.0120588em",
};

const contTitle = {
  ...interstate,
  color: "#203442",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "28px",
  letterSpacing: "0.0120588em",
};

const subTable = {
  ...interstate,
  color: "#79858E",
  fontSize: "13px",
  fontWeight: "400",
  lineHeight: "16px",
  letterSpacing: " 0.0120588em",
};

const warningGray = {
  ...interstate,
  color: '#4D5D68',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: "1.21%",
  display: "inline"
}
const warningPurple = {
  ...interstate,
  color: '#5143D5',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: "1.21%",
  display: "inline"
}
export {
  warningGray,
  warningPurple,
  greenText700,
  subTable,
  tableText,
  contTitle,
  littleDetail,
  subCard,
  line22,
  purpleLittle,
  blackBold2,
  littleTextCard,
  cardSubT,
  modalSubT,
  boldTitle,
  lineText,
  lineBold,
  yellowText,
  redText,
  purpleText,
  grayBold,
  greenTextBold,
  greenText,
  greenBigTitle,
  lineSubText,
  boldGrayModal,
  grayModal,
  modalTitleAlt,
  modalSubtitle,
  typoGraphySubtitle,
  blueText,
  boldInputText,
  titleBox,
  textBox,
  inputText,
  grayText,
  backGreen,
  grayTextFile,
  graySubtext,
  blackBold,
  altTitle,
  littleText,
  loader,
  subLoader,
  modalTitle,
  modalText,
  modalTextBold,
  emptyResumeText,
  blackBold3
};
