import { Button, DialogActions, DialogContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import DownloadIcon from "../../assets/downloadIcon.svg";
import { formatNumber } from "../../utils/formatNumber";
import { defaultRowsPerPage } from "../../utils/constDefault";
import { GetDocumentById } from "../../services/getTributaryDocById";
import { downloadBase64File, downloadFromBase64 } from "../../utils/downloadBase64File";
import { CustomModal, CustomModalTitle } from "../UI/Modal/CustomModal";
import { modalTitleAlt, modalSubtitle } from "./Typography/typographyStyles";
import Toast from "./Toast/Toast";
import { Loading } from "../UI/Modal/Loading";
import { clientCCP } from "../../config/apolloClientsCCP";
import { ModalSpinnerLoad } from "../UI/Modal/spinnerLoad";

export const ModalDocumentos = ({ onOpen, onClose, setOpen, data }: any) => {
    const [openToastSolicitud, setOpenToastSolicitud] = useState<boolean>(false)
    const [toastSolicitud, setToastSolicitud] = useState({content: "", isSuccess: false,})
    const [loading, setLoading] = useState(false);

    const handleClose = () => { setOpen(false)}
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = (documentId: any) => {
        obtainDocument(documentId);
    }

    const obtainDocument = async (documentId: any) => {
        setLoading(true)
        try {
            const response = await clientCCP.query({
                query: GetDocumentById,
                variables: {
                    documentId
                },
            });
            Promise.all([response]).then(() => {
                const { ContenidoDelArchivo, NombreExtOriginalDelArchivo } = response.data.renditiontributaryDocsById.data;
                if (ContenidoDelArchivo && NombreExtOriginalDelArchivo) {
                    downloadFromBase64(ContenidoDelArchivo, NombreExtOriginalDelArchivo);//downloadFromBase64
                    setOpenToastSolicitud(true);
                    setToastSolicitud({ content: "Documento descargado", isSuccess: true });
                }
                else {
                    setOpenToastSolicitud(true);
                    setToastSolicitud({ content: "No se encontró el documento", isSuccess: false });
                }
            })

        } catch (error) {
            setLoading(false);
            setOpenToastSolicitud(true);
            setToastSolicitud({ content: "Su documento no se pudo descargar", isSuccess: false });
            //console.log(error)
        } finally {
            setLoading(false);
            setTimeout(() => {
                setOpenToastSolicitud(false);
            }, 3000);
        }
    }

    return (
        <>
            <CustomModal
                fullWidth
                maxWidth="lg"
                open={onOpen}
                onClose={onClose}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <CustomModalTitle id="editSurrender" onClose={onClose} >
                    <Typography sx={modalTitleAlt} pt="16px" pb="4px">
                        Visualizar documentos
                    </Typography>
                    <Typography sx={modalSubtitle} textAlign="center" pb="17px">
                        Selecciona un documento de cobro para descargar.
                    </Typography>
                </CustomModalTitle>

                <DialogContent dividers >
                    <Paper sx={{ padding: "0 !important", boxShadow: "none", border: ' 1px solid #E9EBEC', borderRadius: '8px', }}>
                        <TableContainer component={Paper} sx={{ padding: "0 !important" }}>
                            <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={"headTable"} >
                                            Tipo
                                        </TableCell>
                                        <TableCell className={"headTable"} >
                                            Folio
                                        </TableCell>
                                        <TableCell className={"headTable"} >
                                            Fecha Doc.
                                        </TableCell>
                                        <TableCell className={"headTable"} sx={{ textAlign: "right;" }}>
                                            Monto Neto
                                        </TableCell>
                                        <TableCell className={"headTable"} sx={{ textAlign: "right;" }} >
                                            Monto Exento
                                        </TableCell>
                                        <TableCell className={"headTable"} sx={{ textAlign: "right;" }} >
                                            Monto Impuesto
                                        </TableCell>
                                        <TableCell className={"headTable"} sx={{ textAlign: "right;" }}>
                                            Monto Total
                                        </TableCell>
                                        <TableCell className={"headTable"} data-testid="downloadBtn" >
                                            Descargar doc.
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((doc: any)=>(
                                        <TableRow key={doc.id} >
                                        <TableCell>
                                            {doc.tipoDoc}
                                        </TableCell>
                                        <TableCell>
                                            {doc.folioDoc}
                                        </TableCell>
                                        <TableCell>
                                            {doc.fechaDoc}
                                        </TableCell>
                                        <TableCell padding="none" sx={{ textAlign: "right;" }}>
                                            {doc.montoCobro? formatNumber(doc.montoCobro): ""}
                                        </TableCell>
                                        <TableCell padding = "none" sx={{ textAlign: "right;" }}>
                                            {doc.montoExento? formatNumber(doc.montoExento): ""}
                                        </TableCell>
                                        <TableCell padding = "none" sx={{ textAlign: "right;" }}>
                                            {doc.montoImpuesto? formatNumber(doc.montoImpuesto): ""}
                                        </TableCell>
                                        <TableCell padding = "none" sx={{ textAlign: "right;" }}>
                                            {doc.montoTotal? formatNumber(doc.montoTotal): ""}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton  onClick={() => handleDownload(doc.documentId)}>
                                                <img src={DownloadIcon} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            component="div"
                            count={data?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ mt: "14px", mb: "14px" }}
                            labelRowsPerPage={`Mostrar por página`}
                            labelDisplayedRows={(e) => {
                                return (
                                    "" +
                                    (page + 1) +
                                    " de " +
                                    Math.ceil(data?.length == 0 ? 0 : data?.length / rowsPerPage)
                                );
                            }}
                        />
                    </Paper >
                </DialogContent>

                <DialogActions sx={{ justifyContent: "end" }}>
                    <Button
                        sx={{ p: "15px 52px !important" }}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </CustomModal>
            
            
            
            {openToastSolicitud ? (
                <Toast
                    openAlert={openToastSolicitud}
                    setOpenAlert={setOpenToastSolicitud}
                    contentAlert={toastSolicitud}
                    onClose={() => setOpenToastSolicitud(false)}
                />
            ) : null}
            
            {
                loading &&
                <ModalSpinnerLoad onOpen={setLoading} onClose={setLoading} subText={"Cargando documento..."} />
            }
        </>
    );
};
