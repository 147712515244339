import { Snackbar, Alert, AlertColor } from "@mui/material";
import { style, iconStyle } from "./components/Styles";

type SnackbarArgs = {
  state: {
    abrir: boolean;
    severidad: AlertColor;
    mensaje: string;
  };
  setState: any;
  vertical?: "top" | "bottom";
  horizontal?: "center" | "left" | "right";
  onClose?: () => void;
};

export type SnackBarState = {
  abrir: boolean;
  mensaje: string;
  severidad: AlertColor;
};

const SnackBar = ({
  state,
  setState,
  vertical = "bottom",
  horizontal = "center",
  onClose
}: SnackbarArgs) => {
  const cerrarMensajero = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    if (onClose) onClose();
    setState({
      ...state,
      abrir: false,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      data-testid="snackTest"
      open={state.abrir}
      autoHideDuration={6000}
      onClose={cerrarMensajero}
      key={vertical + horizontal}
    >
      <Alert
        data-testid="alertTest"
        onClose={cerrarMensajero}
        severity={state.severidad}
        sx={style(state.severidad)}
        iconMapping={iconStyle(state.severidad)}
      >
        {state.mensaje}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
