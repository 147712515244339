import { Navigate, Outlet } from "react-router";
import { useIsAuthenticated } from "@azure/msal-react";
import useUserConfigurationContext from "../hooks/useUserConfigurationContext";

const ProtectedRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const { userConfiguration } = useUserConfigurationContext();

  return isAuthenticated && userConfiguration.length > 0 ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoutes;
