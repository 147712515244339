import { useMutation } from "@apollo/client";
import { WarningModalCustom } from "../../../components/UI/Modal/WarningModalCustom";
import { deleteRequestQuery } from "../../../services/deleteRequest";
import { AlertColor, Typography } from "@mui/material";

type deleteRequestInputs = {
    row: any,
    open: boolean,
    onClose: () => void,
    refetch: Function,
    showMessage: (message: string, severity: AlertColor) => void;
}

export const DeleteRequest = ({ row, open, onClose, refetch, showMessage }: deleteRequestInputs) => {

    const [deleteRquest, { loading }] = useMutation(deleteRequestQuery, {
        onCompleted: () => {
            showMessage("La solicitud ha sido eliminada exitosamente.", 'success');
            onClose();
            refetch();
        },
        onError: () => {
            showMessage("Ocurrió un error al eliminar la solicitud. Por favor, inténtelo de nuevo.", 'error');
        }
    });

    const deleteRequestCall = () => {
        deleteRquest({
            variables: {
                idCMD: [row.idCMD]
            }
        });
    }

    return (
        <WarningModalCustom
            headWarning
            onOpen={open}
            onClose={onClose}
            title="Eliminar Solicitud"
            contentText={
                <>
                    <Typography>
                        Esta es una acción irreversible
                    </Typography>
                    <Typography>
                        ¿Está seguro que deseas eliminar la solicitud N° <label style={{ color: "#5143D5" }}>{row.idCMD}</label>?
                    </Typography>
                </>
            }
            primaryButton="Sí, eliminar"
            secondaryButton="Cancelar"
            onConfirm={deleteRequestCall}
            loading={loading}
        />
    );
}