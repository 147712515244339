import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { DropdownIndicator } from "../../../components/ui-components/Table/dropDownIndicator";
import { stickyHead } from "../../../components/ui-components/Table/tableStyles";

type THeadCellTracing = {
  id: string;
  label: string;
  width: string;
  sorted?: boolean;
};

/** En primera columna, visualmente se muestra Fecha de entrega. Internamente la columna es Fecha de recepción */
const headCellTracing: THeadCellTracing[] = [
  {
    id: "fechaRecepcion",
    label: "Fecha de entrega",
    width: "200px",
    sorted: true,
  },
  {
    id: "fechaEmision",
    label: "Fecha de liquidación",
    width: "200px",
    sorted: true,
  },
  {
    id: "fechaIngreso",
    label: "Fecha de ingreso",
    width: "200px",
    sorted: true,
  },
  {
    id: "estado",
    label: "Estado",
    width: "117px",
    sorted: true,
  },
  {
    id: "numeroCuenta",
    label: "Nº C. Prestador",
    width: "170px",
    sorted: true,
  },
  {
    id: "id",
    label: "Nº Cuenta",
    width: "140px",
    sorted: true,
  },
  {
    id: "idCMD",
    label: "Nº C. Extranet",
    width: "170px",
    sorted: true,
  },
  {
    id: "numeroEnvio",
    label: "Nº Envío",
    width: "140px",
    sorted: true,
  },
  {
    id: "numeroCobro",
    label: "Nº Cobro",
    width: "140px",
    sorted: true,
  },
  {
    id: "rutPrestador",
    label: "Prestador",
    width: "132px",
    sorted: true,
  },
  {
    id: "canalOrigen",
    label: "Canal",
    width: "124px",
    sorted: true,
  },

  {
    id: "rutPaciente",
    label: "Beneficiario",
    width: "132px",
    sorted: true,
  },
  {
    id: "montoTotal",
    label: "Total facturado",
    width: "170px",
    sorted: true,
  },
  {
    id: "sending",
    label: "Migración",
    width: "170px",
    sorted: false,
  },
  {
    id: "acciones",
    label: "Acciones",
    width: "121px",
    sorted: false,
  },
];

type HeadTracingTableProps = {
  numSelected: number;
  rowCount: number;
  orderDirection?: "asc" | "desc";
  orderBy?: string;
  showSending: boolean;
  onRequestSort: (id: string) => void;
  loading: boolean;
  // TODO: TIPAR!
  handleSelectAllClick: (event: any) => void;
};

export const HeadTracingTable = ({
  numSelected,
  rowCount,
  orderDirection,
  orderBy,
  showSending,
  onRequestSort,
  handleSelectAllClick,
  loading,
}: HeadTracingTableProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className="tCell-p" sx={stickyHead}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={handleSelectAllClick}
            color="primary"
            inputProps={{ "aria-label": "select all desserts" }}
            disabled={loading}
          />
        </TableCell>
        {headCellTracing.map((headCell: any, i: number) => {
          if (headCell.id === "acciones") {
            return (
              <TableCell
                key={`${headCell.id}-${i}`}
                className="headerFirstSticky escritorioTrabajo"
              >
                {headCell.label}
              </TableCell>
            );
          } 
          if (headCell.id === "sending") {
            return (
              <>
                {showSending ? (
                  <TableCell
                  key={`${headCell.id}-${i}`}
                    className="headerTableEspecial escritorioTrabajo"
                  >
                    {headCell.label}
                  </TableCell>
                ) : null}
              </>
            );
          } else {
            if (headCell.sorted) {
              return (
                <TableCell
                  className={
                    orderBy === headCell.id
                      ? "escritorioTrabajo activeSort"
                      : "escritorioTrabajo headerSort"
                  }
                  key={`${headCell.id}-${i}-sorted`}
                  sortDirection={
                    orderBy === headCell.id
                      ? orderDirection ?? undefined
                      : undefined
                  }
                  width={headCell.width}
                  sx={{ minWidth: headCell.width }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={
                      orderBy === headCell.id
                        ? orderDirection ?? undefined
                        : "desc"
                    }
                    onClick={() => onRequestSort(headCell.id)}
                    IconComponent={() => (
                      <DropdownIndicator
                        active={orderBy === headCell.id}
                        order={
                          orderBy === headCell.id
                            ? orderDirection?.toLowerCase()
                            : "desc"
                        }
                      />
                    )}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  className={"escritorioTrabajo headerSort"}
                  key={`${headCell.id}-${i}`}
                  width={headCell.width}
                >
                  {headCell.label}
                </TableCell>
              );
            }
          }
        })}
      </TableRow>
    </TableHead>
  );
};
