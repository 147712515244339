import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00837A',
        },
        secondary: {
            main: '#FFFFFF',
            dark: '#FFFFFF',
            light: '#FFFFFF',
        },
        error: {
            main: '#E53935'
        },
        background: {
            default: '#F2F5F9'
        }
    },
    typography: {
        fontFamily: 'Interstate',
        h2: {
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '40px',
            letterSpacing: '0.0120588em',
            color: '#4C4C4C'
        },
        h3: {
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
            color: '#4C4C4C',
            letterSpacing: '0.0120588em'
        },
        h4: {
            fontStyle: 'normal',
            color: '#203442',
            fontWeight: 700,
            fontSize: '28px',
            lineHeight: '36px',
            letterSpacing: '0.0120588em'
        },
        h5: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
            textTransform: 'capitalize',
            letterSpacing: '0.0120588em',
            color: '#203442'
        },
        h6: {
            color: '#364855',
            fontWeight: 400,
            lineHeight: '20px',
            fontSize: '16px',
            letterSpacing: '0.0120588em'
        },
        subtitle2: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.0120588em',
            color: '#01736C'
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.0120588em',
            color: '#364855',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    textAlign: 'center',
                    borderRadius: '8px',
                    letterSpacing: '0.0021em',
                    textTransform: 'none',
                    lineHeight: '20px',
                    fontSize: '16px',
                    padding: '15px 16px',
                    // width: '133px',
                    '&:hover': {
                        color: "#FFFFFF",
                        backgroundColor: '#4DC0B8',
                        // boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)',
                    },
                    '&:active': {
                        color: "#FFFFFF",
                        backgroundColor: "#00736B",
                        boxShadow: '0px 2px 2px rgba(1, 164, 154, 0.25)'
                    },
                    "&:disabled": {
                        color: "#67737C",
                        boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
                        backgroundColor: "#E9EBEC"
                    },

                    "&.ml-button": {
                        marginLeft: '24px',
                    },

                    //variants
                    "&.large-button": {
                        height: '50px',
                    },

                    "&.medium-button": {
                        height: '40px',
                    },
                    "&.MuiButton-textSecondary": {
                        fontWeight: 700,
                        textAlign: "center",
                        borderRadius: "8px",
                        letterSpacing: "0.0021em",
                        textTransform: "none",
                        lineHeight: "20px",
                        fontSize: "16px",
                        padding: "15px 22px",
                        border: "solid 1px #00837A",
                        color: "#00837A",
                        background: "#FFFFFF",
                        "&.Mui-disabled": {
                            fontWeight: 700,
                            textAlign: "center",
                            borderRadius: "8px",
                            letterSpacing: "0.0021em",
                            textTransform: "none",
                            lineHeight: "20px",
                            fontSize: "16px",
                            padding: "15px 22px",
                            border: "solid 1px #79858E !important",
                            color: "#79858E !important",
                            background: "#FFFFFF !important",
                        },
                    },
                    "&.MuiButton-textPrimary": {
                        fontWeight: 700,
                        textAlign: "center",
                        borderRadius: "8px",
                        letterSpacing: "0.0021em",
                        textTransform: "none",
                        lineHeight: "20px",
                        fontSize: "16px",
                        padding: "15px 22px",
                        border: "none",
                        color: "#FFFFFF",
                        background: "#00837A",
                        "&.Mui-disabled": {
                            fontWeight: 700,
                            textAlign: "center",
                            borderRadius: "8px",
                            letterSpacing: "0.0021em",
                            textTransform: "none",
                            lineHeight: "20px",
                            fontSize: "16px",
                            padding: "15px 22px",
                            border: "none",
                            color: "#79858E !important",
                            background: "#F2F5F9 !important",
                            boxShadow: "none"
                        },
                    },
                    "&.MuiButton-textInherit.MuiButton-colorInherit": {
                        fontWeight: 700,
                        textAlign: "center",
                        letterSpacing: "0.0021em",
                        lineHeight: "20px",
                        fontSize: "16px",
                        padding: "15px 22px",
                        boxShadow: "none",
                        color: "#01736C",
                        background: "transparent",
                    },
                    "&.MuiButton-textInherit.MuiButton-colorInherit.Mui-disabled": {
                        fontWeight: 700,
                        textAlign: "center",
                        letterSpacing: "0.0021em",
                        lineHeight: "20px",
                        fontSize: "16px",
                        padding: "15px 22px",
                        boxShadow: "none",
                        color: "#79858E",
                        background: "transparent",
                    },
                    //table buttons
                    "&.doc-button": {
                        padding: '14px 18px',
                        height: '40px',
                        '&:hover': {
                            color: "#00837A",
                            backgroundColor: "#E6F6F5"
                            // boxShadow: '0px 4px 10px rgba(1, 115, 108, 0.35)',
                        },
                        '&:active': {
                            color: "#FFFFFF",
                            backgroundColor: "#00736B",
                            boxShadow: '0px 2px 2px rgba(1, 164, 154, 0.25)'
                        },
                        "&:disabled": {
                            color: "#67737C",
                            boxShadow: '0px 2px 2px rgba(121, 133, 142, 0.25)',
                            backgroundColor: "#E9EBEC"
                        }
                    },

                    "&.error-table-button": {
                        backgroundColor: '#E53935',
                        padding: '14px 18px',
                        height: '40px',
                        '&:hover': {
                            color: "#FFFFFF",
                            backgroundColor: "#E53935",
                            boxShadow: "0px 2px 6px rgba(229, 57, 53, 0.35)"
                        },
                        '&:active': {
                            color: "#FFFFFF",
                            backgroundColor: "#AB0005",
                            boxShadow: "0px 2px 2px rgba(229, 57, 53, 0.25)"
                        },
                        "&:disabled": {
                            color: "#67737C",
                            backgroundColor: "#E9EBEC"
                        },
                    },
                }
            },
            variants: []
        },
        MuiInputBase: {
            styleOverrides: {
                root: {

                    "&.select-special": {
                        width: '67px',
                        height: '32px',
                        borderRadius: '8px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#00736B',
                        letterSpacing: '0.0120588em',
                        "& fieldset": {
                            border: "1px solid #00736B",
                            width: '67px',
                        },
                        "& .MuiSelect-icon": {
                            color: '#00736B'
                        }
                    }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    "&.icon-pointer": {
                        cursor: 'pointer'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#FFFFFF',
                    color: 'rgba(0, 0, 0, 0.87)',
                    border: '1px solid #dadde9',
                    maxWidth: '576px',
                    maxHeight: '263px',
                    borderRadius: '8px',
                    boxShadow: '0px 6px 20px #B2B9CF'
                },
                tooltipArrow: {
                    fontSize: 28,
                    '& .MuiTooltip-arrow::before': {
                        backgroundColor: "#4D5D68",
                        boxShadow: '0px 2px 5px rgba(201, 207, 227, 0.75)',
                        // height: '27px',
                    }
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.paper-tables": {
                        width: '100%',
                        boxShadow: 'none',
                        overflow: 'hidden',
                    },
                    "&.paper-calendar": {
                        "& .MuiSvgIcon-root": {
                            color: "#01A49A"
                        }
                    }

                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    padding: '0',
                    boxShadow: 'none',
                    '&.tContainer-padding-0': {
                        padding: '0 0',
                        borderRadius: '0px',
                    },
                    '::-webkit-scrollbar': {
                        height: '8px'
                    },
                    '::-webkit-scrollbar-track': {
                        background: '#FFFFFF'
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: ' #4DC0B8'
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                        background: ' #399e97'
                    },
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {

                    '&.pCells-boni': {
                        '&.MuiTableCell-body': {
                            padding: '10px 14px',
                            color: "red",
                        }
                    }
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover': {
                        '&:hover': {
                            backgroundColor: '#E6F6F5',
                        },
                    },
                    "&.bg-color-green": {
                        backgroundColor: '#E6F6F5',
                    }
                }
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '&.color-head-table': {
                        backgroundColor: '#F8F9F9'
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    '&.MuiTableCell-head': {
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: "#67737C",
                        letterSpacing: '0.0120588em'
                    },
                    '&.MuiTableCell-body': {
                        padding: '18px 14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: "#4C4C4C",
                        letterSpacing: '0.0120588em',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',

                    },
                    '&.tCell-p': {
                        padding: '10px 14px',
                        textTransform: 'capitalize',
                        '& .MuiTypography-root': {
                            fontSize: '14px',
                        }
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'center',
                    '& .MuiInputBase-input': {
                        border: '1px solid #BCC2C6',
                        borderRadius: '8px',
                        padding: '4px 8px 4px 12px',
                        width: '22px',
                        height: '16px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '&:focus': {
                            borderRadius: '8px',
                            backgroundColor: 'white'
                        }
                    },
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    "&.background-modal": {
                        "& .MuiBackdrop-root": {
                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                        },
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    "&.full-width": {
                        width: '100%'
                    }
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                standardInfo: {
                    fontWeight: 700,
                    fontStyle: 'normal',
                    borderRadius: '8px',
                    boxShadow: 'none'
                },
                standardSuccess: {
                    border: '1px solid #7CB342',
                    backgroundColor: '#F1F8E9',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    color: '#203442',
                    borderRadius: '8px',
                    boxShadow: 'none'
                },
                standardError: {
                    border: '1px solid #E57373',
                    backgroundColor: '#FFEBEE',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    color: '#203442',
                },
                standardWarning: {
                    backgroundColor: '#F1F8E9',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    color: '#203442',
                }
            },
        },
    },


});

export default theme
