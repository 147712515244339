import { Button, TableCell } from '@mui/material';
import FileGreen from "../../assets/fileGreenI.svg"
import ContextRenditionTracking, { ContextValueTracking } from '../../context/contextRenditionTracking';
import { useContext } from 'react';
import { getFormatYYYYMMDD } from '../../utils/getFormatDate';

export const CeldaBody = ({count, typeRenditions, date, setDetailVisible}: any) => {
  const { dataContext, setDataContext } = useContext<ContextValueTracking>(ContextRenditionTracking);
  const { update } = dataContext;
  const handleDetails = () => {
    setDetailVisible(true)
    setDataContext({ ...dataContext, type: typeRenditions, dateRequest: getFormatYYYYMMDD(date), update: !update });
  }

  return (
    <TableCell>
        {
            count === 0 
            ?
            0
            :
            <Button
                variant="outlined"
                className="doc-button"
                startIcon={<img src={FileGreen} />}
                disableRipple
                onClick={handleDetails}
            >
                {" "}
                { count }
            </Button>
        }
    </TableCell>
  )
}
