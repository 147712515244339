import { Skeleton } from "@mui/material";


const SkeletonBar = ({ width, height }: any) => {
    return (
        <Skeleton
            sx={{ bgcolor: '#E9EBEC', borderRadius:"8px" }}
            variant="rectangular"
            animation="wave"
            width={width}
            height={height}

        />
    );
}

export default SkeletonBar;