export const dataPrestador = (data: number, holding: any) => {
    const sessionData = sessionStorage.getItem("info-prestadores");
    const prestadoresArray = sessionData
      ? JSON.parse(sessionData).prestadores
      : [];
    const prestador = prestadoresArray.find((x: any) => x.rutPrestador === holding);
    if(prestador)
    {
    switch (data) {
        case 1: // SLA
        if(prestador.slaDiasResolucionRendicion){
            return Number(prestador.slaDiasResolucionRendicion);
        }
        break; 
        case 2: //FECHA PROBABLE DE PAGO
        if(prestador.visibilidadFechaCompromiso){
            return prestador.visibilidadFechaCompromiso;
        }
        break;  
        case 3: //NOMBRE PRESTADOR
        if(prestador.nombrePrestador){
            return prestador.nombrePrestador;
        }
        break;
        
        case 4: //NOMBRE HOLDING
            if (prestador.razonSocialPrestadorHolding) {
                return prestador.razonSocialPrestadorHolding;
            }
            break;
        case 5: //RUT HOLDING
            if (prestador.rutPrestadorHolding) {
                return prestador.rutPrestadorHolding;
            }
            break; 
    
        default:
            break;
    }
      
    }
    return 0;
  };
