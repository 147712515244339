export const truncateText = (filename: any, maxLength: any) => {
  if (filename.length <= maxLength) {
    return filename;
  }

  const extensionIndex = filename.lastIndexOf(".");
  const extension = filename.substring(extensionIndex);

  if (extensionIndex > 0) {
    const truncatedFilename = filename.substring(0, extensionIndex) + "..";    
    return truncatedFilename + extension;
  } else {
    return filename.substring(0, maxLength);
  }
};
  