import { useLazyQuery } from "@apollo/client";
import { obtenerDocumento } from "../../../services/obtenerDocumento";
import { AlertColor } from "@mui/material";
import { useState } from "react";

type useFileHandlerProps = {
  showMessage: (message: string, severity: AlertColor) => void
};

const useFileHandler = ({ showMessage }: useFileHandlerProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [fnObtenerDocumento] = useLazyQuery(obtenerDocumento);

  const handleObtenerDocumento = async (idSolicitud: number, tipoDocumento: string, isMassive?: boolean, pendingDocs?: number[]) => {
    !isMassive && setLoading(true);
    await fnObtenerDocumento({
      variables: { idSolicitud, tipoDocumento },
      onCompleted: (res) => {
        switch (res.obtenerDocumentoPdf.estado) {
          case 1:
            showMessage("Se ha generado su documento exitosamente", 'success');
            window.open(res.obtenerDocumentoPdf.message, '_blank', 'noopener,noreferrer');
            !isMassive && setLoading(false);
            break;
          case 2:
            if (isMassive && pendingDocs) {
              pendingDocs.push(idSolicitud);
            } else {
              setTimeout(() => handleObtenerDocumento(idSolicitud, tipoDocumento), 10000)
            };
            showMessage(`Documento para la solicitud ${idSolicitud} en proceso de creación, espere por favor...`, 'info');
            break;
          case 3:
            showMessage("No se ha podido generar el documento, por favor intente nuevamente", 'error');
            !isMassive && setLoading(false);
            break;
          case 4:
            showMessage(res.obtenerDocumentoPdf.message, 'info');
            !isMassive && setLoading(false);
            break;
          default:
            showMessage("No se ha podido generar el documento, por favor intente nuevamente", 'error');
            !isMassive && setLoading(false);
            break;
        }
      },
      onError: () => {
        showMessage("No se ha podido generar el documento, por favor intente nuevamente", 'error');
        !isMassive && setLoading(false);
      }
    });
  }

  const handleObtenerDocumentos = async (idSolicitudes: number[], tipoDocumento: string, resetSelectedRecords: () => void) => {
    setLoading(true);
    const pendingDocs: number[] = [];
    for (const idSolicitud of idSolicitudes) {
      await handleObtenerDocumento(idSolicitud, tipoDocumento, true, pendingDocs);
    }
    if (pendingDocs.length > 0) {
      setTimeout(async () => await handleObtenerDocumentos(pendingDocs, tipoDocumento, resetSelectedRecords), 5000);
    } else {
      resetSelectedRecords();
      setLoading(false);
    }

  }

  return {
    loadingObtenerDocumento: loading,
    handleObtenerDocumento,
    handleObtenerDocumentos,
  };
}

export default useFileHandler;