import {
  Grid,
  Box,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import Upload from "../../../../assets/uploadWhite.svg";
import "../../../../assets/cargaArchivo.css";
import { CustomSelect } from "../../../../components/ui-components/customSelect";
import { SecondaryButton } from "../../../../components/ui-components/Buttons/Button";
import { emptyResumeText, inputText, titleBox } from "../../../../components/ui-components/Typography/typographyStyles";

export const UploadFile = ({
  rutBeneficiario,
  idSolicitud,
  sendFile,
  selectTypeFile,
  setToastData,
  setTypeFile,
  typeFile,
  usuario,
  loading,
}) => {
  const transformarBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ({ target }) => {
      const toBase64 = target.result.toString();
      const base64WithoutPrefix = toBase64.slice(
        `data:${file.type};base64,`.length
      );
      sendFile({
        variables: {
          tipoDocumento: selectTypeFile[typeFile - 1].toUpperCase(),
          contenidoDocumento: base64WithoutPrefix,
          idSolicitud: idSolicitud,
          usuario: usuario,
          rutBeneficiario: rutBeneficiario,
          nombreDocumento: file.name,
        },
      });
    };
  };

  const handleOnChangeTypeFile = (newTypeFile) => {
    setTypeFile(newTypeFile.target.value);
  };

  const fillSelectTypeFileNames = () => {
    return Object.keys(selectTypeFile).map(function (key) {
      return selectTypeFile[key];
    });
  };

  const handleButtonAttachDisabled = () => {
    return typeFile !== 0 ? false : true;
  };

  const onDrop = (accFiles, rejFiles) => {
    if (accFiles[0]) {
      transformarBase64(accFiles[0]);
    } else {
      setToastData({
        abrir: true,
        mensaje: "Archivo ingresado no es png, jpg, pdf o pesa más de 2MB",
        severidad: "error",
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: handleButtonAttachDisabled() || loading,
    accept: {
      "image/gif": [".gif"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxSize: 2000 * 1024, // 2MB
  });

  return (
    <Grid data-testid="uploadTest">
      <Divider variant="fullWidth" />
      <Box pt="24px">
        <Typography sx={titleBox}>
          Carga de documentos
        </Typography>
        <Typography sx={emptyResumeText}>
          Aquí podrás cargar los documentos de cobro, antecedentes clínicos y antecedentes adicionales.
        </Typography>
        <Box display="flex" alignItems="flex-end" pt="20px">
          <Box width="290px" mr="24px">
            <Typography sx={inputText}>
              Tipo de Documento
            </Typography>
            <CustomSelect
              onChange={(newTypeFile) => handleOnChangeTypeFile(newTypeFile)}
              value={typeFile || 0}
              data={fillSelectTypeFileNames()}
              titulo={"Seleccione un tipo"}
              name="fileType"
            />
          </Box>
          <div {...getRootProps()}>
            <input data-testid="fileDropButton" {...getInputProps()} />
            <SecondaryButton
              className="large-button"
              disabled={handleButtonAttachDisabled() || loading}
            >
              {loading ? (
                <CircularProgress className="spinnerGreen" size={30} />
              ) : (
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: "10px"}}>
                  <img src={Upload} className="white-color-svg" alt="" />
                  Cargar Archivo
                </Box>
              )}
            </SecondaryButton>
          </div>
          <label className="tipoFormatoMin" style={{ paddingLeft: "16px" }}>
            Formato aceptados: PDF, JPG, PNG<br></br>
            Peso máximo de 2MB
          </label>
        </Box>
      </Box>
    </Grid>
  );
};
