import { gql } from "@apollo/client"

export const GetTributaryDocsByRenditionId = gql`
query($idRendition:Int){
    tributaryDocsByRenditionId(idRendition: $idRendition){
        estado mensaje totalRecords data {
            id
            medicalRenditionId
            tipoDoc
            folioDoc
            fechaDoc
            montoCobro
            montoExento
            montoImpuesto
            montoTotal
            urlSPO
            documentId
        }
    }
}`;
