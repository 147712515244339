import { useState, useEffect } from "react";
import { AlertColor } from "@mui/lab";
import { useLazyQuery, useQuery } from "@apollo/client";
import { queryGetAllCuentasSolicitudes } from "../../../services/tracingCmServices";
import { filterInputArgs, primitiveFilterArgs } from "../types";
import { exportRecords } from "../shared/exportToExcel";
import { statusEnumGrouped } from "../shared/status";
import _ from "lodash";

const emptyResume = {
  entregada: 0,
  enproceso: 0,
  liquidada: 0,
  liquidadaManual: 0,
  rechazada: 0,
  devuelta: 0,
  eliminada: 0,
};

type useNewTracingCMProps = {
  showMessage: (message: string, severity: AlertColor) => void
};

const useNewTracingCM = ({ showMessage }: useNewTracingCMProps) => {
  const [records, setRecords] = useState<any[]>([]);
  const [resumeAccounts, setResumeAccounts] = useState<{
    [key: string]: number;
  } | null>(null);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  /**
   * Lista la cantidad de estados. Utilizado para habilitar o no el btn para acciones masivas. 
   * 99 indica entregadas sin archivos ¿refactor?
   * Estados migración 1 (en cola), 2 (procesando), 4 (exitoso) también bloquea. Correspondientes a 101, 102, 104 
   */
  const [selectedRecordsType, setSelectedRecordsType] = useState<{ [key: number]: number }>({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    99: 0,
    101: 0,
    102: 0,
    104: 0,
  });
  const [error, setError] = useState<boolean>(false);
  const [isEmptyState, setIsEmptyState] = useState<boolean>(true);
  const [pageAccount, setPageAccount] = useState<number>(0);
  const [rowsAccount, setRowsAccount] = useState<number>(25);
  const [quickStartDate, setQuickStartDate] = useState<string | null>(null);
  const [quickStatus, setQuickStatus] = useState<number | null>(null);
  const [count, setCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<filterInputArgs>({
    id: "estado",
    dir: "ASC",
  });
  const [orderByCol, setOrderByCol] = useState<string>("estado");
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [filterData, setFilterData] = useState<primitiveFilterArgs>({});

  useEffect(() => {
    setIsEmptyState(false);
  }, []);

  const resetSelectedRecords = () => {
    setSelectedRecords([]);
    setSelectedRecordsType({
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      99: 0,
      101: 0,
      102: 0,
      104: 0,
    });
  }

  const {
    loading: isLoading,
    refetch,
    startPolling,
    stopPolling,
    networkStatus,
  } = useQuery(queryGetAllCuentasSolicitudes, {
    variables: {
      limit: rowsAccount,
      offset: pageAccount * rowsAccount,
      orderBy: orderBy,
      filterBy: filterData,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      if (res.getAllCuentasSolicitudes.total === 0) {
        setRecords([]);
        setResumeAccounts(emptyResume);
        setCount(0);
      } else {
        setRecords(res.getAllCuentasSolicitudes.cuentas);
        setResumeAccounts({
          entregada: res.getAllCuentasSolicitudes.entregada,
          enproceso: res.getAllCuentasSolicitudes.enproceso,
          liquidada: res.getAllCuentasSolicitudes.liquidada,
          liquidadaManual: res.getAllCuentasSolicitudes.liquidadaManual,
          rechazada: res.getAllCuentasSolicitudes.rechazada,
          devuelta: res.getAllCuentasSolicitudes.devuelta,
          anulada: res.getAllCuentasSolicitudes.anulada,
          eliminada: res.getAllCuentasSolicitudes.eliminada
        });
        setCount(res.getAllCuentasSolicitudes.total);
      }
      setError(false);
    },
    onError: (err) => {
      console.warn("Error getCuentasMedicas", err);
      setRecords([]);
      resetSelectedRecords();
      setResumeAccounts(emptyResume);
      setCount(0);
      setError(true);
    },
  });

  useEffect(() => {
    startPolling(30000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  useEffect(() => {
    setRecords([]);
  }, [pageAccount, rowsAccount])

  const getRecords = (
    incomingFilters: primitiveFilterArgs,
  ) => {
    resetSelectedRecords();
    if (_.isEqual(incomingFilters, filterData)) {
      if (pageAccount === 0) {
        refetch()
      } else {
        setPageAccount(0)
      }
    } else {
      setRecords([]);
      setError(false);
      setIsEmptyState(false);
      setFilterData(incomingFilters);
      setPageAccount(0);
    }
  };

  const onRequestSort = (id: string) => {
    let newOrderBy = {} as filterInputArgs;
    if (id === orderByCol) {
      const direction = orderDirection === "asc" ? "DESC" : "ASC";
      newOrderBy = {
        id,
        dir: direction,
      };
      setOrderDirection(direction === "ASC" ? "asc" : "desc");
    } else {
      newOrderBy = {
        id,
        dir: "DESC",
      };
      setOrderDirection("desc");
    }
    setOrderByCol(id);
    setOrderBy(newOrderBy);
  };

  const isSelected = (name: string) => selectedRecords.indexOf(name) !== -1;

  // TODO: TIPAR!
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const updatedSelectedRecordsType = { ...selectedRecordsType };
      const newSelecteds = records
        ? records.map((item: any) => {
          updatedSelectedRecordsType[item.estado] += 1;
          if (item.numeroDocumentos === 0 && item.estado === statusEnumGrouped.ENTREGADA) updatedSelectedRecordsType[99] += 1;
          if (item.estadoMigracion === 1) updatedSelectedRecordsType[101] += 1;
          if (item.estadoMigracion === 2) updatedSelectedRecordsType[102] += 1;
          if (item.estadoMigracion === 4) updatedSelectedRecordsType[104] += 1;
          return `${item.type === 2 ? item.id : item.idCMD ?? item.id}-${item.canalOrigen}`;
        })
        : [];
      setSelectedRecords(newSelecteds);
      setSelectedRecordsType(updatedSelectedRecordsType);
      return;
    } else {
      resetSelectedRecords();
    }
  };

  const findRecordByIdCMDCanal = (selectedId: string) =>
    records.find(
      (record) =>
        `${record.type === 2 ? record.id : record.idCMD ?? record.id}-${record.canalOrigen
        }` === selectedId
    );

  const handleItemChecked = (selectedId: string) => {
    const updatedSelectedRecordsType = { ...selectedRecordsType };
    const selectedRecord = findRecordByIdCMDCanal(selectedId);
    if (selectedRecords.includes(selectedId)) {
      updatedSelectedRecordsType[selectedRecord.estado] -= 1;
      if (selectedRecord.numeroDocumentos === 0 && selectedRecord.estado === statusEnumGrouped.ENTREGADA) updatedSelectedRecordsType[99] -= 1;
      if (selectedRecord.estadoMigracion === 1) updatedSelectedRecordsType[101] -= 1;
      if (selectedRecord.estadoMigracion === 2) updatedSelectedRecordsType[102] -= 1;
      if (selectedRecord.estadoMigracion === 4) updatedSelectedRecordsType[104] -= 1;
      setSelectedRecords(
        selectedRecords.filter((selected) => selected !== selectedId)
      );
    } else {
      updatedSelectedRecordsType[selectedRecord.estado] += 1;
      if (selectedRecord.numeroDocumentos === 0 && selectedRecord.estado === statusEnumGrouped.ENTREGADA) updatedSelectedRecordsType[99] += 1;
      if (selectedRecord.estadoMigracion === 1) updatedSelectedRecordsType[101] += 1;
      if (selectedRecord.estadoMigracion === 2) updatedSelectedRecordsType[102] += 1;
      if (selectedRecord.estadoMigracion === 4) updatedSelectedRecordsType[104] += 1;
      setSelectedRecords([...selectedRecords, selectedId]);
    }
    setSelectedRecordsType(updatedSelectedRecordsType);
  };

  // TODO: TIPAR!
  const processRecordData = async (result: any) => {
    let temp = result.getAllCuentasSolicitudes;
    if (temp) {
      while (temp.cuentas.length < temp.total) {
        const { data } = await fetchMore({
          variables: {
            offset: temp.cuentas.length,
            limit: 200,
            filterBy: filterData,
            orderBy: {
              id: "estado",
              dir: "ASC",
            },
          },
        });
        const mergeSol = [
          ...temp.cuentas,
          ...data.getAllCuentasSolicitudes.cuentas,
        ];
        temp = {
          ...temp,
          cuentas: mergeSol,
        };
      }

      const reporte = temp.cuentas;
      const didExport = exportRecords(reporte);
      if (didExport) {
        showMessage(
          "El listado de registro de cuentas se exportó correctamente.",
          "success"
        );
      } else {
        showMessage(
          "Ocurrió un error al exportar los registros de cuentas. Por favor, inténtalo de nuevo.",
          "error"
        );
      }
    }
  };

  const [getRecordData, { loading: isLoadingExport, fetchMore }] = useLazyQuery(
    queryGetAllCuentasSolicitudes,
    {
      onCompleted: (res) => processRecordData(res),
      onError: () =>
        showMessage(
          "Ocurrió un error al exportar los registros de cuentas. Por favor, inténtalo de nuevo.",
          "error"
        ),
    }
  );

  const handleExportRecords = () => {
    setIsEmptyState(false);
    getRecordData({
      variables: {
        limit: 200,
        offset: 0,
        orderBy: {
          id: "estado",
          dir: "ASC",
        },
        filterBy: filterData,
      },
    });
  };

  return {
    networkStatus,
    count,
    error,
    isEmptyState,
    isLoading,
    isLoadingExport,
    orderByCol,
    orderDirection,
    pageAccount,
    records,
    rowsAccount,
    resumeAccounts,
    selectedRecords,
    selectedRecordsType,
    filterData,
    quickStatus,
    quickStartDate,
    getRecords,
    isSelected,
    handleExportRecords,
    handleItemChecked,
    handleSelectAllClick,
    onRequestSort,
    refetch,
    resetSelectedRecords,
    setRecords,
    setPageAccount,
    setRowsAccount,
    setQuickStatus,
    setQuickStartDate,
  };
};

export default useNewTracingCM;
