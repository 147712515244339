const sortTableSeguimiento = (table: any) => {
  return table.sort((a: any, b: any) => (a.dia - b.dia))
};

const sortTableDetails = (table: any) => {
  // return table.sort((a:any, b:any) => (a.numeroCuenta - b.numeroCuenta))
  return table
};

const formatRut = (rut: string) => {
  return rut === null ? "" :
    rut.replace(/[^\d](?=[\dK]$)|[^\dK]/gi, "")
      .replace(/\B(?=(\d{3})+(\d|K)$)/gi, ".")
      .replace(/\B.$/, "-$&")
}

const sortTableHistorial = (table: any) => {
  return table.slice().sort((a: any, b: any) => (a.id - b.id));
};

const formatRutForQuery = (rut: string) => {
  return rut.replace(/[^\d](?=[\dK]$)|[^\dK]/g, "")
    .replace(/\B.$/, "-$&")
}

const isNotUndefinedAndNull = (value: any) => {
  return (value !== null && value !== undefined);
}

const openFromBase64 = (base64Str: string, fileName: string) => {
  const byteCharacters = window.atob(base64Str);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf;base64', });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export { 
  sortTableSeguimiento, 
  sortTableDetails, 
  sortTableHistorial, 
  formatRut, 
  formatRutForQuery, 
  isNotUndefinedAndNull,
  openFromBase64}
