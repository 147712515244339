import { Box, CircularProgress, Modal, Typography } from "@mui/material";

import { styledLoaderModal } from "../../UI/Modal/Modal";

function ModalLoader({ open, title }: any){
  return (
    <Modal open={open} className="background-modal">
      <Box sx={styledLoaderModal}>
        <Box>
          <CircularProgress
            color="primary"
            thickness={5}
            size="24px"
            sx={{ marginRight: "10px" }}
          />
          <Typography variant="caption" sx={{ verticalAlign: "super" }}>
            {title}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};
 export default ModalLoader