import { Button, Grid, IconButton, Popover, Typography } from "@mui/material";
import { grayTextFile } from "../ui-components/Typography/typographyStyles";
import UploadGray from "../../assets/uploadGray.svg";
import UploadGreen from "../../assets/uploadGreen.svg";
import DownloadRed from "../../assets/redDownload.svg";
import DocIcon from "../../assets/icons/documentIcon.svg";
import ExampleFormat from "../../assets/images/formatExample.png";
import Close from '../../assets/close.svg'
import { useContext, ChangeEvent, useRef, useState } from 'react';
import { downloadBase64File } from "../../utils/downloadBase64File";
import { getFormattedDateTime } from "../../utils/getFormatDate";
import { fileValidate } from "../../utils/fileValidate";
import { uploadFoliosRendicion } from "../../services/postFileMedicalRendition";
import { clientCCP } from "../../config/apolloClientsCCP";
import { renditionType } from "../../interfaces/rendition";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import { defaultContent } from "../../utils/constDefault";
import ModalLoader from "../ui-components/ModalLoader/ModalLoader";
import Toast from "../ui-components/Toast/Toast";
import { WarningModal } from "../UI/Modal/modalWarning";
import { ButtonAcceptedModal } from "../UI/Modal/ButtonAcceptedModal";
// import iconExternalLink from "../../assets/icons/external_link_icon.png";
// import exampleInvoice from "../../assets/images/exampleInvoice.png";
import { dataPrestador } from "../../utils/getInfoProvider";

export const SendFile = () => {
  const { dataContext, setDataContext } =
    useContext<ContextValue>(ContextRendition);
  const { holding, multiplesFacturas, diasCtrlCaducidad, idRendition, insertInvoices, fileErrorBase64 } = dataContext;
  const [contentAlert, setContentAlert] = useState<any>(defaultContent);

  const [alert, setAlert] = useState<boolean>(false);
  const [openModalError, setOpenModalError] = useState<boolean>(false);
  const [openLoad, setOpenLoader] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorState, setErrorState] = useState<string>();
  const [errorStatus, setErrorStatus] = useState<number>(200);
  const [openModalUpdateDocument, setOpenModalUpdateDocument] = useState<boolean>(false);

  const [exampleTarget, setExampleTarget] = useState<HTMLButtonElement | null>(null);
  const openModalFormat = Boolean(exampleTarget);
  const idPopover = openModalFormat ? 'simple-popover' : undefined;

  const handleDownloadFile = () =>
    errorState &&
    downloadBase64File(
      errorState,
      `ERROR_BonosCargados_${getFormattedDateTime()}.txt`,
      "text/plain"
    );

  const handleButtonClick = () => {
    if (idRendition > 0 && insertInvoices.length > 0) {
      setOpenModalUpdateDocument(true)
    } else {
      fileInputRef.current && fileInputRef?.current.click();
    }
  }

  const handleAcceptedUpdate = () => {
    fileInputRef.current && fileInputRef?.current.click();
  }

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setOpenLoader(true);
      const { status, message } = fileValidate(selectedFile, 4, [
        "xls",
        "xlsx",
      ]);
      if (status) {
        setDataContext({
          ...dataContext,
          file: selectedFile,
        });
        try {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(selectedFile);
          fileReader.onload = async () =>
            SendMutation(
              fileReader.result?.toString().split(",")[1],
              selectedFile,
              dataContext.idRendition
            );
        } catch (error) {
          clean(selectedFile, 0, "");
          console.error("Error al leer el archivo:", error);
          openToast(
            false,
            "Error al generar archivo, por favor intente nuevamente",
            "top",
            { marginTop: "100px" }
          );
          setOpenLoader(false);
        }
      } else {
        clean(selectedFile, 0, "");
        openToast(false, message, "top", { marginTop: "100px" });
        setOpenLoader(false);
      }
    }
    event.target.value = "";
  };

  const getUser = () => {
    try {
      const sessionData = sessionStorage.getItem("info-session");
      const infoData = sessionData ? JSON.parse(sessionData) : {};
      return infoData.email;
    } catch (error) {
      return "";
    }
  }

  const SendMutation = async (
    base64File: string | undefined,
    selectedFile: File,
    idRendition: number
  ) => {
    try {
      if (base64File) {
        const result = await clientCCP.mutate({
          mutation: uploadFoliosRendicion,
          variables: {
            inputFile: base64File,
            fileName: selectedFile.name,
            rutPrestador: holding,
            namePrestador: dataPrestador(3, holding),
            rutHolding: dataPrestador(4, holding),
            nameHolding: dataPrestador(5, holding),
            medicalRenditionId: idRendition,
            diasCtrlCaducidad: diasCtrlCaducidad,
            multiplesFacturas: multiplesFacturas,
            providerUser: getUser()
          },
        });

        const {
          estado,
          mensaje,
          file,
          errorCount,
          data,
        }: {
          errorCount: number;
          file: string;
          estado: number;
          mensaje: string;
          data: renditionType;
        } = result.data.uploadFoliosRendicion;


        setErrorStatus(estado)
        if (estado === 200) {

          if (data.montoTotal === 0) {
            setErrorState("Error")
            openToast(false, "Usted esta tratando de rendir Bonos que no presentan monto a pagar.", "top", { marginTop: "100px" });
            clean(selectedFile, errorCount, "");
          } else {


            const facturas = data.facturas;
            let invoices = facturas.map((x) => {
              return {
                id: String(x),
                nombre: String(x),
                value: String(x),
              };
            });

            let updateInsertInvoices: any = [];
            if (insertInvoices.length > 0) {
              updateInsertInvoices = insertInvoices.filter((insertInvoice) =>
                facturas.includes(insertInvoice.folioDocument)
              );
              invoices = invoices.filter((invoice: { id: any; }) =>
                !insertInvoices.some((insertInvoice) => insertInvoice.folioDocument === invoice.id)
              );
            }

            setDataContext({
              ...dataContext,
              idRendition: data.idRendicion,
              invoices,
              totalMedicalBonds: data.bonosTotal,
              amountToPay: data.montoTotal,
              fileErrorBase64: "",
              file: selectedFile,
              insertInvoices: updateInsertInvoices,
              errorCount: errorCount,
            });

            openToast(
              true,
              "La carga del archivo ha sido realizada correctamente",
              "top",
              { marginTop: "100px" }
            );
          }
        } else if (file) {
          setOpenModalError(true);
          setErrorState(file);
          clean(selectedFile, errorCount, file);
        } else {
          setErrorState(undefined);
          openToast(false, mensaje, "top", { marginTop: "100px" });
          clean(selectedFile, errorCount, "");
        }
        setOpenLoader(false);
      } else {
        clean(selectedFile, 0, "");
        openToast(
          false,
          "Error al generar archivo, por favor intente nuevamente",
          "top",
          { marginTop: "100px" }
        );
        setErrorState("Error");
        setOpenLoader(false);
      }
    } catch (error) {
      openToast(
        false,
        "Error al agregar archivo de bonos a rendir, por favor, intente nuevamente",
        "top",
        { marginTop: "100px" }
      );
      setOpenLoader(false);
    }
  };

  const clean = (file: File, errorCount: number, fileErrorBase64: string) => {

    setDataContext({
      ...dataContext,
      idRendition: dataContext.idRendition !== 0 ? dataContext.idRendition : 0,
      invoices: [],
      totalMedicalBonds: 0,
      amountToPay: 0,
      file,
      fileErrorBase64,
      insertInvoices: [],
      errorCount,
    });
  };

  const openToast = (
    isSuccess: boolean,
    content: string,
    typeAlert: string,
    styleAlert: object
  ) => {
    setContentAlert({ isSuccess, content, typeAlert, styleAlert });
    setAlert(true);
  };

  const handleOpenExample = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExampleTarget(event.currentTarget)
  }

  const handleCloseExample = () => {
    setExampleTarget(null)
  }

  return (
    <Grid item xs={3} textAlign={"right"}>
      <WarningModal
        onOpen={openModalError}
        setOpen={setOpenModalError}
        buttonCancel={true}
        title="Error en archivo "
        subtitle={
          "Favor descargue el archivo con el detalle de Folios de bonos, que identifica el tipo de error por cada uno de ellos, que hemos preparado para usted."
        }
        onClick={handleDownloadFile}
        secondaryButton={<ButtonAcceptedModal title={"Descargar errores"} />}
      />

      <WarningModal
        onOpen={openModalUpdateDocument}
        setOpen={setOpenModalUpdateDocument}
        buttonCancel={true}
        title="Reemplazar Archivo de Bonos Rendido"
        subtitle={
          "Usted ya posee un archivo de bonos adjuntos y documentos de cobro ingresado. Esta acción eliminará todo documento de cobro que no haya sido registrado en el archivo de bonos rendidos. ¿Esta seguro de proceder?"
        }
        onClick={handleAcceptedUpdate}
        secondaryButton={<ButtonAcceptedModal title={"Si, reemplazar"} />}
      />

      <ModalLoader open={openLoad} title={"Cargando documento"} />
      <Toast
        openAlert={alert}
        setOpenAlert={setAlert}
        contentAlert={contentAlert}
      />
      <Typography sx={grayTextFile} pb="14px">
        <span>Formato aceptados: XLSX, XLS</span>
        {/* <img src={iconExternalLink} alt="" onClick={handleImage} style={{ width: "22px", position: "relative", bottom: "-3px", cursor: "pointer" }} /> */}
        <br />
        <span>Peso máximo de 4MB</span>
        <br />
        {multiplesFacturas ? <span></span> : <span>Max. Doc. Cobro a ingresar : 1</span>}
      </Typography>

      <Button
        color="secondary"
        onClick={handleOpenExample}
        role="button-open-format"
        sx={{ marginRight: "10px" }}
        aria-describedby={idPopover}
      >
        <img src={DocIcon} alt="formato" width="14px" />
      </Button>
      <Popover
        id={idPopover}
        open={openModalFormat}
        anchorEl={exampleTarget}
        onClose={handleCloseExample}
        anchorOrigin={{
          vertical: 'top',
          horizontal: -230,
        }}
      >
        <Grid container p={"10px"} width={"220px"}>
          <Grid item xs={12} textAlign={"end"}>
            <IconButton
              aria-label="close"
              onClick={handleCloseExample}
            >
              <img src={Close} width="18px" alt="close img" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <img src={ExampleFormat} alt="formato" width="200px" />
          </Grid>
        </Grid>
      </Popover>

      <Button
        color={holding ? "secondary" : "primary"}
        disabled={holding ? false : true}
        startIcon={<img src={holding ? UploadGreen : UploadGray} alt="" />}
        onClick={handleButtonClick}
        role="button-open-document"
      >
        Cargar archivo{" "}
      </Button>
      <input
        title="fileInput"
        type="file"
        accept={".xls, .xlsx"}
        onChange={handleFileInputChange}
        ref={fileInputRef}
        style={{ display: "none" }}
        data-testid="file-input"
      />
      {errorState && (
        <>
          {fileErrorBase64 !== "" &&
            <Button
              color="inherit"
              startIcon={<img src={DownloadRed} alt="" />}
              sx={{ color: "#B71C1C !important" }}
              onClick={handleDownloadFile}
              role="button-descarga-error"
            >
              Descargar errores{" "}
            </Button>
          }
          <br />

          {dataContext.errorCount > 0 && (<>
            <Typography variant="body1" style={{ color: "#B71C1C" }}>
              Total de bonos con error: {dataContext.errorCount}
            </Typography>
          </>
          )}

          {dataContext.amountToPay === 0 && dataContext.file && dataContext.fileErrorBase64 === '' && (
            <Typography variant="body1" style={{ color: "#B71C1C" }}>
              ERROR : Usted esta tratando de rendir Bonos que no presentan monto a pagar.
            </Typography>
          )}

        </>

      )}
      {(dataContext.errorCount == null || dataContext.errorCount > 0) && errorStatus === 501 && (<>
        <Typography variant="body1" style={{ color: "#B71C1C" }}>
          ERROR : Archivo con formato o datos incorrecto. No se pudo cargar.
        </Typography>
      </>
      )}

    </Grid>
  );
};
