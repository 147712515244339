import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button, CircularProgress } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useState } from "react";
import { LogoutSession } from "../../services/logoutSession";
import { clientCCP } from "../../config/apolloClientsCCP";
import { clientSecondary } from "../../config/apolloClients";
import axios from "axios";
import { GetAccessToken } from "../../utils/azureADTokens";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";

export const SignOutButton = ({ info }: any) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [sessionBool, setSessionBool] = useState<boolean>(true);

  const [prestador, setPrestador] = useState<string>("");
  async function handleLogout(instance: any) {
    
    try {
      setSessionBool(false)
      const result = await clientCCP.mutate({
        mutation: LogoutSession
      });
      
      const {
        estado
      }: {
        estado: number;
      } = result.data.logoutSession;
      
      if(estado == 200) {
        if(await logoutCTA() == 200) {
          sessionStorage.removeItem('info-session');
          sessionStorage.removeItem('info-prestadores');
          instance.logoutRedirect().catch((e: any) => {
            console.error(e);
          });
        }else {
          setSessionBool(true)
        }
        
      }else {
        setSessionBool(true)
      }
    } catch (error) {
      setSessionBool(true)
    }    
  }  

    const logoutCTA = async () => {
      const result = await clientSecondary.mutate({
        mutation: LogoutSession
      });
  
      const {
        estado
      }: {
        estado: number;
      } = result.data.logoutSession;
  
      return estado;
  
  } 

  const getUserData = () => {
    if (info) {
      const infoData = info ? JSON.parse(info) : {};
      let pres = infoData.prestadores[0]?.razonSocialPrestadorHolding;
      setPrestador(pres);
    }
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, info]);

  return (
    <div className="profile-sign-out">
      {prestador && (
        <>
          <p>
            <strong>Sesión iniciada para</strong>
          </p>
          <p>{prestador}</p>
        </>
      )}
      <Button
        variant="outlined"
        className="doc-button"
        onClick={() => handleLogout(instance)}
        startIcon={sessionBool ? <LogoutIcon color="primary"></LogoutIcon> : <CircularProgress size="24px" sx={{ color: '#01A49A' }} />}
        disabled={!sessionBool}
      >
        { sessionBool ? "Cerrar Sesión" : "Cerrando sesión" }
      </Button>
    </div>
  );
};
