import {
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import {
  CustomModal,
  CustomModalTitle,
} from "../../../components/UI/Modal/CustomModal";
import {
  boldTitle,
  cardSubT,
} from "../../../components/ui-components/Typography/typographyStyles";
import CustomInput from "../../../components/ui-components/Input/customInput";
import { CustomSelect } from "../../../components/ui-components/customSelect";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import { primitiveFilterArgs } from "../types";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import es from "date-fns/locale/es";
import { CustomTextField } from "../../../theme/ui/textField";
import { formatRut, validRut } from "chilean-rutify";

type ModalFiltroTracingProps = {
  isOpen: boolean;
  onClose: () => void;
  getRecords: Function;
  filterData: primitiveFilterArgs;
  quickStartDate: string | null;
  quickStatus: number | null;
};

export const ModalFiltroTracing = ({
  isOpen,
  onClose,
  getRecords,
  filterData,
  quickStartDate,
  quickStatus,
}: ModalFiltroTracingProps) => {
  const [localFilters, setLocalFilters] = useState<primitiveFilterArgs>(filterData);
  const [localErrors, setLocalErrors] = useState<any>({});
  // TODO: TIPAR!
  const [providersList, setProvidersList] = useState<any>([]);

  if (!isOpen && localFilters !== filterData) {
    setLocalFilters(filterData);
  }

  useEffect(() => {
    const sessionData = sessionStorage.getItem("info-prestadores");
    const prestadores = sessionData ? JSON.parse(sessionData).prestadores : [];
    setProvidersList(
      prestadores.map((prestador: { [key: string]: string }) => ({
        id: prestador.rutPrestador,
        nombre: prestador.nombrePrestador,
      }))
    );
  }, []);

  const onChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;

    if (!value) {
      const objectKey: keyof primitiveFilterArgs = id;
      let newLocalFilters = { ...localFilters };
      delete newLocalFilters[objectKey];
      setLocalFilters ({ ...newLocalFilters });

      if (id === 'rutPaciente') {
        let newErrors = localErrors;
        delete newErrors[id];
        setLocalErrors ({ ...newErrors });
      }
    } else if (id === 'rutPaciente') {
      const rut = value.replace (/[^0-9kK-]/g, "").trim ();
      const formattedRut = rut.length > 2 ? formatRut (rut)!.toUpperCase () : rut;

      if (formattedRut && !validRut(formattedRut) && formattedRut != '') {
        setLocalErrors ({ ...localErrors, [id]: "Rut inválido." });
      } else {
        let newErrors = localErrors;
        delete newErrors[id];
        setLocalErrors ({ ...newErrors });
      }

      setLocalFilters ({ ...localFilters, [id]: formattedRut });
    } else if (id === "id" || id === "idCMD" || id === "numeroEnvio" || id === "montoTotal") {
      const cleanValue = value.replace (/[^0-9]/g, "");
      const numberValue = Number (cleanValue);

      if (!(!numberValue || isNaN (numberValue))) setLocalFilters ({ ...localFilters, [id]: numberValue });
    } else {
      setLocalFilters ({ ...localFilters, [id]: value });
    }
  };

  const onClick = () => {
    let parseFilters = { ...localFilters };
    if (localFilters.fechaEntrega) {
      parseFilters.fechaEntrega = localFilters.fechaEntrega ? format(new Date(localFilters.fechaEntrega), "yyyy-MM-dd") : undefined;
    }
    if (localFilters.fechaEmision) {
      parseFilters.fechaEmision = localFilters.fechaEmision ? format(new Date(localFilters.fechaEmision), "yyyy-MM-dd") : undefined;
    }
    if (localFilters.rutPaciente) parseFilters.rutPaciente = localFilters.rutPaciente.replace(/[.]/g, "");
    if (quickStartDate) {
      parseFilters.fechaRecepcion = format(new Date(quickStartDate!), "yyyy-MM-dd") || undefined
    }
    if (quickStatus) {
      parseFilters.estado = quickStatus;
    }
    getRecords(parseFilters);
    onClose();
  };

  const cleanFilters = () => {
    setLocalFilters(filterData);
    onClose();
  };

  const cleanErrors = () => {
    setLocalErrors({});
    onClose();
  };

  const modalFilterKeys = [
    'id',
    'numeroCuenta',
    'rutPaciente',
    'numeroEnvio',
    'idCMD',
    'rutPrestador',
    'numeroCobro',
    'montoTotal',
    'canalOrigen',
    'fechaEmision'
  ];
  const modalHasErrors = () => Object.keys (localErrors).length !== 0;
  const modalHasFilters = () => Object.keys (localFilters).some ((key) => (modalFilterKeys.includes (key)));
  const modalHasSameFilters = () => Object.keys (localFilters).every ((key) => (filterData.hasOwnProperty(key)));
  const modalHasSameValues = () => Object.keys (filterData).every ((key) => (localFilters[key as keyof primitiveFilterArgs] === filterData[key as keyof primitiveFilterArgs]));

  return (
    <CustomModal
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={cleanFilters}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <CustomModalTitle id="modalEmitir" onClose = { cleanErrors }>
        <div style={{ textAlign: "left" }}>
          <Typography sx={boldTitle}>Selección de filtros </Typography>
          <Typography sx={cardSubT}>
            Seleccione los criterios de búsqueda para encontrar resultados más
            específicos.
          </Typography>
        </div>
      </CustomModalTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={6} pr="26px" pb="24px">
            <CustomInput
              value={localFilters.id || ""}
              onChange={onChange}
              label="Nº Cuenta"
              fullWidth
              id="id"
              inputProps={{ "data-testid": "filter-account" }}
              InputLabelProps={{ style: { color: "#909AA1" } }}
            />
          </Grid>
          <Grid item xs={6} pb="26px">
            <CustomInput
              value={localFilters.numeroCuenta || ""}
              onChange={onChange}
              label="Nº C. Prestador"
              fullWidth
              id="numeroCuenta"
              inputProps={{ "data-testid": "filter-providerAccount" }}
              InputLabelProps={{ style: { color: "#909AA1" } }}
            />
          </Grid>
          <Grid item xs={6} pb={localErrors.rutPaciente ? "2px" : "26px"} pr="24px">
            <FormControl error={localErrors.rutPaciente || false} fullWidth>
              <CustomInput
                label="RUT Beneficiario"
                fullWidth
                error={localErrors.rutPaciente}
                inputProps={{ "data-testid": "filter-patientRut", style: { textTransform: "uppercase" } }}
                id="rutPaciente"
                data-testid="rutPaciente"
                value={localFilters.rutPaciente || ""}
                onChange={onChange}
                InputLabelProps={{ style: { color: "#909AA1" } }}
              />
              <FormHelperText sx={{mt: '0px'}}>{localErrors.rutPaciente || ""}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6} pb="26px">
            <CustomInput
              value={localFilters.numeroEnvio || ""}
              onChange={onChange}
              label="Nº Envío"
              fullWidth
              id="numeroEnvio"
              inputProps={{ "data-testid": "filter-trackingNumber" }}
              InputLabelProps={{ style: { color: "#909AA1" } }}
            />
          </Grid>
          <Grid item xs={6} pb="26px" pr="24px">
            <CustomInput
              value={localFilters.idCMD || ""}
              onChange={onChange}
              label="Nº Cuenta Extranet"
              fullWidth
              id="idCMD"
              inputProps={{ "data-testid": "filter-accountExt" }}
              InputLabelProps={{ style: { color: "#909AA1" } }}
            />
          </Grid>
          <Grid item xs={6} pb="26px">
            <CustomSelect
              value={localFilters.rutPrestador}
              onChange={(e: any) => setLocalFilters({ ...localFilters, rutPrestador: e.target.value || undefined })}
              data={providersList}
              titulo={"Prestador"}
              name="rutPrestador"
            />
          </Grid>
          <Grid item xs={6} pb="26px" pr="24px">
            <CustomInput
              value={localFilters.numeroCobro || ""}
              onChange={onChange}
              label="Nº Cobro"
              fullWidth
              id="numeroCobro"
              inputProps={{ "data-testid": "filter-invoiceNumber" }}
              InputLabelProps={{ style: { color: "#909AA1" } }}
            />
          </Grid>
          <Grid item xs={6} pb="26px">
            <CustomInput
              value={localFilters.montoTotal || ""}
              onChange={onChange}
              label="Total facturado"
              fullWidth
              id="montoTotal"
              inputProps={{ "data-testid": "filter-totalAmount" }}
              InputLabelProps={{ style: { color: "#909AA1" } }}
            />
          </Grid>
          <Grid item xs={6} pb="26px" pr="24px">
            <CustomSelect
              value={localFilters.canalOrigen}
              onChange={(e: any) => setLocalFilters({ ...localFilters, canalOrigen: e.target.value || undefined })}
              data={[
                {
                  id: "CMD",
                  nombre: "CMD",
                },
                {
                  id: "EMAIL",
                  nombre: "Email",
                },
              ]}
              titulo={"Canal"}
              name="canalOrigen"
            />
          </Grid>
          <Grid item xs={6} pb="26px">
            <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
              <DatePicker
                value={localFilters.fechaEmision || null}
                onChange={(date) => setLocalFilters({ ...localFilters, fechaEmision: date || undefined })}
                PaperProps={{
                  className: "paper-calendar",
                }}
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    className="full-width"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Fecha de liquidación",
                      sx: {
                        "&::placeholder": {
                          color: "#909AA1",
                          fontWeight: 400,
                          opacity: 1, // otherwise firefox shows a lighter color
                        },
                      },
                      readOnly: true,
                    }}
                    value={"20/20/20"}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between">
          <Button
            variant="outlined"
            sx={{ p: "15px 45px !important" }}
            onClick = { cleanErrors }
          >
            Cancelar
          </Button>
          <Button
            sx={{ marginLeft: "24px", p: "15px 45px !important" }}
            onClick = { onClick }
            disabled = { modalHasErrors () || !modalHasFilters () || (modalHasSameFilters () && modalHasSameValues ()) }
          >
            Filtrar
          </Button>
        </Grid>
      </DialogActions>
    </CustomModal>
  );
};
