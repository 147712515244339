import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    Box,
    Stack
} from "@mui/material";
import { loader, subLoader } from "../../ui-components/Typography/typographyStyles";
import { CustomModal } from "./CustomModal";

export const PointLoad = ({ onOpen, onClose, text, subText }: any) => {
    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            onClose={onClose}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                }, 

            }}
        >
            <DialogContent sx={{ padding: "48px 24px !important" }}>
                <Stack direction={"column"} alignItems={"center"} display={"flex"} pt="28px" pb="28px" >
                    <div className="blob"></div>
                    {text ? (
                        <Typography sx={loader} data-testid="text-element">{text}</Typography>
                    ) : (
                        <Typography sx={loader} data-testid="text-element"> </Typography>
                    )}
                    {subText ? (
                        <Typography sx={subLoader} data-testid="subtext-element">{subText}</Typography>
                    ) : (
                        <Typography sx={subLoader} data-testid="subtext-element">
                            {" "}
                        </Typography>
                    )}
                </Stack>
            </DialogContent>


        </CustomModal>
    );
};
