import { MouseEvent, useState } from "react";
import { AlertColor, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { statusEnumGrouped } from "../shared/status";
import useFileHandler from "../hooks/useFileHandler";
import ModalLoader from "../../../components/ui-components/ModalLoader/ModalLoader";
import { ModalFiles } from "./files/modalFiles";
import { DeleteRequest } from "./deleteRequest";

// TODO: TIPAR!
type MenuTableTracingProps = {
  row: any;
  sendingQueue: string[];
  setCurrentMenuRow: Function;
  setShowHistoryModal: Function;
  handleSend: () => void;
  showMessage: (message: string, severity: AlertColor) => void;
  resetSelectedRecords: () => void;
  refetch: Function;
};

export const MenuTableTracing = ({
  row,
  sendingQueue,
  setCurrentMenuRow,
  setShowHistoryModal,
  handleSend,
  showMessage,
  resetSelectedRecords,
  refetch,
}: MenuTableTracingProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const {
    handleObtenerDocumento,
    loadingObtenerDocumento,
  } = useFileHandler({ showMessage });
  const open = Boolean(anchorEl);

  const showHistory = () => {
    resetSelectedRecords();
    setCurrentMenuRow(row);
    setShowHistoryModal(true);
    setAnchorEl(null);
  };

  const showDocuments = () => {
    resetSelectedRecords();
    setCurrentMenuRow(row);
    setShowFilesModal(true);
    setAnchorEl(null);
  };

  const handleCloseModalFiles = (shouldRefetch: boolean) => {
    setShowFilesModal(!showFilesModal);
    if (shouldRefetch) refetch();
  };

  const downloadFile = (fileType: string) => {
    handleObtenerDocumento(row.id, fileType);
    setAnchorEl(null);
  };

  const onSend = () => {
    setAnchorEl(null);
    handleSend();
  }

  const openDeleteModal = () => {
    setDeleteModal(true);
  }

  const closeDeleteModal = () => setDeleteModal(false);

  const MenuOnDemand = ({ status }: { status: number }) => {
    if ([statusEnumGrouped.ENTREGADA].includes(status)) {
      return (
        <>
          <MenuItem className="menuForm" onClick={showDocuments}>
            Carga de doc. ({row.numeroDocumentos})
          </MenuItem>
          <MenuItem
            className="menuForm"
            disabled={
              row.numeroDocumentos < 1 || sendingQueue.includes(`${row.idCMD}-${row.canalOrigen}`) || row.estadoMigracion === 1 || row.estadoMigracion === 2
            }
            onClick={onSend}
          >
            Enviar
          </MenuItem>
          <MenuItem className="menuForm" onClick={openDeleteModal}>
            Eliminar
          </MenuItem>
          <MenuItem className="menuForm" onClick={showHistory}>
            Ver historial
          </MenuItem>
        </>
      );
    }
    if ([statusEnumGrouped.LIQUIDADA, statusEnumGrouped.LIQUIDADA_MANUAL].includes(status)) {
      return (
        <>
          <MenuItem
            className="menuForm"
            onClick={() => downloadFile("FORMULARIO LIQUIDACION")}
            disabled={(status === statusEnumGrouped.LIQUIDADA_MANUAL)}
          >
            Descargar formulario
          </MenuItem>
          <MenuItem
            className="menuForm"
            onClick={() => downloadFile("BONOS EMITIDOS")}
            disabled={(status === statusEnumGrouped.LIQUIDADA_MANUAL)}
          >
            Descargar bono
          </MenuItem>
          <MenuItem className="menuForm" onClick={showDocuments}>
            Ver documentos
          </MenuItem>
          <MenuItem className="menuForm" onClick={showHistory}>
            Ver historial
          </MenuItem>
        </>
      );
    }
    if (
      [
        statusEnumGrouped.ENPROCESO,
        statusEnumGrouped.RECHAZADA,
        statusEnumGrouped.DEVUELTA,
      ].includes(status)
    ) {
      return (
        <>
          <MenuItem className="menuForm" onClick={showDocuments}>
            Ver documentos
          </MenuItem>
          <MenuItem className="menuForm" onClick={showHistory}>
            Ver historial
          </MenuItem>
        </>
      );
    }
    return (
      <>
        <MenuItem className="menuForm" onClick={showHistory}>
          Ver historial
        </MenuItem>
      </>
    );
  };

  return (
    <>
      {loadingObtenerDocumento ? (
        <ModalLoader
          open={loadingObtenerDocumento}
          title={"Generando Documento"}
        />
      ) : null}
      <ModalFiles
        open={showFilesModal}
        onClose={handleCloseModalFiles}
        row={row}
      />
      <DeleteRequest
        open={deleteModal}
        onClose={closeDeleteModal}
        showMessage={showMessage}
        refetch={refetch}
        row={row}
      />
      <IconButton
        onClick={(event: MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(event.currentTarget)
        }
        data-testid="btnMenu"
      >
        <MoreVertIcon sx={{ color: "#203442" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        className="menuForm"
      >
        <MenuOnDemand status={row.estado} />
      </Menu>
    </>
  );
};
