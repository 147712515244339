import { gql } from "@apollo/client";

export const getPrestadoresHolding = gql`
  query obtenerPrestadoresHolding($rutPrestadorHolding: String!) {
    obtenerPrestadoresHolding(rutPrestadorHolding: $rutPrestadorHolding) {
      estado
      mensaje
      data {
        id
        nombrePrestador
        rutPrestador 
        rutPrestadorHolding
        razonSocialPrestadorHolding
        slaDiasResolucion
        slaDiasResolucionRendicion
        diaDelMesRendicion
        estado
        causalBloqueo
        fechaEstado
        multiplesFacturas
        diasCtrlCaducidad
        montoMaxAjusteSencillo
        visibilidadFechaCompromiso
      }
    }
}`;

export const getByUserConfiguration = gql`query ($userId: String) {
  getUserConfiguration(userId: $userId) {
    id
    nombrePrestador
    rutPrestador
    rutPrestadorHolding
    razonSocialPrestadorHolding
  }
}
`;