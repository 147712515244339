import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import { CustomTextField } from "../../../../theme/ui/textField";
import { CustomSelect } from "../../../../components/ui-components/customSelect";
import { inputText } from "../../../../components/ui-components/Typography/typographyStyles";
import DateAdapter from "@mui/lab/AdapterDateFns";
import es from "date-fns/locale/es";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { primitiveFilterArgs } from "../../types";
import useFechaProbableLiquid from "./hooks/useFechaProbableLiquid";

const medicalRecordStatusList = [
  {
    id: 1,
    nombre: "Entregada",
  },
  {
    id: 2,
    nombre: "En Proceso",
  },
  {
    id: 3,
    nombre: "Devuelta",
  },
  {
    id: 4,
    nombre: "Rechazada",
  },
  {
    id: 5,
    nombre: "Liquidada",
  },
  {
    id: 6,
    nombre: "Liquidada Manual",
  },
  {
    id: 7,
    nombre: "Eliminada",
  },
  {
    id: 8,
    nombre: "Anulada",
  },
];

type QuickSearchProps = {
  getRecords: Function;
  quickStatus: number | null;
  quickStartDate: string | null;
  setQuickStatus: Function;
  setQuickStartDate: Function;
  filterData: primitiveFilterArgs;
};

const QuickSearch = ({
  getRecords,
  quickStatus,
  quickStartDate,
  setQuickStatus,
  setQuickStartDate,
  filterData
}: QuickSearchProps) => {
  const [localFilters, setLocalFilters] = useState<primitiveFilterArgs>(filterData);
  const { dateProbLiq, getDateProbLiq, setdateProbLiq } = useFechaProbableLiquid();

  const calculateLiquidationDate = (startDate: string | null) => {
    if (startDate) {
      const endDate = new Date(startDate);
      getDateProbLiq(endDate);
    } else {
      setdateProbLiq(null);
    }
  }

  const handleQuickStartDateChange = (startDate: string | null) => {
    if (startDate) {
      setLocalFilters({
        ...localFilters,
        fechaRecepcion: format(new Date(startDate!), "yyyy-MM-dd") || undefined,
      });
    }
    setQuickStartDate(startDate);
  };

  const handleQuickStatus = (status: number) => {
    setLocalFilters({ ...localFilters, estado: status });
    setQuickStatus(status);
  };

  const onClick = () => {
    getRecords(localFilters);
  };

  useEffect(() => {
    calculateLiquidationDate(quickStartDate);
  }, [quickStartDate]);

  return (
    <Grid container>
      <Grid item xs={3} pr="16px">
        <Box className="widthMax">
          <Typography sx={inputText} pb="6px" whiteSpace={"pre"}>
            Fecha de Entrega
          </Typography>
        </Box>
        <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
          <DatePicker
            value={quickStartDate}
            onChange={(startDate) => handleQuickStartDateChange(startDate)}
            PaperProps={{
              className: "paper-calendar",
            }}
            renderInput={(params: any) => (
              <CustomTextField
                {...params}
                className="full-width"
                inputProps={{
                  ...params.inputProps,
                  placeholder: "DD/MM/AAAA",
                  readOnly: true,
                }}
                value={"20/20/20"}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3} pr="16px" sx={{ minWidth: "170px" }}>
        <Box className="widthMax">
          <Typography sx={inputText} pb="6px" whiteSpace={"pre"}>
            Fecha de liquidación
          </Typography>
        </Box>
        <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
          <DatePicker
            value={dateProbLiq}
            onChange={() => { }}
            disabled={true}
            PaperProps={{
              className: "paper-calendar",
            }}
            renderInput={(params: any) => (
              <CustomTextField
                {...params}
                className="full-width"
                inputProps={{
                  ...params.inputProps,
                  placeholder: "DD/MM/AAAA",
                  readOnly: true,
                }}
                value={"20/20/20"}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3} pr="16px">
        <Typography sx={inputText} pb="6px">
          Estado de cuenta
        </Typography>
        <CustomSelect
          value={quickStatus}
          data={medicalRecordStatusList}
          titulo={"Seleccione"}
          name="status"
          onChange={(event: any) => handleQuickStatus(parseInt(event.target.value))}
        />
      </Grid>
      <Grid item xs={2} pt="26px" sx={{ maxWidth: "90px" }}>
        <Button
          disabled={quickStartDate === null && quickStatus === null}
          onClick={onClick}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
};

export default QuickSearch;
