import {
  DownloadGreen,
  DownloadGray,
  DownloadWhite
} from "../../../assets/icons/iconosEscencial";
import { StyledloadingButton } from './StyledButton';

type BotonCargaArgs = {
  disabled?: boolean,
  label?: string,
  loading?: boolean,
  download?: any,
  classname?: string,
  noClick?: boolean,
  name?: string,
  variant?: "text" | "outlined" | "contained" | undefined,
  type?: string,
  setLoading?: Function,
  sx?: Object,
  testid?: string,
  toDo: Function,
  children?: any,
  ripple?: any
}
export const LoadingButton = ({
  disabled,
  label,
  loading,
  download,
  classname,
  noClick,
  name,
  variant,
  type,
  setLoading,
  sx,
  testid,
  toDo,
  children,
  ripple
}: BotonCargaArgs) => {

  function handleClick() {
    if (setLoading) {
      setLoading(true);
    }
    toDo();
  }

  const ButtonLoading = StyledloadingButton(type);

  return (
    <ButtonLoading
      data-testid={testid}
      onClick={noClick ? undefined : handleClick}
      loading={loading}
      name={name}
      variant={variant}
      className={classname}
      sx={sx}
      disabled={disabled}
      disableRipple={ripple}
    >
      {loading ? "" : <>{download ?
        disabled ?
          <DownloadGray sx={{ pt: "2px" }} />
          : type ? <DownloadGreen sx={{ pt: "2px" }} />
            : <DownloadWhite sx={{ pt: "2px" }} />
        : ""}{label}{children}</>}
    </ButtonLoading>
  );
};

export default LoadingButton;

