import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import WhiteArrow from "../../../assets/icons/downArrow.svg";
import { statusEnumGrouped } from "../shared/status";
import { CustomTooltip } from "../../../components/ui-components/Tooltip/LongTextTooltip";
export const title = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  color: "#364855",
};

export const regis = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
  color: "#4D5D68",
  display: "inline",
};

export const BoxStyle = {
  backgroundColor: "#857BE2",
  borderRadius: "8px",
  width: "600px",
  zIndex: 9999,
  margin: " 0 auto",
};

export const BoxStyleDisabled = {
  ...BoxStyle,
  backgroundColor: "#E9EBEC",
  boxShadow: "0px 2px 2px 0px #01A49A40",
};

const LetterStyle = {
  fontFamily: "Interstate !important",
  fontSize: "16px !important",
  fontWeight: "700 !important",
  lineHeight: "20px !important",
  letterSpacing: "0.0021em  !important",
  textAlign: "center !important",
  color: "#FFFFFF !important",
};

const LetterStyleDisabled = {
  ...LetterStyle,
  color: "#79858E !important",
};

type ButtonEditProps = {
  checkCount: number;
  selectedRecordsType: { [key: number]: number };
  handleBulkSend: () => void;
  handleBulkDownload: (type: string) => void;
  isLoading: boolean;
};

const ButtonEdit = ({
  checkCount,
  handleBulkSend,
  handleBulkDownload,
  selectedRecordsType,
  isLoading,
}: ButtonEditProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isAnyAboveLimit = (...types: number[]) => types.some(type => selectedRecordsType[type] > 0);

  /** valor 99 corresponde a ENTREGADA sin documentos adjuntos ¿refactor?
   * Estados migración 1 (en cola), 2 (procesando), 4 (exitoso) también bloquea. Correspondientes a 101, 102, 104 
   */

  const isDisabled =
    isAnyAboveLimit(
      statusEnumGrouped.DEVUELTA,
      statusEnumGrouped.RECHAZADA,
      statusEnumGrouped.ENPROCESO,
      99,
      101,
      102,
      104
    ) ||
    Number(selectedRecordsType[statusEnumGrouped.LIQUIDADA]) > 25 ||
    selectedRecordsType[statusEnumGrouped.ENTREGADA] > 25 ||
    (isAnyAboveLimit(statusEnumGrouped.LIQUIDADA) &&
      isAnyAboveLimit(statusEnumGrouped.ENTREGADA, 99, 101, 102, 104));

  const action =
    selectedRecordsType[statusEnumGrouped.ENTREGADA] > 0 ? "send" : "download";

  const NotaTooltip = () => {
    return (
      <>
        <Typography display={"inline"}>
        Sólo puedes realizar acciones masivas<br></br> con cuentas de un
        </Typography>{" "}
        <Typography display={"inline"} sx={{ fontWeight: 700 }}>
          mismo estado
        </Typography>
      </>
    );
  };

  return (
    <Box
      sx={{
        position: "fixed",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        top: "75%",
        left: "42%",
        zIndex: 9998,
      }}
    >
      <Box sx={isDisabled ? BoxStyleDisabled : BoxStyle}>
        <Grid container>
          <Grid
            item
            xs={5}
            textAlign={"right"}
            sx={{ placeSelf: "center", float: "right" }}
          >
            <Typography
              sx={isDisabled ? LetterStyleDisabled : LetterStyle}
              paddingLeft="40%"
            >
              Seleccionado: {checkCount}
            </Typography>
          </Grid>
          <Grid item xs={1} pt="10px" pb="10px">
            <Divider
              sx={{ borderColor: "#FFFFFF !important" }}
              orientation="vertical"
            />
          </Grid>
          {isDisabled ? (
            <CustomTooltip title={<NotaTooltip />} arrow placement="top">
              <Grid xs={5} item>
                <Button color="inherit" disabled>
                  <Typography sx={LetterStyleDisabled}>
                    Acciones deshabilitadas
                  </Typography>
                </Button>
              </Grid>
            </CustomTooltip>
          ) : (
            <Grid item xs={5}>
              {isLoading && (
                <Button color="inherit">
                  <CircularProgress size="24px" sx={{ color: "#FFFFFF" }} />
                </Button>
              )}
              {!isLoading && action === "send" && (
                <Button color="inherit" onClick={handleBulkSend}>
                  <Typography
                    sx={isDisabled ? LetterStyleDisabled : LetterStyle}
                    paddingLeft={"12px"}
                  >
                    {" "}
                    Enviar
                  </Typography>
                </Button>
              )}
              {!isLoading && action === "download" && (
                <>
                  <Button
                    color="inherit"
                    onClick={(event: any) => setAnchorEl(event.currentTarget)}
                  >
                    <img src={WhiteArrow} alt="Descargar" />{" "}
                    <Typography
                      sx={isDisabled ? LetterStyleDisabled : LetterStyle}
                      paddingLeft={"12px"}
                    >
                      {" "}
                      Descargar Formularios
                    </Typography>
                  </Button>
                  <Menu
                    sx={{ zIndex: 9999 }}
                    slotProps={{ paper: { sx: { width: 300 } } }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    className="menuFormAlt"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem
                      className="menuFormAlt"
                      onClick={() => {
                        setAnchorEl(null);
                        handleBulkDownload("FORMULARIO LIQUIDACION");
                      }}
                    >
                      Descargar formulario
                    </MenuItem>
                    <MenuItem
                      className="menuFormAlt"
                      onClick={() => {
                        setAnchorEl(null);
                        handleBulkDownload("BONOS EMITIDOS");
                      }}
                    >
                      Descargar bono
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ButtonEdit;
