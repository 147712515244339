import {
    Box,
    Divider,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import SkeletonBar from "../../../components/ui-components/Skeletons/skeletonBar";
import { StyledTableCell } from "../../../theme/ui/Table/table";
import {
    stickyBodyLeft,
    stickyBodyRight,
} from "../../../components/ui-components/Table/tableStyles";
import { grayBox } from "../../../theme/ui/Boxs";

export const SekeltonMedicalReport = () => (
    <>
        <Grid container p="37px 24px">
            <Grid item xs={6} pr="16px" pb="14px">
                <Box pb="10px">
                    <SkeletonBar width={239} height={14} />
                </Box>
                <Box pb="20px">
                    <SkeletonBar width={"auto"} height={14} />
                </Box>
                <Box pb="6px">
                    <SkeletonBar width={203} height={14} />
                </Box>
                <SkeletonBar width={"auto"} height={50} />
            </Grid>
            <Grid item xs={6} pb="24px">
                <Box sx={grayBox}>
                    <Grid container>
                        <Grid item xs={12} pb="15px">
                            <SkeletonBar width={"auto"} height={14} />
                        </Grid>
                        <Grid item xs={4} pr="16px">
                            <Box pb="6px">
                                <SkeletonBar width={110} height={14} />
                            </Box>
                            <SkeletonBar width={"auto"} height={50} />
                        </Grid>
                        <Grid item xs={4} pr="16px">
                            <Box pb="6px">
                                <SkeletonBar width={110} height={14} />
                            </Box>
                            <SkeletonBar width={"auto"} height={50} />
                        </Grid>
                        <Grid item xs={4}  >
                            <Box pb="6px">
                                <SkeletonBar width={110} height={14} />
                            </Box>
                            <SkeletonBar width={"auto"} height={50} />
                        </Grid>
                    </Grid>

                </Box>
            </Grid>
            <Grid item xs={2.4} pr="16px">
                <Box pb="6px">
                    <SkeletonBar width={127} height={14} />
                </Box>
                <SkeletonBar width={"auto"} height={50} />
            </Grid>
            <Grid item xs={2.4} pr="16px">
                <Box pb="6px">
                    <SkeletonBar width={127} height={14} />
                </Box>
                <SkeletonBar width={"auto"} height={50} />
            </Grid>
            <Grid item xs={2.4} pr="16px">
                <Box pb="6px">
                    <SkeletonBar width={127} height={14} />
                </Box>
                <SkeletonBar width={"auto"} height={50} />
            </Grid>
            <Grid item xs={2.4} pt="30px">
                <SkeletonBar width={"auto"} height={50} />
            </Grid>
        </Grid>
        <Divider />
        <Grid container pl="29px" pb="28px">
            <Grid item xs={12} pt="15px">
                <Box pb="6px">
                    <SkeletonBar width={199} height={14} />
                </Box>
                <SkeletonBar width={571} height={14} />
            </Grid>
            <Grid item xs={6} pt="15px" pr="16px">
                <SkeletonBar width={"auto"} height={50} />
            </Grid>
            <Grid item xs={3} pt="15px">
                <SkeletonBar width={"auto"} height={50} />
            </Grid>
            <Grid item xs={3} pt="15px" textAlign={"end"} pr="24px" pl="42px">
                <SkeletonBar width={"auto"} height={50} />
            </Grid>
        </Grid>

        <TableContainer
            component={Paper}
            sx={{
                boxShadow: "none",
                borderRadius: "8px 8px 8px 8px",
                border: " 0.5px solid #D2D6D9 ",
            }}
        >
            <Table sx={{ minWidth: "auto" }} aria-label="customized table">
                <TableHead className="color-head-table">
                    <TableRow>
                        <StyledTableCell
                            align={'center'}
                            className={"escritorioTrabajo headerSort"}
                            sx={{ minWidth: "180px" }}>
                            <SkeletonBar width={80} height={14} />
                        </StyledTableCell>
                        <StyledTableCell
                            align={'center'}
                            className={"escritorioTrabajo headerSort"}
                            sx={{ minWidth: "180px" }}>
                            <SkeletonBar width={80} height={14} />
                        </StyledTableCell>
                        <StyledTableCell
                            align={'center'}
                            className={"escritorioTrabajo headerSort"}
                            sx={{ minWidth: "180px" }}>
                            <SkeletonBar width={80} height={14} />
                        </StyledTableCell>
                        <StyledTableCell
                            align={'center'}
                            className={"escritorioTrabajo headerSort"}
                            sx={{ minWidth: "180px" }}>
                            <SkeletonBar width={80} height={14} />
                        </StyledTableCell>
                        <StyledTableCell
                            align={'center'}
                            className={"escritorioTrabajo headerSort"}
                            sx={{ minWidth: "180px" }}>
                            <SkeletonBar width={80} height={14} />
                        </StyledTableCell>
                        <StyledTableCell
                            align={'center'}
                            className={"escritorioTrabajo headerSort"}
                            sx={{ minWidth: "180px" }}>
                            <SkeletonBar width={80} height={14} />
                        </StyledTableCell>
                        <StyledTableCell
                            align={'center'}
                            className={"headerFirstSticky"}
                            width={"119px"}>
                            <SkeletonBar width={80} height={14} />
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow data-testid="withData">
                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell>
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                        <TableCell className="tableBodyAcciones">
                            <SkeletonBar width={80} height={14} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Stack
                direction={"row"}
                spacing={"24px"}
                justifyContent={"center"}
                p="24px"
                alignItems={"center"}
            >
                <SkeletonBar width={108} height={14} />
                <SkeletonBar width={68} height={32} />
                <SkeletonBar width={88} height={32} />
            </Stack>
        </TableContainer>
    </>
);


