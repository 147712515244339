import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  inputText,
  textBox,
  titleBox,
} from "../ui-components/Typography/typographyStyles";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import {
  defaultContent,
  defaultPrestador,
} from "../../utils/constDefault";
import { WarningModal } from "../UI/Modal/modalWarning";
import { clientCCP } from "../../config/apolloClientsCCP";
import Toast from "../ui-components/Toast/Toast";
import { PostCleanMedicalRendition } from "../../services/cleanMedicalRendition";
import ModalLoader from "../ui-components/ModalLoader/ModalLoader";
import { ButtonAcceptedModal } from "../UI/Modal/ButtonAcceptedModal";
import { SelectPrestador } from "../medicalRendition/selectPrestador";

export const SelectProvider = () => {
  const { dataContext, setDataContext } =
    useContext<ContextValue>(ContextRendition);
  const { holding, idRendition, file } = dataContext;

  const [openLoad, setOpenLoader] = useState(false);
  const [contentAlert, setContentAlert] = useState<any>(defaultContent);
  const [alert, setAlert] = useState<boolean>(false);

  const [newHolding, setNewHolding] = useState<string>("");

  const [openModalProvider, setOpenModalProvider] = useState<boolean>(false);

  const [prestadores, setPrestadores] = useState<any>(defaultPrestador);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setNewHolding(event.target.value);
    if (idRendition > 0 || file) {
      setOpenModalProvider(true);
    } else {
      selectProviderChange(event.target.value);
    }
  };

  const submitCleanRendition = async () => {
    try {
      if (idRendition > 0) {
        setOpenLoader(true);
        const result = await clientCCP.mutate({
          mutation: PostCleanMedicalRendition,
          variables: {
            medicalRenditionId: idRendition,
          },
        });

        setOpenLoader(false);
        const { estado, mensaje } = result?.data?.cleanMedicalRendition;
        if (estado === 200) {
          const prestador = prestadores.find((x: any) => x.id == newHolding);
          setDataContext({
            idRendition: idRendition,
            invoices: [],
            totalMedicalBonds: 0,
            taxToPay: 0,
            netToPay: 0,
            amountToPay: 0,
            file: undefined,
            fileErrorBase64: "",
            holding: newHolding,
            insertInvoices: [],
            errorCount: 0,
            multiplesFacturas: prestador.multiplesFacturas,
            diasCtrlCaducidad: prestador.diasCtrlCaducidad,
          });
        } else {
          openToast(false, mensaje, "top", { marginTop: "300px" });
        }
      }else {
        const prestador = prestadores.find((x: any) => x.id == newHolding);
          setDataContext({
            idRendition: idRendition,
            invoices: [],
            totalMedicalBonds: 0,
            taxToPay: 0,
            netToPay: 0,
            amountToPay: 0,
            file: undefined,
            fileErrorBase64: "",
            holding: newHolding,
            insertInvoices: [],
            errorCount: 0,
            multiplesFacturas: prestador.multiplesFacturas,
            diasCtrlCaducidad: prestador.diasCtrlCaducidad,
          });
      }
    } catch (error) {
      console.log(error);
      openToast(
        false,
        "Error al limpiar la rendición, intente nuevamente",
        "top",
        { marginTop: "300px" }
      );
    }
  };

  const openToast = (
    isSuccess: boolean,
    content: string,
    typeAlert: string,
    styleAlert: object
  ) => {
    setContentAlert({ isSuccess, content, typeAlert, styleAlert });
    setAlert(true);
  };

  const selectProviderChange = (holdingMod: string) => {
    const prestador = prestadores.find((x: any) => x.id == holdingMod);
    setDataContext({
      ...dataContext,
      holding: holdingMod,
      multiplesFacturas: prestador.multiplesFacturas,
      diasCtrlCaducidad: prestador.diasCtrlCaducidad,
    });
  };

  return (
    <Grid item xs={6} pr="5%">
      <Toast
        openAlert={alert}
        setOpenAlert={setAlert}
        contentAlert={contentAlert}
      />
      <WarningModal
        onOpen={openModalProvider}
        setOpen={setOpenModalProvider}
        buttonCancel={true}
        title="Cambio de Facturador"
        subtitle={
          "Este cambio obliga a volver a cargar archivo de bonos a rendir. ¿Desea proseguir?"
        }
        onClick={submitCleanRendition}
        secondaryButton={
          <ButtonAcceptedModal            
            title={"Si, Cambiar"}
          />
        }
      />
      <ModalLoader
        open={openLoad}
        title={"Su solicitud está siendo procesada "}
      />
      <Typography sx={titleBox}>Rendición de bonos</Typography>
      <Typography sx={textBox}>
        Selecciona y carga información para visualizar el monto total por rendir
      </Typography>
      <Typography sx={inputText} pt="12px" pb="6px">
        Prestador solicitante
      </Typography>
      <SelectPrestador holding={holding} handleSelectChange={handleSelectChange} setPrestadores={setPrestadores} prestadores={prestadores} />
    </Grid>
  );
};
