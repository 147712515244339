import { StateAccount, Estado } from '../interfaces/detailsInterface';
import { gql } from "@apollo/client";

export const getDataStatus = gql`
 query {
    obtenerValoresParametros(tipoParametro: ESTADO_CUENTA_MEDICA){
        idParametroInterno
        valorParametro
      }
 }`;

export function getDataStatusMapper(response: any) {
    const stateAccount: StateAccount = {
        estados: response.map((estado: any) => {
            const estadoMapped: Estado = {
                id: estado.idParametroInterno,
                valorParametro: estado.valorParametro
            }
            return estadoMapped;
        })
    }
    return stateAccount;
}

export const getCuentaMedicasByEstadoQuery = gql`
query getCuentaMedicasByEstadoQuery($prestador: String, $estado: Int, $fecha: String){ 
    getCuentasMedicasByEstado(prestador:$prestador, estado:$estado, fecha:$fecha) {
      estado
      estadoEtl
      montoTotal
      nombrePaciente
      apellidoPaternoPaciente
      apellidoMaternoPaciente
      numeroCobro
      numeroCuenta
      numeroEnvio
      numeroDocumentos
      canalOrigen
      rutPaciente
      id
      idCuentaMedica
      fechaEntrega
      fechaRecepcion
      fechaProbableLiquidacion
    }
}`;


export const ejecutarMigracionQuery = gql`
mutation ejecutarMigracionQuery($idSolicitud: Int){ 
    ejecutarMigracion(idSolicitud:$idSolicitud) {
    resultado
    }
}`;


export const getFechaProbableLiquid = gql`
query getDateProblableLiqQuery($fechaDesde: Date){
    obtenerFechaLiquidacion(fechaDesde:$fechaDesde) {
        fechaLiquidacion
    }
}`;
