import { useEffect, useState } from 'react';
import { CustomSelect } from '../ui-components/customSelect'
import { defaultMonth } from '../../utils/constDefault';
import { getMonths } from '../../utils/getFormatDate';

export const SelectMonth = ({month, handleSelectChange}: any) => {
    const [ months, setMonths ] = useState<any>(defaultMonth);
    useEffect(() => {
        setMonths(getMonths)
    }, [])
  return (
    <CustomSelect
        onChange={handleSelectChange}
        disabled={false}
        value={month}
        data={months}
        titulo={"Seleccione"}
        name="Year"
    />
  )
}
