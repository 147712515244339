import { boxCard } from "../../theme/ui/Boxs";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { SelectProvider } from './selectProvider';
import { RenditionDetails } from "./renditionDetails";
import { SendFile } from "./sendFile";

function BonusHeader() {
  return (
    <Box sx={boxCard} mt="18px !important">       
      <Grid container>
        <SelectProvider />
        <RenditionDetails />
        <SendFile />
      </Grid>
    </Box>
  );
}

export default BonusHeader;