import { gql } from "@apollo/client"

export const GetDocByRenditionId = gql`
query($idRendition:Int){
    documentByRenditionId(idRendition: $idRendition){
        estado
        mensaje
        data {
            NombreExtOriginalDelArchivo 
            ContenidoDelArchivo
        }
    }
}`;