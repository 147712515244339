import { createContext } from "react";

export interface ContextValueTracking {
    dataContext: { 
        holding: string,
        nameHolding: string,
        year: number
        month: number        
        medicalRenditions: any[]
        totalRecords: number
        type: string,
        dateRequest: Date,
        update: Boolean
     };
     setDataContext: React.Dispatch<React.SetStateAction<ContextValueTracking["dataContext"]>>;
  }
  
  const ContextRenditionTracking = createContext<ContextValueTracking>({
    dataContext: {
        holding: "0",
        nameHolding: '',
        year: 0,
        month: 0,
        medicalRenditions: [],
        totalRecords: 0,
        type: '0',
        dateRequest: new Date(),
        update: true
    },
    setDataContext: () => {},
  });

export default ContextRenditionTracking;