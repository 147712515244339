import React from 'react'
import { Grid, Typography } from '@mui/material';
import { CustomSelectInput } from '../../theme/ui/Select';
import { inputText } from '../ui-components/Typography/typographyStyles';
import { formatNumber } from '../../utils/formatNumber';

const inputStyles = {
  textAlign: "right" as const, // Asegúrate de definir 'textAlign' como 'right'
};

const placeholderStyles = `
  .custom-input::placeholder {
    color: black; 
    opacity: 1 !important;
  }
  .custom-input {
    padding-right: 5px !important;
  }
`;

export const InputAmounts = ({setFormData, valueInput, nameInput, disabled= false, title}: any) => {
    const handleInput = (event: any) => {
    
        let inputText = event.target.value
          .replace(/\D/g, "")
          .replace(/\./g, "")
          .replace(/,/g, "");
    
        let name = event.target.name;
    
        if (inputText.match(/[^0-9]/) || inputText.length > 9) {
          return;
        }
        
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          [name]: inputText !== "" ? formatNumber(Number(inputText)) : "",
        }));
        
      };
      
  return (
    <Grid item style={{maxWidth: '13.5%'}} pr="20px" pt="24px">
        <Typography sx={inputText} pt="12px" pb="6px" whiteSpace={"pre"}>
          { title }
        </Typography>
        <style>{placeholderStyles}</style>
        <CustomSelectInput
          value={valueInput}
          disabled={disabled}
          onChange={handleInput}
          placeholder="$ 0"
          name={nameInput}
          type="text"
          inputProps={{
            style: inputStyles,
            className: "custom-input",
          }}
        />
      </Grid>
  )
}
