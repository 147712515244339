import { gql } from "@apollo/client";

export const uploadFoliosRendicion = gql`
mutation(   $inputFile: String, $fileName: String,
            $rutPrestador:String, $namePrestador:String,
            $rutHolding:String, $nameHolding:String,
            $medicalRenditionId: Int, $diasCtrlCaducidad: Int,
            $multiplesFacturas: Boolean, $providerUser: String
    ){
        uploadFoliosRendicion(
            inputFile: $inputFile,
            fileName: $fileName,
            rutPrestador:$rutPrestador,
            namePrestador: $namePrestador,
            rutHolding:$rutHolding,
            nameHolding: $nameHolding,
            medicalRenditionId:$medicalRenditionId,
            diasCtrlCaducidad:$diasCtrlCaducidad,
            multiplesFacturas: $multiplesFacturas,
            providerUser: $providerUser
        ){
            estado
            mensaje
            file
            errorCount
            data{
                idRendicion
                montoTotal
                bonosTotal
                facturas
            }
        }
    }
`;