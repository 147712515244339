import { gql } from "@apollo/client";

export const loadDocumentsQuery = gql`
  query getFiles($idSolicitud: Int){
    obtenerDocumentoIdSolicitud(idSolicitud: $idSolicitud) {
        id
        createdAt
        folioDocumento
        idSharePoint
        nombreDocumento
        tipoDocumento
        url
        usuario
    }
  }
`;

export const sendFileQuery = gql`
  mutation sendFile(
    $tipoDocumento: String,
    $contenidoDocumento: String,
    $idSolicitud: Int,
    $usuario: String,
    $rutBeneficiario: String,
    $nombreDocumento: String
  ){
    uploadDocumento(
        tipoDocumento: $tipoDocumento,
        contenidoDocumento: $contenidoDocumento,
        idSolicitud: $idSolicitud,
        usuario: $usuario,
        rutBeneficiario: $rutBeneficiario,
        nombreDocumento: $nombreDocumento
    ){
        id
        url
        nombreDocumento
        usuario
        folioDocumento
        createdAt
        idSharePoint
        tipoDocumento
    }
  }
`;

export const deleteDocumentoQuery = gql`
  query deleteDocumentoQuery($id: Int){ 
      deleteDocumento(idDocumento: $id) {
          estado
      }
  }
`;
