import DownloadWhite from "../../../assets/downloadWhiteButton.svg";
export const ButtonAcceptedModal = ({title}: any) => {
  return (
    <>
        <div style={{ paddingLeft: "10px" }} >
          {`${title}`}
        </div>
    </>
  );
};
