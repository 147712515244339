import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  CustomModal,
  CustomModalTitle,
} from "../../../../components/UI/Modal/CustomModal";
import {
  blackBold2,
  modalSubT,
  modalTitle,
  purpleLittle,
} from "../../../../components/ui-components/Typography/typographyStyles";
import { TableHistory } from "./tableHistory";

type ModalHistoryProps = {
  open: boolean;
  onClose: () => void;
  row: any;
};
export const ModalHistory = ({ open, onClose, row }: ModalHistoryProps) => {
  return (
    <CustomModal
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <CustomModalTitle id="modalEmitir" onClose={onClose}>
        <Typography sx={modalTitle} paddingTop="16px">
          Historial resolutivo
        </Typography>
        <Typography sx={modalSubT} pt="16px" pb="17px">
          Aquí puedes ver todos los cambios realizados a la cuenta.
        </Typography>
      </CustomModalTitle>

      <DialogContent dividers>
        <Grid container>
          <Grid item xs={4} pb="16px">
            <Stack direction="column">
              <Typography sx={purpleLittle} component="div" pb="4px">
                NºCuenta
              </Typography>
              <Typography sx={blackBold2} component="div">
                {row?.id}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} pb="16px">
            <Stack direction="column">
              <Typography sx={purpleLittle} component="div" pb="4px">
                Nº C. Extranet
              </Typography>
              <Typography sx={blackBold2} component="div">
                {row?.idCMD}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4} pb="16px">
            <Stack direction="column">
              <Typography sx={purpleLittle} component="div" pb="4px">
                Nº C. Prestador
              </Typography>
              <Typography sx={blackBold2} component="div">
                {row?.numeroCuenta}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} textAlign={"left"} pt="20px">
            <TableHistory row={row} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ textAlign: "right" }}>
        <Button
          sx={{ padding: "15px 42px !important" }}
          color="secondary"
          onClick={onClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </CustomModal>
  );
};
