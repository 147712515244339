import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  grayBold,
  emptyResumeText,
  greenText,
  greenTextBold,
  purpleText,
  redText,
  yellowText,
} from "../../../components/ui-components/Typography/typographyStyles";
import { grayBox, verticalDivider } from "../../../theme/ui/Boxs";
import { Fragment } from "react";

type resumeAccountsProps = {
  resumeAccounts: { [key: string]: number };
};

const resumeColor: { [key: string]: object } = {
  entregada: greenText,
  enproceso: purpleText,
  liquidada: purpleText,
  liquidadaManual: purpleText,
  rechazada: redText,
  devuelta: yellowText,
  empty: emptyResumeText,
  anulada: redText
};

const resumeDescription: { [key: string]: string } = {
  entregada: "Entregada",
  enproceso: "En Proceso",
  liquidada: "Liquidada",
  liquidadaManual: "Liquidada Manual",
  rechazada: "Rechazada",
  devuelta: "Devuelta",
  eliminada: "Eliminada",
  anulada: "Anulada",
};

const ResumeAccounts = ({ resumeAccounts }: resumeAccountsProps) => {
  return (
    <Box p="24px">
      <Stack
        sx={grayBox}
        width={"100%"}
        direction={"row"}
        alignItems={"center"}
        overflow={"auto"}
      >
        <Typography sx={greenTextBold} pr="10px">
          RESUMEN CUENTAS
        </Typography>
        {Object.keys(resumeAccounts).map((key) => {
          return (
            <Fragment key={key}>
              <Divider sx={verticalDivider} />
              <Typography
                sx={
                  resumeAccounts[key] === 0
                    ? resumeColor["empty"]
                    : resumeColor[key]
                }
                pl="10px"
                pr="4px"
              >
                {resumeDescription[key]}:
              </Typography>
              <Typography
                sx={
                  resumeAccounts[key] === 0
                    ? resumeColor["entregada"]
                    : grayBold
                }
                pr="10px"
              >
                {resumeAccounts[key]}
              </Typography>
            </Fragment>
          );
        })}
      </Stack>
    </Box>
  );
};

export default ResumeAccounts;
