import { PrestadorByRut, Prestador } from "../interfaces/tracingCmInterfaces";
import { gql } from "@apollo/client";

export const getPrestadoresByRut = gql`
  query getPrestadoresByRut($rutPrestador: String) {
    obtenerPrestadores(rutPrestador: $rutPrestador) {
      id
      nombrePrestador
      rutPrestador
    }
  }
`;

export function getPrestadoresByRutMapper(response: any) {
  const prestadores: PrestadorByRut = {
    prestadores: response.map((prestador: any) => {
      const prestadorMapped: Prestador = {
        id: prestador.id,
        nombrePrestador: prestador.nombrePrestador,
        rutPrestador: prestador.rutPrestador,
      };
      return prestadorMapped;
    }),
  };
  return prestadores;
}

export const getCuentaMedicasQuery = gql`
  query getCuentaMedicasQuery($prestador: String, $mes: String, $anio: String) {
    getCuentasMedicasGroupbyFechaAndCount(
      prestador: $prestador
      mes: $mes
      anio: $anio
    ) {
      total
      fechaEntrega
      countRecibidas
      countEnProceso
      countLiquidadas
      countRechazadas
      countDevueltas
      countPendientes
    }
  }
`;

export const queryGetAllCuentasSolicitudes = gql`
  query (
    $limit: Int
    $offset: Int
    $orderBy: orderQuery
    $filterBy: filterQuery
  ) {
    getAllCuentasSolicitudes(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      filterBy: $filterBy
    ) {
      cuentas {
        id
        idCMD
        type
        numeroCuenta
        numeroCobro
        numeroEnvio
        rutPaciente
        nombrePaciente
        rutPrestador
        razonSocial
        montoTotal
        estado
        fechaEntrega
        canalOrigen
        numeroDocumentos
        fechaRecepcion
        fechaEmision
        fechaIngreso
        estadoMigracion
        historial{
          nota
          idUsuario
        }
      }
      total
      entregada
      enproceso
      liquidada
      liquidadaManual
      rechazada
      devuelta
      eliminada
      anulada
    }
  }
`;

export const migrarSolicitudes = gql`
  mutation($solicitudes: [Int]){
    sendSolicitudLambda(solicitudes: $solicitudes){
        resultado
    }
  }
`;