import { createContext, useState } from "react";

interface OptionsSelected {
  indexAnio: number;
  indexPrestador: number;
  mes: number;
  prestador: any
}

export interface CMContextData {
  status: number | null;
  date: Date | null;
  estadoCM: number | null;
  setEstadoCM: (newEstadoCM: number) => void
  changeStatus: (status: number, date: Date | null) => void;
  optionsSelectedTracing: OptionsSelected | null;
  setOptionsSelectedTracing: (newOptions:OptionsSelected | null) => void
  listEstados: any | null;
  changeListEstados: (newListEstados : any | null) => void;
}

export const EstadoContext = createContext<CMContextData | null>(null)

const CMProvider = (props: any) => {
  const [status, setStatus] = useState<number | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const [estadoCM, setEstadoCM] = useState<number | null>(null)
  
  const [optionsSelectedTracing, setOptionsSelectedTracing] = useState<OptionsSelected | null>(null);
  const [listEstados, setListEstados] = useState<any | null>(null);

  const changeStatus = (newStatus:number, newDate:Date | null = null) => {
    setStatus(newStatus);
    setDate(newDate);
  }

  const changeListEstados = (newListEstados: any) => {
    setListEstados(newListEstados)
  }

  return (
    <EstadoContext.Provider value={{
      status, date, changeStatus, optionsSelectedTracing, setOptionsSelectedTracing, listEstados, changeListEstados, estadoCM, setEstadoCM}}>
      {props.children}
    </EstadoContext.Provider>)
}

export default CMProvider