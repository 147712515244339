import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TablePagination,
    TableSortLabel,
    IconButton,
    Menu,
    MenuItem
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DropdownIndicator } from "../../../components/ui-components/Table/dropDownIndicator";
import { headCellsReports, perPageGlobal } from "../../../utils/constDefault";
import { NoResult } from "../../../components/ui-components/Table/noResult";
import { StatesRendition } from "../../../components/medicalRendition/statesRendition";
import { formatNumber } from "../../../utils/formatNumber";
import { useState } from "react";
import { GetTributaryDocsByRenditionId } from "../../../services/getTributaryDocsByRenditionId";
import { ModalDocumentos } from "../../../components/ui-components/modalDocumentos";
import { clientCCP } from "../../../config/apolloClientsCCP";
import Toast from "../../../components/ui-components/Toast/Toast";
import { ModalSpinnerLoad } from "../../../components/UI/Modal/spinnerLoad";
import { GetDocByRenditionId } from "../../../services/getDocByRenditionId";
import { downloadFromBase64 } from "../../../utils/downloadBase64File";

const TableConsultMedicalReport = ({ datos, totalRecords, getRenditions, orderBy, setOrderBy, order, setOrder, setSearchTextbox, searchTextbox, page, setPage, rowsPerPage, setRowsPerPage, error }: any) => {
    
    const handleChangePage = (_event: any, newPage: any) => {
        setPage(newPage)
        getRenditions(newPage, rowsPerPage, orderBy, order)
    };

    const handleChangeRowsPerPage = (event: any) => {
        const perPage = parseInt(event.target.value, 10);
        setPage(0)
        setRowsPerPage(perPage)
        getRenditions(0, perPage, orderBy, order)
    };

    const getDirection = (columnId: string): "asc" | "desc" | undefined => {
        if (orderBy === columnId) {
          return order as "asc" | "desc"; 
        }
        return 'asc'; 
    };

    const handleSortClick = (columnId: any) => {        
        if (orderBy === columnId) {
          let orderMod = order === 'asc' ? 'desc' : 'asc';
          setOrder(orderMod);
          getRenditions(page, rowsPerPage, columnId, orderMod, searchTextbox)
        } else {
          setOrderBy(columnId);
          setOrder('asc');
          getRenditions(page, rowsPerPage, columnId, order, searchTextbox)
        }
        
    };

    const [anchorEl, setAnchorEl] = useState([null]);
    
    const [modalDocument, setModalDocument] = useState(false)
    const [loader, setLoading] = useState(false);
    const [openToastSolicitud, setOpenToastSolicitud] = useState<boolean>(false)
    const [toastSolicitud, setToastSolicitud] = useState({content: "", isSuccess: false,})

    const handleClickMenu = (e: any, index: number) => {
        let anchorRef = anchorEl;
        anchorRef[index] = e.currentTarget;
        setAnchorEl([...anchorRef]);
    };

    const handleCloseMenu = (index:number) => {
        let anchorRef = anchorEl;
        anchorRef[index] = null;
        setAnchorEl([...anchorRef]);
    };

    const handleDocuments = (renditionId: any)=>{
        obtainDocumentList(renditionId);     
    }

    const handleDownloadOriginalFile = (renditionId: any) => {
        obtainOriginalDocument(renditionId);
    }

    const [documentData, setDocumentData] = useState([]);
    
    const obtainDocumentList = async (renditionId: any) => {
        setLoading(true)
        try {
            const result = await clientCCP.query({
                query: GetTributaryDocsByRenditionId,
                variables: {
                    idRendition: renditionId
                },
            });
            Promise.all([result]).then(() => {
                if (result?.data?.tributaryDocsByRenditionId?.estado == 200
                    && (result?.data?.tributaryDocsByRenditionId?.data.length > 0)) {
                    setModalDocument(true);
                    setDocumentData(result?.data?.tributaryDocsByRenditionId?.data);
                }
                else {
                    setOpenToastSolicitud(true);
                    setToastSolicitud({ content: "No se encontraron documentos", isSuccess: false, });
                }
            })
        }
        catch (error) {
            console.log(error)
            setOpenToastSolicitud(true);
            setToastSolicitud({ content: "Ocurrió un error al buscar documentos. Por favor, inténtalo de nuevo más tarde.", isSuccess: false });
        }
        finally {
            setLoading(false);
            setTimeout(() => {
                setOpenToastSolicitud(false);
            }, 3000);
        }
    }

    const obtainOriginalDocument = async (renditionId: any) => {
        setLoading(true)
        try {
            const result = await clientCCP.query({
                query: GetDocByRenditionId,
                variables: {
                    idRendition: renditionId
                },
            });
            Promise.all([result]).then(() => {
                const { ContenidoDelArchivo, NombreExtOriginalDelArchivo } = result?.data?.documentByRenditionId?.data;
                if (ContenidoDelArchivo && NombreExtOriginalDelArchivo) {
                    downloadFromBase64(ContenidoDelArchivo, NombreExtOriginalDelArchivo);
                    setOpenToastSolicitud(true);
                    setToastSolicitud({ content: "Documento descargado", isSuccess: true });
                }
                else {
                    setOpenToastSolicitud(true);
                    setToastSolicitud({ content: "No se encontró el documento", isSuccess: false });
                }
            })
        }
        catch (error) {
            console.log(error)
            setOpenToastSolicitud(true);
            setToastSolicitud({ content: "Ocurrió un error al buscar el documento. Por favor, inténtalo de nuevo más tarde.", isSuccess: false });
        }
        finally {
            setLoading(false);
            setTimeout(() => {
                setOpenToastSolicitud(false);
            }, 3000);
        }
    }

    return (
        <>
        
            {openToastSolicitud ? (
                <Toast
                    openAlert={openToastSolicitud}
                    setOpenAlert={setOpenToastSolicitud}
                    contentAlert={toastSolicitud}
                    onClose={() => setOpenToastSolicitud(false)}
                />
            ) : null}
            <ModalDocumentos
                {...{
                    onOpen: modalDocument,
                    setOpen: setModalDocument,
                    data: documentData,
                    page,
                    rowsPerPage
                }}
            />
            {
                loader &&
                <ModalSpinnerLoad onOpen={setLoading} onClose={setLoading} subText={"Cargando información de documentos..."} />
            }
            { 
                error ?
                    <Paper className="paper-tables">
                    {
                        datos.length > 0 ?                        
                            <TableContainer sx={{ maxWidth: "auto" }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>    
                                        <TableRow>
                                        {headCellsReports.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={'center'}
                                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                                    className={ orderBy === headCell.columnName ? "escritorioTrabajo activeSort" 
                                                    : ((headCell.columnName === 'totalRendition')? "escritorioTrabajo headerBorderless"
                                                    : (headCell.columnName === 'edit')? "escritorioTrabajo headerSort tableBodyAcciones" : "escritorioTrabajo headerSort")}
                                                >
                                                    {headCell.sorted ?
                                                        <TableSortLabel
                                                            active={orderBy === headCell.columnName}
                                                            direction={getDirection(headCell.columnName)}
                                                            onClick={() => handleSortClick(headCell.columnName)}
                                                            IconComponent={() => <DropdownIndicator active={orderBy === headCell.columnName} order={orderBy === headCell.columnName ? order : 'desc'} />}>
                                                            {headCell.label}
                                                        </TableSortLabel>
                                                        :
                                                        <span>{ headCell.label }</span>
                                                    }
                                                </TableCell>
                                            ))}    
                                        </TableRow>                               
                                    </TableHead>                               
                                    <TableBody>
                                        {
                                            datos.map((m: any, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{textAlign: "center"}}>{ m.id }</TableCell>
                                                    <TableCell sx={{textAlign: "center"}}>{ m.rutProvider }</TableCell>
                                                    <TableCell sx={{textAlign: "center"}}><StatesRendition state={m.renditionStatus} /></TableCell>
                                                    <TableCell sx={{textAlign: "center"}}>{ m.dateState }</TableCell>
                                                    <TableCell sx={{textAlign: "right"}}>{ m.documentFolio }</TableCell>
                                                    <TableCell sx={{textAlign: "right"}}>{ formatNumber(m.documentAmount) }</TableCell>
                                                    <TableCell sx={{textAlign: "right"}}>{ formatNumber(m.totalRendition) }</TableCell>
                                                    
                                                    <TableCell sx={{ textAlign: "center"}} className={"tableBodyAcciones"}>
                                                        {
                                                            <div className={"groupMenu"}>
                                                                <IconButton data-testid={index === 0 ? 'button-menu' : undefined} onClick={(e: any) => handleClickMenu(e, index)}>
                                                                    <MoreVertIcon />
                                                                </IconButton>

                                                                <Menu id={`menu-${m.id}`}
                                                                    anchorEl={anchorEl[index]}
                                                                    open={Boolean(anchorEl[index])}
                                                                    onClick={() => handleCloseMenu(index)}
                                                                    onClose={() => handleCloseMenu(index)}
                                                                    className="shadow-menu">
                                                                    <MenuItem onClick={() => handleDownloadOriginalFile(m.id)}>
                                                                        Bonos
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() =>handleDocuments(m.id)}>
                                                                        Documento tributario
                                                                    </MenuItem>
                                                                </Menu>

                                                            </div>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                        rowsPerPageOptions={perPageGlobal}
                                        component="div"
                                        count={totalRecords}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        sx={{ mt: "14px", mb: "14px" }}
                                        labelRowsPerPage={ `Mostrar por página`}
                                        labelDisplayedRows={(e) => {
                                        return (
                                            "" +
                                            (page + 1) +
                                            " de " +
                                            ((totalRecords > 0) ? Math.ceil(totalRecords / rowsPerPage): 1)
                                        );
                                        }}
                                    />
                            </TableContainer> 
                        :
                        <NoResult
                            newFound
                            titulo={"No se encontró información de rendiciones"}
                            subtitulo={"Inténtelo nuevamente usando otra información de filtro"} />
                                }
                    </Paper>
                    :
                    <NoResult
                        newDontSearch
                        titulo={"Realiza una búsqueda"}
                        subtitulo={"Usa los filtros de búsqueda para encontrar las rendiciones que desees consultar."} />                                        
            }
        </>
    )
}

export default TableConsultMedicalReport;