import { Grid, Typography } from "@mui/material";
import {
  backGreen,
  grayText,
  titleBox,
} from "../ui-components/Typography/typographyStyles";
import { truncateText } from "../../utils/truncateText";
import { formatNumber } from "../../utils/formatNumber";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import { useContext } from "react";

export const RenditionDetails = () => {
  const { dataContext } = useContext<ContextValue>(ContextRendition);
  const { totalMedicalBonds, amountToPay, file } = dataContext;

  return (
    <Grid item xs={3}>
      <Typography sx={titleBox}>Bonos a rendir</Typography>
      <Typography sx={grayText} pt="16px">
        Total de bonos cargados: {totalMedicalBonds > 0 && totalMedicalBonds}
      </Typography>
      <Typography sx={grayText} pt="16px">
        Nombre del archivo:{" "}
        <small style={{display: 'block'}}>{file && truncateText(file?.name, 40)}</small>
      </Typography>
      <Typography sx={backGreen} mt="16px">
        Monto total por rendir:{" "}
        {amountToPay > 0 && formatNumber(Number(amountToPay))}
      </Typography>
    </Grid>
  );
};
