import { parse, format } from "date-fns";

export function getFormattedDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}`;
  }  

export function getFormattedToday() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function getFormattedDate(date: any) {
  if (date instanceof Date) {
    return format(date, 'dd-MM-yyyy');
  }

  const parts = date.split(/[-T:]/);

  const newDate = new Date(
    Number(parts[0]),    
    Number(parts[1]) - 1, 
    Number(parts[2]),    
  );

  return format(newDate, 'dd-MM-yyyy');
}

export function getFormattedddMMyyyy(date: any) {
  try {
      if (date instanceof Date) {
          return format(date, 'ddMMyyyy');
        }
      
        const parts = date.split(/[-T:]/);
      
        const newDate = new Date(
          Number(parts[0]),    
          Number(parts[1]) - 1, 
          Number(parts[2]),
        );
      
        return format(newDate, 'ddMMyyyy');
  } catch (error) {
      return ""
  }    
}
  

export const getYears = () => Array.from({ length: new Date().getFullYear() - 2023  + 1 }, (_, index) => 2023 + index);

export const getMonths = () => {
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  return months.map((nombre, index) => ({
    id: index + 1,
    nombre,
    value: index + 1,
  }));
};

export const getFormatYYYYMMDD = (date: any) => {
  return parse(date, "dd-MM-yyyy", new Date());
}

export const getDateFormatYYYYMMDDGuion = (date: string) => {
  const fechaParseada = parse(date, 'dd-MM-yyyy', new Date());
  return format(fechaParseada, 'yyyy-MM-dd');
}

export const validateFormFilters = (obj: any) => {
  console.log('obj a validar', obj);

  if ((obj.holding && obj.holding !== '0')/**Sección 1: Holding */
    || ((obj.folioRendition && obj.folioRendition !== '0') || (obj.folioTributary && obj.folioTributary !== '0') || (obj.folioBono && obj.folioBono !== '0'))/**Sección 2: Folios */
    || (obj.stateRendition !== '0' && obj.dateInit !== null && obj.dateInit !== null)/**Sección 3: Fechas rendición */
  ) {
    return true;
  }
  else {
    return false;
  }
}