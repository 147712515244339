import { CustomSelect } from '../ui-components/customSelect'
import { getYears } from '../../utils/getFormatDate'
import { useEffect, useState } from 'react';
import { defaultYear } from '../../utils/constDefault';

export const SelectYear = ({year, handleSelectChange}: any) => {
    const [ years, setYears ] = useState<any>(defaultYear);
    const loadData = () => {
        const data = getYears().map( year => {
            return {
                id: year.toString(), 
                nombre: year.toString(), 
                value: year.toString(), 
            }
        });
        setYears(data)
    };

    useEffect(() => {
        loadData();
    }, []);

  return (
    <CustomSelect
        onChange={handleSelectChange}
        disabled={false}
        value={year}
        data={years}
        titulo={"Seleccione"}
        name="Year"
    />
  )
}
