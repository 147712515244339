import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { NoResult } from "../../../../components/ui-components/Table/noResult";
import {
  contTitle,
  tableText,
} from "../../../../components/ui-components/Typography/typographyStyles";
import TrashIcon from "../../../../assets/icons/trashTable.svg";
import EyeIcon from "../../../../assets/icons/eyeTable.svg";
import { StyledTableCell } from "../../../../theme/ui/Table/table";
import { documentoCuentaMedica } from "../../types";

type TableFilesProps = {
  files: documentoCuentaMedica[];
  canDelete: boolean;
  onDelete: ({ fileId, folio }: { fileId: number, folio: string}) => void;
};

export default function TableFiles({
  files,
  canDelete,
  onDelete,
}: TableFilesProps) {

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleViewDocument = (URLDocument: string) => {
    window.open(URLDocument, "_blank", "noopener,noreferrer");
  };

  return (
    <Box>
      <Paper className="paper-tables">
        <TableContainer
          className="grayScroll"
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "8px",
            border: " 2px solid #E9EBEC",
          }}
        >
          {files.length === 0 ? (
            <NoResult
              titulo={"Carga un archivo"}
              subtitulo={"Ingresa información para visualizar en el listado"}
              fileImg
            />
          ) : (
            <Table
              sx={{ minWidth: "auto", maxHeight: "263px" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Folio</StyledTableCell>
                  <StyledTableCell>Tipo</StyledTableCell>
                  <StyledTableCell>Nombre archivo</StyledTableCell>
                  <StyledTableCell>Ver</StyledTableCell>
                  {canDelete && <StyledTableCell>Eliminar</StyledTableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {files.map((file: documentoCuentaMedica) => (
                  <TableRow key={file.id}>
                    <TableCell>
                      <Typography variant="h5" sx={tableText}>
                        {file.folioDocumento}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={tableText}>
                        {file.tipoDocumento}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={tableText}>
                        {file.nombreDocumento}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewDocument(file.url)}>
                        <img src={EyeIcon} alt="" />
                      </IconButton>
                    </TableCell>
                    {canDelete && (
                      <TableCell>
                        <IconButton data-testid="delete-file" onClick={() => onDelete({ fileId: file.id, folio: file.folioDocumento})}>
                          <img src={TrashIcon} alt="" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
}
