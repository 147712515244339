import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export const SignInButton = () => {
  const { instance } = useMsal();
  
  const handleLogin = (instance: any) => {
    instance
      .loginRedirect(loginRequest)
      .then((response: any) => {

      })
      .catch((e: any) => {
        console.error(e);
      });
  };

  return (
    <Button
      variant="outlined"
      className="doc-button"
      onClick={() => handleLogin(instance)}
      startIcon={<AccountCircleIcon color="primary"></AccountCircleIcon>}
      disableRipple
    >
      Iniciar Sesión
    </Button>
  );
};
