import { gql } from "@apollo/client"

export const GetDocumentById = gql`
query($documentId:String){
    renditiontributaryDocsById(documentId: $documentId){
        estado
        mensaje
        data {
            NombreExtOriginalDelArchivo 
            ContenidoDelArchivo
        }
    }
}`;