import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from "@mui/material";
import icon_warningOrange from "../../../assets/warningIcon.svg";
import { CustomModal, CustomModalTitle } from "./CustomModal";
import { modalText, modalTitle } from "../../ui-components/Typography/typographyStyles";
import { SecondaryButton } from "../../ui-components/Buttons/Button";
import LoadingButton from "../../ui-components/Buttons/LoadingButton";
type WarningModalProps = {
    onOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    subTitle?: any;
    primaryButton: string;
    secondaryButton: string;
    loading?: boolean;
    headWarning?: boolean;
    contentText?: JSX.Element;
    loadingWidth?: string;
};

export const WarningModalCustom = ({
    onOpen,
    onClose,
    onConfirm,
    title,
    subTitle,
    primaryButton,
    secondaryButton,
    loading,
    headWarning,
    contentText,
}: WarningModalProps) => {
    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            {headWarning ? (
                <>
                    <CustomModalTitle id="warning" disabled={loading} onClose={onClose}>
                        <Typography sx={modalTitle} paddingTop="16px">
                            {title}
                        </Typography>
                    </CustomModalTitle>

                    <DialogContent
                        dividers
                        sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
                    >
                        <img
                            alt="img"
                            src={icon_warningOrange}
                            style={{ marginTop: "20px", marginBottom: "16px" }}
                        />
                        <br />
                        <Typography sx={modalText}>{subTitle}</Typography>
                        {contentText}
                    </DialogContent>

                    <DialogActions sx={{ textAlign: "center", margin: "5px 0 5px 0" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Button
                                color="secondary"
                                onClick={onClose}
                                disabled={loading}
                                sx={{ minWidth: "175px", marginRight: "24px" }}
                            >
                                {secondaryButton}
                            </Button>
                            <LoadingButton
                                loading={loading}
                                toDo={onConfirm}
                                label={primaryButton}
                                sx={{ minWidth: "175px" }}
                            />
                        </Grid>
                    </DialogActions>
                </>
            ) : (
                <>
                    <CustomModalTitle id="warning" disabled={loading} onClose={onClose}>
                        <Typography sx={modalTitle} paddingTop="16px">
                            {title}
                        </Typography>
                    </CustomModalTitle>

                    <DialogContent
                        dividers
                        sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
                    >
                        <img
                            alt="img"
                            src={icon_warningOrange}
                            style={{ marginTop: "20px", marginBottom: "16px" }}
                        />
                        <Typography sx={modalText}>{subTitle}</Typography>
                        {contentText}
                    </DialogContent>

                    <DialogActions sx={{ textAlign: "center", margin: "5px 0 15px 0" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <SecondaryButton
                                sx={{ minWidth: "175px", marginRight: "24px" }}
                                onClick={onClose}
                                disabled={loading}
                            >
                                {secondaryButton}
                            </SecondaryButton>
                            <LoadingButton
                                loading={loading}
                                toDo={onConfirm}
                                label={primaryButton}
                                sx={{ minWidth: "175px" }}
                            />
                        </Grid>
                    </DialogActions>
                </>
            )}
        </CustomModal>
    );
};