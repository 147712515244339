import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SkeletonBar from "../../../components/ui-components/Skeletons/skeletonBar";
import { StyledTableCell } from "../../../theme/ui/Table/table";
import {
  stickyBodyLeft,
  stickyBodyRight,
  styleHeadAlt,
} from "../../../components/ui-components/Table/tableStyles";

export const SkeletonModalTracing = () => (
  <>
    <Box mb={"23px"}>
      <Grid container pb={"16px"}>
        <Grid xs={4}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={161} height={14} />
          </Box>
          <SkeletonBar width={111} height={14} />
        </Grid>
        <Grid xs={4}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={161} height={14} />
          </Box>
          <SkeletonBar width={111} height={14} />
        </Grid>
        <Grid xs={4}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={161} height={14} />
          </Box>
          <SkeletonBar width={111} height={14} />
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          borderRadius: "8px 8px 8px 8px",
          border: " 0.5px solid #4DC0B8 ",
        }}
      >
        <Table sx={{ minWidth: "auto" }} aria-label="customized table">
          <TableHead className="color-head-table">
            <TableRow>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={80} height={14} />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow data-testid="withData">
              <TableCell>
                <SkeletonBar width={80} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={80} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={80} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={80} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={80} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={80} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={80} height={14} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Stack
          direction={"row"}
          spacing={"24px"}
          justifyContent={"center"}
          p="24px"
          alignItems={"center"}
        >
          <SkeletonBar width={108} height={14} />
          <SkeletonBar width={68} height={32} />
          <SkeletonBar width={88} height={32} />
        </Stack>
      </TableContainer>
    </Box>
  </>
);

export const SkeletonModalFile = () => (
  <>
    <Box mb={"23px"} data-testid="skeleton-modal-file">
      <Grid container pb={"16px"}>
        <Grid item xs={4}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={96} height={14} />
          </Box>
          <SkeletonBar width={194} height={14} />
        </Grid>
        <Grid item xs={4}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={127} height={14} />
          </Box>
          <SkeletonBar width={103} height={14} />
        </Grid>
        <Grid item xs={4}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={80} height={14} />
          </Box>
          <SkeletonBar width={259} height={14} />
        </Grid>
        <Grid item xs={12} pt="16px">
          <Divider />
        </Grid>
        <Grid item xs={12} pt="16px" pb="16px">
          <Box pb="8px">
            {" "}
            <SkeletonBar width={192} height={14} />
          </Box>
          <SkeletonBar width={838} height={14} />
        </Grid>
        <Grid item xs={5}>
          <SkeletonBar width={331} height={50} />
        </Grid>
        <Grid item xs={3}>
          <SkeletonBar width={174} height={50} />
        </Grid>
        <Grid item xs={4} justifyContent={"right"}>
          <Box pb="8px">
            {" "}
            <SkeletonBar width={161} height={14} />
          </Box>
          <SkeletonBar width={133} height={14} />
        </Grid>
        <Grid item xs={12} pt="32px">
          <SkeletonBar width={220} height={14} />
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          borderRadius: "8px 8px 8px 8px",
          border: " 0.5px solid #E9EBEC ",
        }}
      >
        <Table sx={{ minWidth: "auto" }} aria-label="customized table">
          <TableHead className="color-head-table">
            <TableRow>
              <StyledTableCell>
                <SkeletonBar width={31} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={31} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={119} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={31} height={14} />
              </StyledTableCell>
              <StyledTableCell>
                <SkeletonBar width={53} height={14} />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow data-testid="withData">
              <TableCell>
                <SkeletonBar width={122} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={122} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={122} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={36} height={14} />
              </TableCell>
              <TableCell>
                <SkeletonBar width={36} height={14} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Stack
          direction={"row"}
          spacing={"24px"}
          justifyContent={"center"}
          p="24px"
          alignItems={"center"}
        >
          <SkeletonBar width={108} height={14} />
          <SkeletonBar width={68} height={32} />
          <SkeletonBar width={88} height={32} />
        </Stack>
      </TableContainer>
    </Box>
  </>
);

export const BtnModalLoad = () => (
  <>
    <Stack
      direction="row"
      justifyContent="end"
      alignItems="center"
      spacing={"16px"}
    >
      <SkeletonBar width={153} height={50} />
      <SkeletonBar width={233} height={50} />
    </Stack>
  </>
);

export const SkeletonTable = ({ rowsAccount }: { rowsAccount: number }) => (
  <TableContainer
    component={Paper}
    sx={{
      boxShadow: "none",
      border: "1px solid #E9EBEC",
      marginBottom: "24px  ",
    }}
  >
    <Table sx={{ minWidth: 650 }} aria-label="customized table">
      <TableHead className="color-head-table">
        <TableRow>
          <TableCell className="tCell-p headerSort" sx={stickyBodyLeft("0")}>
            <SkeletonBar width={16} height={16} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell className="tCell-p headerSort">
            <SkeletonBar width={86} height={14} />
          </TableCell>
          <TableCell
            sx={stickyBodyRight("0")}
            className="headerFirstSticky escritorioTrabajo"
          >
            <SkeletonBar width={86} height={14} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(rowsAccount)].map((x, i) => (
          <TableRow data-testid={`withData-${i}`} key={`skeletonTableRow-${i}`}>
            <TableCell
              padding="checkbox"
              className="tCell-p headerSort"
              sx={stickyBodyLeft("0")}
            >
              <SkeletonBar width={16} height={16} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell sx={stickyBodyRight("0")} className="tableBodyAcciones">
              <SkeletonBar width={80} height={14} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Stack
      direction={"row"}
      spacing={"24px"}
      justifyContent={"center"}
      p="24px"
      alignItems={"center"}
    >
      <SkeletonBar width={108} height={14} />
      <SkeletonBar width={68} height={32} />
      <SkeletonBar width={88} height={32} />
    </Stack>
  </TableContainer>
);

export const MainLoadTracing = ({ rowsAccount }: { rowsAccount: number }) => (
  <>
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "8px",
        width: "86%",
        ml: "auto",
        mr: "auto",
      }}
      mt="29px"
      mb="30px !important"
    >
      <Box p="24px">
        <SkeletonBar width={"auto"} height={44} />
      </Box>
      <Divider />
      <Stack
        direction="row"
        justifyContent={"space-between"}
        display={"flex"}
        p="44px 22px 22px 22px"
      >
        <Stack direction={"row"} spacing={"16px"} display={"flex"}>
          <SkeletonBar width={130} height={50} />
          <SkeletonBar width={130} height={50} />
          <SkeletonBar width={130} height={50} />
          <SkeletonBar width={86} height={50} />
        </Stack>
        <Stack
          direction={"row"}
          spacing={"16px"}
          justifyContent={"flex-end"}
          display={"flex"}
        >
          <SkeletonBar width={104} height={50} />
          <SkeletonBar width={130} height={50} />
        </Stack>
      </Stack>
      <Box mt="16px" mb="24px">
        <SkeletonTable rowsAccount={rowsAccount} />
      </Box>
    </Box>
  </>
);

export const LoadHistorialCtas = () => (
  <Paper
    className="paper-tables"
    sx={{ border: " 0.5px solid #4DC0B8 !important", borderRadius: "16px" }}
  >
    <TableContainer
      sx={{ pl: "0 !important" }}
      data-testid="loadingHistorialCta"
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell sx={styleHeadAlt}>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell sx={styleHeadAlt}>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell sx={styleHeadAlt}>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell sx={styleHeadAlt}>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell sx={styleHeadAlt}>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell sx={styleHeadAlt}>
              <SkeletonBar width={80} height={14} />
            </TableCell>
            <TableCell sx={styleHeadAlt}>
              <SkeletonBar width={80} height={14} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>

            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>

            <TableCell component="th" scope="row">
              <SkeletonBar width={80} height={14} />
            </TableCell>

            <TableCell component="th" scope="row">
              <SkeletonBar width={80} height={14} />
            </TableCell>

            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>

            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>

            <TableCell>
              <SkeletonBar width={80} height={14} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Box display={"flex"} justifyContent={"center"} pt="32px" pb="32px">
      <SkeletonBar width={371} height={16} />
    </Box>
  </Paper>
);
