import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { callGraphApi } from '../utils/graphEndPointEmployeeId';

export const useEmployeeId = () => {
  const { instance, accounts } = useMsal();
  const [employeeId, setEmployeeId] = useState<number | null>(null);
  const [response, setResponse] = useState<any | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [email, setEmail] = useState<string | null>(null); 

  useEffect(() => {

    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
      instance
        .acquireTokenSilent({ scopes: ["user.read"] })
        .then((res) => {   
            const accessToken = res?.accessToken;
            if (accessToken) {
              callGraphApi(accessToken)
                .then((empId: any) => {
                  setEmployeeId(empId)
                })
                .catch((error) => console.log("No obtuvimos información, por favor, refresque la página"));
            }
            setResponse(res);
            if (res?.account) {
              const idTokenClaims = res.account.idTokenClaims;
              setRoles(idTokenClaims?.roles ?? []);
              setEmail(idTokenClaims?.preferred_username ?? null)
            }
        })
        .catch((error) => 
          console.log("No obtuvimos información, por favor, refresque la página")
        );
    }else {
      //console.info("No conectado")
    }
  }, [instance, accounts]);

  return { employeeId, response, roles, email };
};
