import medicalSurrenderIcon from '../assets/medicalSurrenderIcon.svg'
import medicalSurrenderOrange from '../assets/medicalSurrenderOrange.svg'
import pay from '../assets/grayPay.svg'
import payOrange from '../assets/paySign.svg'
import MagOrange from '../assets/icons/lupaSideBar.svg'
import Mag from '../assets/icons/lupaGray.svg'
import segIsapr from '../assets/medicalIsapreIcon.svg'
import segIsaprOrange from '../assets/isapreOrange.svg'

export const items = [
    {
        id: 1,
        icon: pay,
        iconSelected: payOrange,
        title: 'Solicitud de Rendición Médica',
        route: '/medical-rendition',
        role: ['RENDICION_MED']
    },
    {
        id: 2,
        icon: medicalSurrenderIcon,
        iconSelected: medicalSurrenderOrange,
        title: 'Seguimiento de Rendiciones Médicas',
        route: '/tracing-medical-rendition',
        role: ['RENDICION_MED']
    },
    {
        id: 3,
        icon: Mag,
        iconSelected: MagOrange,
        title: 'Consultar rendiciones médicas',
        route: '/consultaMedica',
        role: ['RENDICION_MED']
    },
    {
        id: 4,
        icon: segIsapr,
        iconSelected: segIsaprOrange,
        title: 'Seguimiento y Envío de Cuentas Médicas a Isapre',
        route: '/newTracingCM/',
        role: ['PRESTADOR']
    },
]
