import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { NoResult } from "../../../../components/ui-components/Table/noResult";
import { CustomTooltip } from "../../../../components/ui-components/Tooltip/LongTextTooltip";
import { Paginador } from "../../../../components/ui-components/Table/tablePaginador";
import {
  subTable,
  tableText,
} from "../../../../components/ui-components/Typography/typographyStyles";
import { LoadHistorialCtas } from "../tracingLoaders";
import { getNewTracingCMHistorial } from "../../../../services/resolutionHistoryCmService";
import StatusTag from "../statusTag";
import { StyledTableCell } from "../../../../theme/ui/Table/table";

export const TableHistory = ({ row }: any) => {
  const [dataHistorial, setDataHistorial] = useState<any>(null);
  const [count, setCount] = useState<number>(0);
  const [pagHisRes, setPagHisRes] = useState<number>(0);
  const [rowsPerPagHisRes, setRowsPerPagHisRes] = useState<number>(5);
  
  const { loading, error } = useQuery(getNewTracingCMHistorial, {
    variables: {
      idSolicitud: row.idCMD || row.id,
      canalOrigen: row.canalOrigen,
      limit: rowsPerPagHisRes, 
      offset: rowsPerPagHisRes * pagHisRes
    },
    onCompleted: (res) => {
      setDataHistorial(res.getHistorialCuentaMedicaNewTracingCM.historialCuentaMedica);
      setCount(res.getHistorialCuentaMedicaNewTracingCM.count);
    },
  });

  const changeRowPerPag = (lines: number) => {
    setRowsPerPagHisRes(lines);
  };
  const changePag = (newPage: number) => {
    setPagHisRes(newPage);
  };

  const FechaHandler = ({ fecha }: { fecha: string }) => {
    if (fecha) {
      const dataFechas = fecha.split(" ");
      return (
        <>
          {dataFechas[0]}
          {<Typography sx={subTable}>{dataFechas[1]}</Typography>}
        </>
      );
    } else {
      return null;
    }
  };
  return (
    <Box border=" 1px solid #E9EBEC" borderRadius={" 8px 8px 8px 8px"}>
      <Paper className="paper-tables">
        {loading ? (
          <LoadHistorialCtas />
        ) : error ? (
          <NoResult
            titulo="Error"
            subtitulo="Error al cargar el historial"
            noDivider
            error
          />
        ) : count > 0 ? (
          <TableContainer
            className="grayScroll"
            component={Paper}
            sx={{
              boxShadow: "none",
              borderRadius: "8px 8px 0 0",
              border: " 0.5px solid #4DC0B8 ",
            }}
          >
            <Table
              sx={{ minWidth: "auto", maxHeight: "263px" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    Fecha y hora<br></br> Transacción
                  </StyledTableCell>
                  <StyledTableCell>Estado</StyledTableCell>
                  <StyledTableCell>Causal</StyledTableCell>
                  <StyledTableCell>Usuario</StyledTableCell>
                  <StyledTableCell>
                    Fecha <br></br> derivación
                  </StyledTableCell>
                  <StyledTableCell>
                    Entidad<br></br> derivación
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: "300px" }}>
                    Nota
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataHistorial &&
                  dataHistorial.map((log: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        <FechaHandler fecha={log.fechaTransaccion ?? ''} />
                      </TableCell>
                      <TableCell>
                        <StatusTag statusId={Number(log.estado ?? 0)} isGrouped={false} />
                      </TableCell>
                      <TableCell>
                        <Typography sx={tableText}>{log.causal?.descripcionCausal}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={tableText}>
                          {log.idUsuario}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <FechaHandler fecha={log.fechaDerivacion} />
                      </TableCell>
                      <TableCell>
                        <Typography sx={tableText}>
                          {log.entidadDerivacion}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <CustomTooltip
                          title={log.nota}
                          arrow
                          placement="bottom"
                        >
                          <Typography sx={tableText}>
                            {log.tituloNota}
                          </Typography>
                        </CustomTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Paginador
              opciones={[5, 10, 15]}
              data={count}
              itemPorPagina={rowsPerPagHisRes}
              pagina={pagHisRes}
              setPagina={changePag}
              setLineasPagina={changeRowPerPag}
            />
          </TableContainer>
        ) : (
          <NoResult
            titulo="No se encontró historial"
            subtitulo="No se han encontrado registros de historial para esta cuenta"
            noDivider
            newFound
          />
        )}
      </Paper>
    </Box>
  );
};
