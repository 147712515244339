import { gql } from "@apollo/client";

export const medicalRenditionTrackingDetails = gql`
  query MedicalRenditionTrackingDetails($renditionDate: Date, $status: String, $rutProvider: String, $page: Int, $perPage: Int, $orderBy: String, $columnId: String, $searchText: String) {
    medicalRenditionTrackingDetails(renditionDate: $renditionDate, status: $status, rutProvider: $rutProvider, page: $page, perPage: $perPage, orderBy: $orderBy, columnId: $columnId, searchText: $searchText) {
      estado,
      mensaje,
      totalRecords,
      data {
        id
        canal
        totalAmount
        totalDocsTrib
        dateState
        totalVouchers
        note
      }
    }
}`