import { useLazyQuery } from "@apollo/client";
import { getFechaProbableLiquid } from "../../../../../services/detailsService";
import { format } from "date-fns";
import { useState } from "react";

const useFechaProbableLiquid = () => {
  const [dateProbLiq, setdateProbLiq] = useState<Date | null>(null);
  const [getDateProblableLiq] = useLazyQuery(getFechaProbableLiquid);

  const getDateProbLiq = (date: Date) => {
    getDateProblableLiq({
      variables: {
        fechaDesde: format(date!, "yyyy-MM-dd"),
      },
      onCompleted: (res) => {
        const dateString = res.obtenerFechaLiquidacion?.fechaLiquidacion;
        const [day, month, year] = dateString.split('-');
        const dateObject = new Date(Number(year), Number(month) - 1, Number(day));
        setdateProbLiq(dateObject);
      },
      onError: (err) => console.error(err)
    });
  };

  return { dateProbLiq, getDateProbLiq, setdateProbLiq };
};

export default useFechaProbableLiquid;