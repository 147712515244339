const graphEndpoint =
"https://graph.microsoft.com/v1.0/me?$select=businessPhones,displayName,givenName,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,employeeID,id";

export const callGraphApi = async (accessToken: string) => {
    const responseGraph = await fetch(graphEndpoint, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    if (responseGraph.ok) {
        const data = await responseGraph.json();
        if (data && data.employeeId) {
            return data.employeeId;
        }
        return 0;
    } else {
        return 0;
    }
};