import { gql } from "@apollo/client";

export const medicalRenditionTracking = gql`
    query MedicalRenditionTracking($month: Int, $year: Int, $rutProvider: String, $page: Int, $perPage: Int) {
        medicalRenditionTracking(month: $month, year: $year, rutProvider: $rutProvider, page: $page, perPage: $perPage) {
        estado,
        mensaje,
        totalRecords,
        data {
            day_formatted
            total
            solicitadas
            aprobadas
            rechazadas
            pagadas
        }
    }
}`