import { Box, Drawer } from "@mui/material";
import logo from "./../..//assets/esencial-logo.svg";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../../components/login/SignInButton";
import { SignOutButton } from "../../components/login/SignOutButton";
import { drawerStyle } from "../../theme/ui/drawer";
import { NavDrawerExtranet } from "./navDrawerExtranet";
import { useEffect, useState } from "react";
import { items } from "../../utils/items";
import { useEmployeeId } from "../../hooks/useEmployeeId";
import { getPrestadoresHolding, getByUserConfiguration } from "../../services/getPrestadores";
import IsLoading from "../isLoading/isLoading";
import { WarningModal } from "../UI/Modal/modalWarning";
import { clientCCP } from "../../config/apolloClientsCCP";
import useUserConfigurationContext from "../../hooks/useUserConfigurationContext";
import { useLazyQuery } from "@apollo/client";
import { isNotUndefinedAndNull } from "../../utils";
import { AnyNsRecord } from "dns";

export const DrawerExtranet = () => {
  const isAuthenticated = useIsAuthenticated();
  const userData = useEmployeeId();
  const [menu, setMenu] = useState<any>([]);
  const [info, setInfo] = useState<any>();
  const [infoPrestador, setInfoPrestador] = useState<any>();
  const [employeeId, setEmployeeId] = useState<any>();
  const [dataEmployee, setDataEmployee] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(true);

  const { userConfiguration, setUserConfiguration } = useUserConfigurationContext();
  const [handleGetUserConfiguration, { loading: isLoadingGetUserConfiguration}] = useLazyQuery(getByUserConfiguration);

  useEffect(() => {
    if (isAuthenticated) {
      if (employeeId) {
        if (employeeId !== "0") {
          sessionStorage.setItem("info-session", JSON.stringify(userData));
          setMenu(items);
          setInfo(JSON.stringify(userData));
        }
      }
      setEmployeeId(userData?.employeeId);
    }else {
      sessionStorage.removeItem('info-session');
      sessionStorage.removeItem('info-prestadores');
    }
  }, [userData, isAuthenticated, employeeId]);

  const llenarPrestadores = async () => {
    try {
      if (isAuthenticated) {
        const sessionData = sessionStorage.getItem("info-session");
        const infoData = sessionData ? JSON.parse(sessionData) : {};
        if (infoData.employeeId) {
          const result = await clientCCP.query({
            query: getPrestadoresHolding,
            variables: { rutPrestadorHolding: infoData.employeeId },
          });

          const { estado, data } = result.data.obtenerPrestadoresHolding;
          const updatedInfo: any = {
            prestadores: data,
          };

          if (estado === 200) {
            sessionStorage.setItem(
              "info-prestadores",
              JSON.stringify(updatedInfo)
            );
            setInfoPrestador(JSON.stringify(updatedInfo));
            setDataEmployee(1);
          }
          else {
            setDataEmployee(0);
          }
        }
      }
    } catch (error) {
      console.log("No pudimos obtener la información, favor vuelva a intentar:", error);
    }
  };

  const callGetUserConfiguration = async () => {
    try {
      if (isAuthenticated) {
        const sessionData = sessionStorage.getItem("info-session");
        const infoData = sessionData ? JSON.parse(sessionData) : {};
        if (infoData.email) {
          handleGetUserConfiguration({
            variables: {
              userId: infoData.email,
            },
            onCompleted: (res) => {
              if (isNotUndefinedAndNull(res.getUserConfiguration)) {
                setUserConfiguration(res.getUserConfiguration);
              }
            },
            onError: (err) => console.error(err)
          });
        }
      }
    } catch (error) {
      console.log("No pudimos obtener la información, favor vuelva a intentar:");
    }
  }

  useEffect(() => {
    llenarPrestadores();
    callGetUserConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, info, menu]);

  const InternalDrawer = () => {
    if (
      !isAuthenticated ||
      (!isLoadingGetUserConfiguration && userConfiguration.length === 0)
    )
      return null;
    if (isLoadingGetUserConfiguration || !infoPrestador)
      return <IsLoading message={"Cargando perfil"} />;
    return <NavDrawerExtranet isAuthenticated={isAuthenticated} items={menu} />;
  };

  return (
    <Box sx={{ width: 320 }} aria-label="mailbox folders">
      {
        dataEmployee === 0 &&
        <WarningModal
          onOpen={openModal}
          setOpen={setOpenModal}
          title={"No se puede avanzar"}
          subtitle={
            "Hemos encontrado un error al acceder a su información como prestador. Por favor de comunicar a su contraparte para entregar soporte respectivo."
          }
        />
      }
      { employeeId === 0 &&
          <WarningModal
            onOpen={openModal}
            setOpen={setOpenModal}
            title={"No se puede avanzar"}
            subtitle={
              "Se autenticó correctamente, pero no logramos acceder a la información de su Empresa. Favor de comunicarse con nuestro administrador de accesos por medio de la casilla de correo admaccesos@somosesencial.cl"
            }
          />
        }
        { employeeId && userConfiguration.length === 0 && !isLoadingGetUserConfiguration &&
          <WarningModal
            onOpen={openModal}
            setOpen={setOpenModal}
            title={"No se puede avanzar"}
            subtitle={
              "Se autenticó correctamente, pero usuario no tiene configuración. Favor de comunicarse con nuestro administrador de accesos por medio de la casilla de correo admaccesos@somosesencial.cl"
            }
          />
        }
      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        sx={drawerStyle}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Box height="50px" m="74px 58px 55px 32px">
          <img src={logo} alt="logo esencial"></img>
        </Box>
        <InternalDrawer />
        <Box
          height="100%"
          display="flex"
          alignItems="flex-end"
          pb="20px"
          justifyContent="center"
        >
          {isAuthenticated ? (
            <SignOutButton info={infoPrestador} />
          ) : (
            <SignInButton />
          )}
        </Box>
      </Drawer>     
    </Box>
  );
};
