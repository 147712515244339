import { Fragment } from "react";
import { Divider, Grid } from "@mui/material";
// import { createSvgIcon } from "@mui/material/utils";
import Idea from "../../../assets/ideaIcon.svg";
import ErrorIcon from "../../../assets/errorCircle.svg";
import Lupa from "../../../assets//lupa.svg";
import NewLight from "../../../assets/noSearchIcon.svg";
import FoundNew from "../../../assets/newFound.svg";
import TableDoc from "../../../assets/icons/tableIconDoc.svg";
import FileIcon from "../../../assets/icons/cargarArchivo.svg";

export function NoResult({
  titulo,
  subtitulo,
  noDivider,
  error,
  dontSearch,
  newDontSearch,
  newFound,
  docImg,
  fileImg
}: any) {
  return (
    <Fragment>
      {noDivider ? null : (
        <Grid item xs={12}>
          <Divider orientation="horizontal" />
        </Grid>
      )}
      <div className="box-resultados">
        <Grid
          pb="22px"
          pt="70px"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {error ? (
            <img src={ErrorIcon} alt="errorIcon" />
          ) : dontSearch ? (
            <img src={Idea} alt="idea" />
          ) : newDontSearch ? (
            <img src={NewLight} alt="idea" />
          ) : newFound ? (
            <img src={FoundNew} alt="lupa" />
          ) : docImg ? (
            <img src={TableDoc} alt="doc" />
          ) : fileImg ? (
              <img src={FileIcon} alt="doc" />
            )
              : <img src={Lupa} alt="lupa" />}
        </Grid>
        <Grid
          pb="4px"
          sx={{ margin: "auto 0" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <label className="TituloResultadoTabla">{titulo}</label>
        </Grid>
        <Grid
          pb="70px"
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <label className="subtituloResultadoTabla">{subtitulo}</label>
        </Grid>
      </div>
    </Fragment>
  );
}
