export function formatNumber(number: any){
    const format = new Intl.NumberFormat("es-CL", { style: 'currency', currency: 'CLP' }).format(number);
    return format.replace("$", "$ ");
}

export function formatNumberMiles(number: any) {    
    const format = new Intl.NumberFormat("es-CL", { style: 'decimal' }).format(number);
    return format;
}

export const removeFormatNumber = (number: any) => Number(String(number).replace(/[$ .]/g, ""))