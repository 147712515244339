import { gql } from "@apollo/client";

export const getHistorialCM = gql`
query getHistorialCM($id: Int, $canalOrigen: String){ 
    getHistorialCuentaMedica(idSolicitud: $id, canalOrigen: $canalOrigen) {
        id
        idSolicitud
        estado
        nota
        causal
        entidad
        usuario
        fechaDerivacion
        entidadDerivacion
        folioCuentaMedica
        prestador
        fechaInicioCobro
        fechaTerminoCobro
        montoTotalCobro
        canalOrigen
        fechaTransaccion
        tituloNota
        Parametro { valorParametro}
    }
}`;

export const getNewTracingCMHistorial = gql`
query getHistorialCuentaMedicaNewTracingCM(
    $idSolicitud: Int
    $canalOrigen: String
    $offset: Int!
    $limit: Int!
  ) {
    getHistorialCuentaMedicaNewTracingCM(
      idSolicitud: $idSolicitud
      canalOrigen: $canalOrigen
      offset: $offset
      limit: $limit
    ) {
      count
      historialCuentaMedica {
        idSolicitud
        estado
        nota
        causal {
          idCausal
          descripcionCausal
        }
        entidad
        usuario
        idUsuario
        fechaDerivacion
        entidadDerivacion
        folioCuentaMedica
        prestador
        fechaInicioCobro
        fechaTerminoCobro
        montoTotalCobro
        canalOrigen
        fechaTransaccion
        tituloNota
        estadoEtl
      }
    }
  }`;