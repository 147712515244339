import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TablePagination,
    Button,
    Box,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Grid,
    TableSortLabel
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import FileGreen from "../../assets/fileGreenI.svg";
import Lupa from "../../assets/lupaInput.svg";
import { NoResult } from "../ui-components/Table/noResult";
import { PointLoad } from "../UI/Modal/PointLoad";
import { titleBox } from "../ui-components/Typography/typographyStyles";
import { CustomTextField } from "../../theme/ui/textField";
import CustomInput from "../ui-components/Input/customInput";
import { DropdownIndicator } from "../ui-components/Table/dropDownIndicator";
import { OperativeNote } from "./operativeNote";
import { defaultRowsPerPage, headCells, perPageGlobal } from "../../utils/constDefault";
import { getFormattedDate } from "../../utils/getFormatDate";
import { formatNumber } from "../../utils/formatNumber";
import ContextRenditionTracking, { ContextValueTracking } from "../../context/contextRenditionTracking";

const MedicalRecord = ({datos, totalRecords, getRenditionsDetails, orderBy, setOrderBy, order, setOrder, setSearchTextbox, searchTextbox, error}: any) => {
    
    const { dataContext, setDataContext } = useContext<ContextValueTracking>(ContextRenditionTracking);
    const { type } = dataContext;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [debouncedQuery, setDebouncedQuery] = useState("");
    const [titleNote, setTitleNote] = useState("");
    
    const handleChangePage = (_event: any, newPage: any) => {
        setPage(newPage);
        getRenditionsDetails(newPage, rowsPerPage, orderBy, order, searchTextbox)
    };

    const handleChangeRowsPerPage = (event: any) => {
        const perPage = parseInt(event.target.value, 10);
        setPage(0)
        setRowsPerPage(perPage)
        getRenditionsDetails(0, perPage, orderBy, order, searchTextbox)
    };

    const [note, setNote] = useState<boolean>(false);

    const handleNote = (note: string) => {
        setTitleNote(note)
        setNote(true)
    }
    const handleSortClick = (columnId: any) => {        
        if (orderBy === columnId) {
          let orderMod = order === 'asc' ? 'desc' : 'asc';
          setOrder(orderMod);
          getRenditionsDetails(page, rowsPerPage, columnId, orderMod, searchTextbox)
        } else {
          setSearchTextbox("")
          setOrderBy(columnId);
          setOrder('asc');
          getRenditionsDetails(page, rowsPerPage, columnId, 'asc', "")
        }
        
    };
    
    const getDirection = (columnId: string): "asc" | "desc" | undefined => {
        if (orderBy === columnId) {
          return order as "asc" | "desc"; 
        }
        return 'asc'; 
    };

    const handleSearchText = (event: { target: { value: any; }; }) => {
        setSearchTextbox(event.target.value)
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setDebouncedQuery(searchTextbox);
        }, 2000); 
    
        return () => {
        clearTimeout(debounceTimer);
        };
    }, [searchTextbox]);

    useEffect(() => {
        getRenditionsDetails(0, rowsPerPage, orderBy, order, searchTextbox)
    }, [debouncedQuery]);
    
    return (
        <>
            <OperativeNote onOpen={note} setOpen={setNote} titleNote={titleNote} />
            <Box p="24px" borderTop={"1px solid #E9EBEC"} borderBottom={"1px solid #E9EBEC"}>
                <Typography sx={titleBox} pb="14px">Registro de Rendiciones médicas</Typography>
                <CustomInput
                    onChange={handleSearchText}
                    value={searchTextbox}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <IconButton  >
                                <img src={Lupa} />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
            {
                datos.length > 0 ?
                <Paper className="paper-tables">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        ((headCell.id == "note" && type == "3") || (headCell.id != "note")) &&                                        
                                        <TableCell
                                            key={headCell.id}
                                            align={'center'}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                            className={ orderBy === headCell.id ? "escritorioTrabajo activeSort" : "escritorioTrabajo headerSort"}
                                        >
                                            {headCell.sorted ?
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={getDirection(headCell.id)}
                                                    onClick={() => handleSortClick(headCell.id)}
                                                    IconComponent={() => <DropdownIndicator active={orderBy === headCell.id} order={orderBy === headCell.id ? order : 'desc'} />}>
                                                    {headCell.label}
                                                </TableSortLabel>
                                                :
                                                <span>{ headCell.label }</span>
                                            }
                                        </TableCell>

                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                datos.map((m: any) => (
                                    <TableRow  key={m.id}>
                                        <TableCell component="th" scope="row" sx={{textAlign: "center"}}>{ m.id }</TableCell>
                                        <TableCell component="th" scope="row" sx={{textAlign: "center"}}>{ m.canal }</TableCell>
                                        <TableCell component="th" scope="row" sx={{textAlign: "center"}}>{ m.totalDocsTrib }</TableCell>
                                        <TableCell component="th" scope="row" sx={{textAlign: "center"}}>{ m.totalVouchers }</TableCell>
                                        <TableCell component="th" scope="row" sx={{textAlign: "center"}}>{ formatNumber(m.totalAmount) }</TableCell>
                                        <TableCell component="th" scope="row" sx={{textAlign: "center"}}>{ m.dateState && getFormattedDate(m.dateState) }</TableCell>
                                        {
                                            type == "3" &&
                                            <TableCell component="th" scope="row" sx={{textAlign: "center"}}>
                                                {
                                                    m.note ? 
                                                        <Button
                                                            variant="outlined"
                                                            className="doc-button"
                                                            disableRipple
                                                            sx={{ p: "4px 22px !important" }}
                                                            onClick={() => handleNote(m.note)}
                                                        >
                                                        Ver nota
                                                        </Button>
                                                    :   
                                                        "-"
                                                }                                                
                                            </TableCell>
                                        }
                                        
                                        
                                    </TableRow>
                                ))
                            }
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={perPageGlobal}
                            component="div"
                            count={totalRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ mt: "14px", mb: "14px" }}
                            labelRowsPerPage={ `Mostrar por página`}
                            labelDisplayedRows={(e) => {
                            return (
                                "" +
                                (page + 1) +
                                " de " +
                                Math.ceil(totalRecords / rowsPerPage)
                            );
                            }}
                        />
                    </TableContainer>
                    </Paper >
                :
                error === true ?
                <NoResult
                    newFound
                    titulo={"No se encontró información de rendiciones"}
                    subtitulo={"Inténtelo nuevamente usando otra información de filtro"} /> 
                    : 
                    <NoResult 
                    newDontSearch 
                    titulo={"Realiza una búsqueda"} 
                    subtitulo={"Ingresa información para visualizar seguimiento de rendiciones"} /> 
            }
        </>
    )
}

export default MedicalRecord;