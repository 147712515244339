import { Box } from "@mui/system";
import { boxCardNP } from "../../theme/ui/Boxs";
import { Button, Grid, Stack, Typography } from "@mui/material";
import {
  altTitle,
  littleText,
} from "../ui-components/Typography/typographyStyles";
import GreenCheck from "../../assets/greenCheck.svg";
import GrayPay from "../../assets/grayPay.svg";
import ErrorIcon from "../../assets/errorIcon.svg";
import UploadGreen from "../../assets/uploadGreen.svg";
import GreenTrash from "../../assets/greenTrash.svg";
import GrayCircle from "../../assets/grayCircle.svg";
import { useContext, useEffect, useState } from "react";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import { WarningModal } from "../UI/Modal/modalWarning";
import { clientCCP } from "../../config/apolloClientsCCP";
import { PostCleanMedicalRendition } from "../../services/cleanMedicalRendition";
import { contextMedicalRendition, defaultContent } from "../../utils/constDefault";
import Toast from "../ui-components/Toast/Toast";
import ModalLoader from "../ui-components/ModalLoader/ModalLoader";
import { registerMedicalRendition } from "../../services/postRegisterMedicalRendition";
import { ButtonAcceptedModal } from "../UI/Modal/ButtonAcceptedModal";
import { useLazyQuery } from "@apollo/client";
import { getFechaProbablePago } from "../../services/getFechaProbablePago";
import { getDateFormatYYYYMMDDGuion, getFormattedToday } from "../../utils/getFormatDate";
import { format, isValid } from "date-fns";
const SummaryValidations = () => {
  const { dataContext, setDataContext } = useContext<ContextValue>(ContextRendition);
  const { amountToPay, insertInvoices, idRendition, file, invoices, holding } = dataContext;
  const [montoCobro, setMontoCobro] = useState(0);
  const [openCleanModal, setOpenCleanModal] = useState<boolean>(false);
  const [openLoad, setOpenLoader] = useState(false);
  const [contentAlert, setContentAlert] = useState<any>(defaultContent);
  const [alert, setAlert] = useState<boolean>(false);
  const [validateDocumentsState, setValidateDocumentsState] = useState<number>(0);
  const [diferenciaCuadre, setDiferenciaCuadre] = useState<any>(0);

  const [fechaProbablePago] = useLazyQuery(getFechaProbablePago);

  const handleCleanRendition = () => {
    setOpenCleanModal(true);
  };

  const MaxControlDiferencia = () => {
    const sessionData = sessionStorage.getItem("info-prestadores");
    const prestadoresArray = sessionData
      ? JSON.parse(sessionData).prestadores
      : [];
    const prestador = prestadoresArray.find((x: any) => x.rutPrestador === holding);
    if(prestador)
    {
      if(prestador.montoMaxAjusteSencillo){
        setDiferenciaCuadre(prestador.montoMaxAjusteSencillo)
      }
    }
  };

  const getUser = () => {
    try {
      const sessionData = sessionStorage.getItem("info-session");
      const infoData = sessionData ? JSON.parse(sessionData) : {};
      return infoData.email;
    } catch (error) {
      return "";
    }    
  }

  const GetSla = () => {
    const sessionData = sessionStorage.getItem("info-prestadores");
    const prestadoresArray = sessionData
      ? JSON.parse(sessionData).prestadores
      : [];
    const prestador = prestadoresArray.find((x: any) => x.rutPrestador === holding);
    if(prestador)
    {
      if(prestador.slaDiasResolucionRendicion){
        return prestador.slaDiasResolucionRendicion;
      }
    }
    return 0;
  };

  const submitCleanRendition = async () => {
    try {
      setOpenLoader(true);
      const result = await clientCCP.mutate({
        mutation: PostCleanMedicalRendition,
        variables: {
          medicalRenditionId: idRendition,
        },
      });
      
      setOpenLoader(false);
      const { estado, mensaje } = result?.data?.cleanMedicalRendition;
      if (estado === 200) {
        setDataContext(contextMedicalRendition)
      } else {
        openToast( false, mensaje, "top", { marginTop: "300px" });
      }
    }catch(error) {
      console.log(error)
      openToast( false, "Error al limpiar la rendición, intente nuevamente", "top", { marginTop: "300px" });
    }
  };

  const openToast = (isSuccess: boolean, content: string, typeAlert: string, styleAlert: object) => {
    setContentAlert({ isSuccess, content, typeAlert, styleAlert });
    setAlert(true);
  };

  const fetchFechaProbablePago = () => {
    return new Promise((resolve, reject) => {
      fechaProbablePago({
        variables: {
          fechaDesde: format(new Date(), "yyyy-MM-dd"),
          sla: GetSla()
        },
        onCompleted: (data) => {
          resolve(data);
        },
        onError: (error) => {
          reject(error);
        }
      });
    });
  };

  const handleSubmitMedicalRendition = async () => {
    try {
      setOpenLoader(true);      
      const respuestaConsulta: any = await fetchFechaProbablePago();
      const result = await clientCCP.mutate({
        mutation: registerMedicalRendition,
        variables: {
          medicalRenditionId: idRendition,
          cuadraturaDiff: Number(montoCobro - amountToPay),
          dateProbablePayment: getDateFormatYYYYMMDDGuion(respuestaConsulta.obtenerFechaProbablePago.fechaProbablePago),
          providerUser: getUser()
        },
      });
  
      setOpenLoader(false);
      const { estado, mensaje } = result?.data?.registerMedicalRendition;
      if (estado === 200) {
        openToast( true, `Su solicitud de Rendición Médica ha sido generada exitosamente con el folio: ${idRendition}`, "bottom", { marginBottom: "100px" });
        setDataContext(contextMedicalRendition)
      } else {
        openToast( false, mensaje, "top", { marginTop: "300px" });
      }
    }catch(error) {
      openToast( false, "No se pudo solicitar la rendición, por favor, vuelva a intentar.", "top", { marginTop: "300px" });
      setOpenLoader(false);
    }
  }

  useEffect(() => {

    const totalDoc = insertInvoices.reduce((sum, invoice) => sum + invoice.amount , 0);
    setMontoCobro(
      totalDoc
    );

    let validateDoc = true;
    insertInvoices.forEach(invoice => {
      if(!invoice.document){
        validateDoc = false
      }
    });

    if(idRendition === 0 && !file) {
      setValidateDocumentsState(0)
    }else {
      if (validateDoc && invoices.length === 0 && insertInvoices.length > 0) 
      { 
        setValidateDocumentsState(1) 
      }
      else {
        setValidateDocumentsState(2)
      }
    }
  }, [insertInvoices, file]);

  useEffect(() => {
    MaxControlDiferencia()
  }, [holding])

  return (
    <Box sx={boxCardNP} mt="25px !important">
      <Toast
        openAlert={alert}
        setOpenAlert={setAlert}
        contentAlert={contentAlert}
      />
      <WarningModal
        onOpen={openCleanModal}
        setOpen={setOpenCleanModal}
        buttonCancel={true}
        title="Perdida de progreso "
        subtitle={
          "Tienes una solicitud de rendición pendiente, ¿Deseas cerrarla?"
        }
        onClick={submitCleanRendition}
        secondaryButton={<ButtonAcceptedModal  title={"Si, Cambiar"} />}
      />
      <ModalLoader open={openLoad} title={"Su solicitud está siendo procesada "} />
      <Grid container p="24px">
        <Grid item xs={8} pb="20px">
          <Grid container>
            <Grid item xs={12} pb="21px">
              <Typography sx={altTitle}>Resumen de validaciones</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignContent={"center"}
              alignItems={"flex-start"}
              pb="7px"
            >
              <img              
                src={                 
                  idRendition === 0 && !file ? GrayCircle
                    : amountToPay > 0 ? GreenCheck : ErrorIcon
                }
                data-testid="bonos-rendidos"
                alt=""
              />
              <Typography sx={littleText} display={"inline"} pl={"6px"}>
                Bonos rendidos
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignContent={"center"}
              alignItems={"flex-start"}
              pb="7px"
            >
              <img src={validateDocumentsState === 0 ? GrayCircle : validateDocumentsState === 1 ? GreenCheck : ErrorIcon} alt="" data-testid="documentos-tributarios" />
              <Typography sx={littleText} display={"inline"} pl={"6px"}>
                Documentos tributarios
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              alignContent={"center"}
              alignItems={"flex-start"}
              pb="7px"
            >
              <img
                src={
                  idRendition === 0 &&                  
                   !file
                    ? GrayCircle
                    : (amountToPay === montoCobro || Math.abs(amountToPay - montoCobro) <= diferenciaCuadre) && amountToPay > 0
                    ? GreenCheck
                    : ErrorIcon
                }
                data-testid="rendicion-cuadrada"
                alt=""
              />
              <Typography sx={littleText} display={"inline"} pl={"6px"}>
                Rendición cuadrada
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} pb="20px" alignSelf={"end"}>
          <Stack direction={"row"} alignSelf={"end"}>
            <Button
              color={idRendition && idRendition > 0 && validateDocumentsState > 0 ? "secondary" : "primary"}
              disabled={idRendition && idRendition > 0 && validateDocumentsState > 0 ? false : true}
              startIcon={<img src={GreenTrash} alt="" />}
              sx={{ whiteSpace: "pre", mr: "16px" }}
              onClick={handleCleanRendition}
            >
              Limpiar solicitud
            </Button>
            <Button
              color="primary"
              disabled={
                (amountToPay === montoCobro || Math.abs(amountToPay - montoCobro) <= diferenciaCuadre) && amountToPay > 0 && validateDocumentsState === 1 ? false : true
              }
              startIcon={
                <img
                  src={
                    (amountToPay === montoCobro || Math.abs(amountToPay - montoCobro) <= diferenciaCuadre) && amountToPay > 0 && validateDocumentsState === 1
                      ? UploadGreen
                      : GrayPay
                  }
                  alt=""
                />
              }
              sx={{ whiteSpace: "pre" }}
              onClick={handleSubmitMedicalRendition}
            >
              Solicitar rendición
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SummaryValidations;
