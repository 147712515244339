import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const SecondaryButton = styled(Button)({
    color: "#00837A",
    fontStyle: 'normal',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    border: '1px solid #00837A',
    left: '0%',
    right: '0%',
    top: '0%',
    bottom: '0%',
    letterSpacing: '0.0021em',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    backgroundColor: "#FFFFFF",
    lineHeight: '20px',
    textTransform: 'none',
    fontFamily: 'interstate',
    fontSize: "16px",
    height: "48px",
    minWidth: "150px",
  
    '&:hover': {
      border: '1px solid #00837A',
      boxSizing: 'border-box',
      color: "#00837A",
      backgroundColor: '#E6F6F5',
    },
    '&:active': {
      color: "#FFFFFF",
      backgroundColor: "#00736B"
    },
    "&:disabled": {
      boxSizing: 'border-box',
      border: '1px solid #909AA1',
      color: "#909AA1",
      boxShadow: 'none',
      backgroundColor: "#FFFFFF"
    }
  });