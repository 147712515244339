import { styled, TextField } from "@mui/material";

const CustomTextField = styled(TextField)({
    
    '& .MuiOutlinedInput-root': {
        height: '50px',
        borderRadius: '8px',
        width: '100%',
        '& fieldset': {
            borderColor: '#BCC2C6',
        },
        '&:hover fieldset': {
            borderColor: '#364855',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid #5143D5',
        },
        
    },
    
    
});

export {
    CustomTextField
}