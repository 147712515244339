import { Grid, Typography, Button } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { inputText,  textBox,  titleBox } from "../ui-components/Typography/typographyStyles";
import { SelectPrestador } from "../medicalRendition/selectPrestador";
import { defaultPrestador, defaultRowsPerPage } from "../../utils/constDefault";
import { SelectYear } from "../medicalRendition/selectYear";
import { SelectMonth } from "../medicalRendition/selectMonth";
import ContextRenditionTracking, { ContextValueTracking } from "../../context/contextRenditionTracking";

export const DetailTracingMedicalHeader = ({getRenditions, setPage, setRowsPerPage, }: any) => {  

  const { dataContext, setDataContext } = useContext<ContextValueTracking>(ContextRenditionTracking);
  const { holding, year, month } = dataContext;
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  
  const [prestadores, setPrestadores] = useState<any>(defaultPrestador);

  const handleRenditions = () => {
    setPage(0)
    setRowsPerPage(defaultRowsPerPage)
    getRenditions(0, defaultRowsPerPage)
  }

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, key: any) => {
    //console.log(prestadores)
    if (key === 'holding') {
      const selectProv =  prestadores.find((x: any) => x.id == event.target.value).nombre;
      setDataContext({ ...dataContext, holding: event.target.value, nameHolding: selectProv});
    }else {
      setDataContext({ ...dataContext, [key]: event.target.value });
    }
  };

  useEffect(() => {
      if(holding !== "0" && year !== 0 && month !== 0) {
        setBtnDisabled(false)
          setDataContext({
              ...dataContext,
              medicalRenditions: []
          });
      } else { 
        setBtnDisabled(true);
      }
  }, [holding, year, month])

  return (
    <Grid container p="24px">
      
    <Grid item xs={12} pr="5%" pb="24px">
      <Typography sx={titleBox}>Rendiciones</Typography>
      <Typography sx={textBox}>Seleccione la información correspondiente para visualizar el estado de sus rendiciones.</Typography>
    </Grid>
    <Grid item xs={5} pr="24px">
      <Typography sx={inputText} pb="6px">Prestador solicitante</Typography>
      <SelectPrestador holding={holding} handleSelectChange={(e: any) => handleSelectChange(e, 'holding')} prestadores={prestadores} setPrestadores={setPrestadores} />
    </Grid>
    <Grid item xs={2} pr="24px">
      <Typography sx={inputText} pb="6px">Año solicitud</Typography>
      <SelectYear year={year} handleSelectChange={(e: any) => handleSelectChange(e, 'year')} />
    </Grid>
    <Grid item xs={2} pr="24px">
      <Typography sx={inputText} pb="6px">Mes solicitud</Typography>
      <SelectMonth month={month} handleSelectChange={(e: any) => handleSelectChange(e, 'month')} />
    </Grid>
    <Grid item xs={3} pt="26px">
      <Button disabled={btnDisabled} color={"primary"} onClick={handleRenditions}>
        Buscar Rendiciones
      </Button>
    </Grid>
  </Grid>
  );
};