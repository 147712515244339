import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import RoutesConfig from "./routes";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import theme from "./theme/themeConfig";
import { BrowserRouter } from "react-router-dom";
import { DrawerExtranet } from "./components/drawer/drawerExtranet";
import { ApolloProvider } from "@apollo/client";
import { clientSecondary } from "./config/apolloClients";
// Inicio Azure AD.
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { UserConfigurationProvider } from "./context/userConfigurationContext";
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(  
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={clientSecondary}>
            <BrowserRouter>
              <Box display="flex" flexDirection="row">
                <CssBaseline />
                <UserConfigurationProvider>
                  <DrawerExtranet />
                  <RoutesConfig />
                </UserConfigurationProvider>
              </Box>
            </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </MsalProvider>,
  document.getElementById("root")
);

reportWebVitals();
