import { Typography } from "@mui/material";
import { WarningModalCustom } from "../../../../components/UI/Modal/WarningModalCustom";
import {
  modalTextBold,
  warningGray,
  warningPurple,
} from "../../../../components/ui-components/Typography/typographyStyles";

type DeteleDocumentModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  documentId: string;
};

const WarningModalContent = ({ documentId }: {documentId: string}) => {
  return (
    <>
      <br></br>
      <Typography sx={warningGray}>Esta acción se aplicará al</Typography>{" "}
      <Typography sx={modalTextBold}>guardar cambios.</Typography>
      <br></br>
      <Typography sx={warningGray}>
        Estás eliminando el documento:
      </Typography>{" "}
      <br></br>
      <Typography sx={warningPurple} pb="16px">
        Folio {documentId}
      </Typography>
      <br></br>{" "}
      <Typography sx={warningGray}>
        ¿Deseas continuar con esta acción?
      </Typography>
    </>
  );
};

export const DeleteDocumentModal = ({
  open,
  onClose,
  onConfirm,
  documentId
}: DeteleDocumentModalProps) => {
  return (
    <WarningModalCustom
      secondaryButton="Cancelar"
      primaryButton="Confirmar"
      contentText={<WarningModalContent documentId={documentId} />}
      title="Eliminar documento"
      headWarning
      onOpen={open}
      onClose={onClose}
      onConfirm={onConfirm}
      loadingWidth={"120px"}
    />
  );
};
