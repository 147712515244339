import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#FFFFFF",
    backgroundColor: "#4D5D68",
    fontSize: "14px",
    fontFamily: "Interstate",
    padding: "8px 12px 8px 12px",
  },
}));

type LongTextTooltipProps = {
  text: string;
  maxLength: number;
  extraLine?: string;
  placement?: string;
};

export const LongTextTooltip = ({
  text,
  maxLength,
  extraLine,
  placement,
}: LongTextTooltipProps) => (
  <>
    {text.length > maxLength ? (
      <Grid>
        <CustomTooltip
          title={
            <>
              {extraLine && (
                <>
                  {extraLine}
                  <br />
                </>
              )}
              {text}
            </>
          }
          arrow
          placement={placement ?? "left"}
        >
          <label>{text.slice(0, maxLength)}...</label>
        </CustomTooltip>
      </Grid>
    ) : (
      <span>{text}</span>
    )}
  </>
);
