import { TableBody, TableRow, TableCell, Button } from "@mui/material";
import { useContext, useState, useEffect, useRef, ChangeEvent } from "react";
import GreenTrash from "../../assets/greenTrash.svg";
import paper from "../../assets/paper.svg";
import WhitePaper from "../../assets/whitePaper.svg";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import { formatNumber } from "../../utils/formatNumber";
import { clientCCP } from "../../config/apolloClientsCCP";
import { DeleteTributaryDocument } from "../../services/deleteTributaryDocument";
export const BodyTable = ({ page, rowsPerPage, handleOpenSelectedDocument, setOpenLoader }: any) => {

  const { dataContext, setDataContext } =
    useContext<ContextValue>(ContextRendition);
  const { insertInvoices, invoices, idRendition } = dataContext;
  const [invoicesPrint, setInvoicesPrint] = useState<any>([]);
  useEffect(() => {
    setInvoicesPrint(insertInvoices);
  }, [insertInvoices]);
  
  const handleDeleteDoc = async (event: any, folioDocument: any) => {

    setOpenLoader(true);
    const result = await clientCCP.mutate({
      mutation: DeleteTributaryDocument,
        variables: {
          medicalRenditionId: idRendition,
          idTributaryDocument: folioDocument
        },
      });
      
      const { estado }: { estado: number; } = result.data.deleteTributaryDocument;
      
      if(estado === 200)
      {
        const updatedInsertInvoices = insertInvoices.filter(
          (invoice) => invoice.folioDocument !== folioDocument
        );        
    
        const updateInvoices = [
          ...invoices,
          {
            id: folioDocument,
            nombre: String(folioDocument),
            value: folioDocument,
          },
        ];
            
        setDataContext((prevContext) => ({
          ...prevContext,
          invoices: updateInvoices,
          insertInvoices: updatedInsertInvoices,
        }));
      }
    setOpenLoader(false);
  };

  return (
    <>
      <TableBody>
        {invoicesPrint
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(
            ({
              typeDocument,
              date,
              amount,
              exempt,
              net,
              tax,
              radioIVA,
              folioDocument,
              document,
            }: any) => (
              <TableRow hover key={folioDocument}>
                <TableCell component="th" scope="row">{typeDocument}</TableCell>
                <TableCell>{folioDocument}</TableCell>
                <TableCell>{date}</TableCell>
                <TableCell>{ formatNumber(net) }</TableCell>
                <TableCell>{ formatNumber(exempt) }</TableCell>
                <TableCell>{ formatNumber(tax) }</TableCell>
                <TableCell>{ formatNumber(amount) }</TableCell>
                <TableCell>
                  <Button
                    color={document && document != "" ? "primary" : "secondary"}
                    startIcon={
                      <img
                        src={document && document != "" ? WhitePaper : paper}
                      />
                    }
                    onClick={() => handleOpenSelectedDocument(folioDocument)}
                  >
                    {document && document != "" ? 1 : 0}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    role="button-delete"
                    color="inherit"
                    startIcon={<img src={GreenTrash} />}
                    onClick={(event) => handleDeleteDoc(event, folioDocument)}
                  ></Button>
                </TableCell>
              </TableRow>
            )
          )}
      </TableBody>
    </>
  );
};
