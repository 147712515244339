import { TableBody, TableCell, TableRow } from "@mui/material";
import { useContext, useEffect } from "react";
import ContextRenditionTracking, { ContextValueTracking } from "../../context/contextRenditionTracking";
import { CeldaBody } from "./celdaBody";

export const BodyTableTracing = ({setDetailVisible}: any) => {
    const { dataContext } = useContext<ContextValueTracking>(ContextRenditionTracking);
    const { medicalRenditions } = dataContext;
  return (
    <TableBody>
        {
            medicalRenditions.map((m: any) => (
                <TableRow  key={m.day_formatted}>
                    <TableCell component="th" scope="row">{ m.day_formatted }</TableCell>
                    <TableCell>{ m.total }</TableCell>
                    <CeldaBody count={ m.solicitadas } typeRenditions={5} date={m.day_formatted } setDetailVisible={setDetailVisible} />
                    <CeldaBody count={ m.aprobadas } typeRenditions={1} date={m.day_formatted } setDetailVisible={setDetailVisible} />
                    <CeldaBody count={ m.rechazadas } typeRenditions={3} date={m.day_formatted } setDetailVisible={setDetailVisible} />
                    <CeldaBody count={ m.pagadas } typeRenditions={2} date={ m.day_formatted } setDetailVisible={setDetailVisible} />
                </TableRow>
            ))
        }
    </TableBody>
  );
};
