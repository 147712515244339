export interface ValidationResult {
    message: string;
    status: boolean;
  }

  export const fileValidate = (selectedFile: File, mb: number, typeDocument: string[]): ValidationResult => {
    const length: number = mb * 1024 * 1024;

    if (selectedFile.size > length) {
      const message = `El archivo seleccionado debe ser menor o igual a ${mb} MB.`;
      console.log(`Error: ${message}`);
      return { message, status: false };
    }
  
    const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();
    if (!fileExtension) {
      const message = "Archivo sin extensión.";
      return { message, status: false };
    } else if (!typeDocument.includes(fileExtension)) {
      const message = `Solo se permiten archivos de tipo [${typeDocument}].`;
      console.log(`Error: ${message}`);
      return { message, status: false };
    }
  
    return { message: '', status: true };
  };