import { TablePagination } from "@mui/material";

type PaginadorArgs = {
  setPagina: Function,
  setLineasPagina: Function,
  opciones: (number | { value: number; label: string; })[] | undefined,
  data: number,
  itemPorPagina: number,
  pagina: number
}

export const Paginador = ({
  setPagina,
  setLineasPagina,
  opciones,
  data,
  itemPorPagina,
  pagina
}: PaginadorArgs) => {

  const cambioPagina = (event: any, newPage: Number) => {
    setPagina(newPage);
  };

  const cambioLineas = (event: any) => {
    setLineasPagina(parseInt(event.target.value, 10));
    setPagina(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={opciones}
      labelRowsPerPage="Mostrar por página"
      component="div"
      count={data}
      rowsPerPage={itemPorPagina}
      page={pagina}
      onPageChange={cambioPagina}
      onRowsPerPageChange={cambioLineas}
      labelDisplayedRows={
        () => {
          return '' + (pagina + 1) + ' de ' + (Math.ceil(data / itemPorPagina))
        }
      }
    />
  )
}