import {
  Table,
  TableContainer,
  Paper
} from "@mui/material";
import { useState, ChangeEvent, useRef, useContext } from 'react';
import { HeaderTable } from "../medicalRendition/headerTable";
import { TablePaginationFormat } from "./tablePagination";
import { BodyTable } from "./bodyTable";
import { defaultContent } from "../../utils/constDefault";
import { fileValidate } from "../../utils/fileValidate";
import ContextRendition, { ContextValue } from "../../context/contextRendition";
import ModalLoader from "../ui-components/ModalLoader/ModalLoader";
import Toast from "../ui-components/Toast/Toast";
import { clientCCP } from "../../config/apolloClientsCCP";
import { SetTributaryDocument } from "../../services/setTributaryDocument";
import { tableHeaders } from '../../utils/constDefault';

function TableDocument() { 
  const { dataContext, setDataContext } =
    useContext<ContextValue>(ContextRendition);
  const { insertInvoices, invoices, idRendition } = dataContext;

  const inputInvoiceFileRef = useRef<HTMLInputElement>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [contentAlert, setContentAlert] = useState<any>(defaultContent);

  const [alert, setAlert] = useState<boolean>(false);
  const [openLoad, setOpenLoader] = useState(false);
  const [invoiceSelected, setInvoiceSelected] = useState(0);
  
  const [errorState, setErrorState] = useState<string>();

  const handleOpenSelectedDocument = (folioDocument: any) => {
    setInvoiceSelected(folioDocument);
    if(inputInvoiceFileRef.current) {
      inputInvoiceFileRef.current.click();
    }
  };

  const openToast = (
    isSuccess: boolean,
    content: string,
    typeAlert: string,
    styleAlert: object
  ) => {
    setContentAlert({ isSuccess, content, typeAlert, styleAlert });
    setAlert(true);
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setOpenLoader(true);
      const { status, message } = fileValidate(selectedFile, 4, [
        "pdf",
        "png",
        "jpg",
      ]);
      if (status) {
        const indexModificar = dataContext.insertInvoices.findIndex((invoice: { folioDocument: number; }) => invoice.folioDocument === invoiceSelected);
        
        if (indexModificar !== -1) {
          try {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(selectedFile);
            fileReader.onload = async () => SendMutation(fileReader.result?.toString().split(",")[1], selectedFile, indexModificar)
          } catch (error) {
            openToast( false, "Error al generar archivo, por favor intente nuevamente", "top", { marginTop: "100px" } );
            setOpenLoader(false);
          }
        }else {
          openToast(false, "Ha ocurrido un error, favor actualice su navegadory vuelva a intentar", "top", { marginTop: "100px" });
        }       
      } else {
        openToast(false, message, "top", { marginTop: "100px" });
        setOpenLoader(false);
      }
    }
    event.target.value = "";
  };

  const SendMutation = async ( base64File: string | undefined, selectedFile: File, indexModificar: number ) => {
    if (base64File) {
      const modifiedInvoice = {
        ...dataContext.insertInvoices[indexModificar],
      };

      const tributaryDocumentFile = {
        idTributaryDocument: modifiedInvoice.folioDocument,
        inputFile: base64File,
        fileName: selectedFile.name,
        medicalRenditionId: idRendition
      }
      
      const result = await clientCCP.mutate({
      mutation: SetTributaryDocument,
        variables: {
          tributaryDocumentFile
        },
      });
      
      const { estado, mensaje }: { estado: number; mensaje: string; } = result.data.setTributaryDocument;

      if (estado === 200) {
       
        openToast(true, "La carga del archivo ha sido realizada correctamente", "top", { marginTop: "100px" });
        setErrorState(undefined);
        modifiedInvoice.document = selectedFile;
        const updatedInsertInvoices = [...dataContext.insertInvoices];
        updatedInsertInvoices[indexModificar] = modifiedInvoice;

        setDataContext({
          ...dataContext,
          insertInvoices: updatedInsertInvoices,
        });

      } else {
        setErrorState(undefined);
        openToast(false, mensaje, "top", { marginTop: "100px" });
      }
      setOpenLoader(false);
    } else {
      openToast( false, "Error al generar archivo, por favor intente nuevamente", "top", { marginTop: "100px" });
      setOpenLoader(false);
    }
  };

  return (
    <Paper className="paper-tables">
      <ModalLoader open={openLoad} title={"Su solicitud está siendo procesada"} />
      <Toast
        openAlert={alert}
        setOpenAlert={setAlert}
        contentAlert={contentAlert}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <HeaderTable tableHeaders={tableHeaders} />
          <BodyTable  page={page} rowsPerPage={rowsPerPage} handleOpenSelectedDocument={handleOpenSelectedDocument} setOpenLoader={setOpenLoader} />
        </Table>
      </TableContainer>
      <TablePaginationFormat page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
      <input
        type="file"
        accept=".pdf, .png, .jpg"
        onChange={handleFileInputChange}
        ref={inputInvoiceFileRef}
        style={{ display: "none" }}
        data-testid="file-input-invoices"
      />
    </Paper>
  );
}

export default TableDocument;
